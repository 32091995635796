import {ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AuthorizationService} from '../../../../services/authorization/authorization.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {Subscription} from 'rxjs';
import {FormEvent} from '../../containers/form/form.interface';
import {ButtonRank} from '../../../commonUI/button/lumi-button/lumi-button.interface';
import {FormGroupConfig} from '../field/fieldDirective.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-button',
    template: `
        <div *ngIf="isVisible()" class="dynamic-field" [formGroup]="group">
            <lumi-button (onClick)="handleClick()"
                         [disabled]="isDisabled()"
                         [size]="this.isSubmitButton() ? 'large':'medium'"
                         [label]="config.label? config.label : ''"
                         [fullWidth]="!!config.label"
                         [centerLabel]="this.isSubmitButton()"
                         [rank]="getRank()"
                         [icon]="getIcon()"
                         [title]="getToolTip()">
            </lumi-button>
        </div>
    `,
})

export class FormButtonComponent implements OnDestroy, OnInit {
    @HostBinding('class') hostClasses = '';
    @Output() onComponentEvent: EventEmitter<FormButtonComponentEvent> = new EventEmitter();
    
    @Input() group: UntypedFormGroup;
    readOnly: boolean;
    invalidControlsErrors: any;

    private _config: any;
    private _subMobileMode: Subscription;
    private _mobileMode: Boolean = false;

    public constructor(private auth: AuthorizationService, private model: GlobalModel, private cd: ChangeDetectorRef, protected logger:LoggerService) {
    }

    ngOnInit(): void {
        this._subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            //Don't forget to unsubscribe
            this._mobileMode = value;
            this.cd.detectChanges(); //Mark for change was not enough here
        });
    }

    ngOnDestroy(): void {
        if (this._subMobileMode) {
            this._subMobileMode.unsubscribe();
        }
    }

   @Input()
   set config(value: any) {
        this._config = value;
        if (this.isCancelButton()) {
            this.hostClasses = 'cancel-button-container';
        }
        else if (this.isBackButton()) {
            this.hostClasses = 'back-button-container';
        }
        else {
            this.hostClasses = 'default-button-container';
        }
    }

    get config(): any {
        return this._config;
    }

    isVisible(): boolean {
        let result: boolean = true;

        if (this.isCancelButton() && this._mobileMode) {
            result = false;
        }

        return result;
    }

    isDisabled(): boolean {
        let disabled: boolean = false;

        if (this.readOnly === true) {
            disabled = true;
        }
        else if (this.readOnly === false) {
            disabled = false;
        }

        if (this.config.disabled === true) {
            disabled = true;
        }

        if (this.config.disabledByFormError) {
            disabled = true;
        }

        if (this.config && this.config.attr) {
            // TODO: Kan ook verplaatst worden naar de backend. De knop als disabled doorsturen als je geen delete rechten hebt
            // client-side authorisation for special cases
            let action: string = this.config.attr.action;
            if (
                (action == FormEvent.DELETE_BASEOBJECT && !this.auth.allowRemoveBaseObject()) ||
                (action == FormEvent.SEGMENT_DELETE && !this.auth.allowRemoveBaseObject()) ||
                (action == FormEvent.COPY_BASEOBJECT && !this.auth.allowCopyBaseObject()) ||
                (action == FormEvent.EXCEPTION_CREATE && !this.auth.allowCreateException()) ||
                (action == FormEvent.SCHEME_EDIT && !this.auth.allowEditDimmingScheme()) ||
                (action == FormEvent.SET_DIMGROUP_OVERRIDE && !this.auth.allowSetOverride()) ||
                (action == FormEvent.DIMGROUP_DELETE && !this.auth.allowDeleteDimGroup())
            ) {
                disabled = true;
            }
        }

        //Movebutton altijd toeganklijk
        if (this.config && this.config.attr && this.config.attr.action == FormEvent.MOVE_MAPITEM) {
            disabled = false;
        }

        return disabled;
    }

    handleClick() {
        this.logger.log('[FormButtonComponent] ' + 'handle click form button', this.group);

        if (this.isBackButton()) {
            window.history.back();
        }

        //Default to save-button, since that is most common
        let action: string = FormEvent.SAVE;
        let url: string = '';
        let mapItem: number = -1;
        let attr: object = {};

        if (this.config.attr) {
            attr = this.config.attr;

            if (this.config.attr.action) {
                action = this.config.attr.action;
            }
            if (this.config.attr.url) {
                url = this.config.attr.url;
            }
            if (this.config.attr.map_item_id) {
                mapItem = this.config.attr.map_item_id;
            }
        }

        this.onComponentEvent.emit({event: action, data: {url: url, mapItem: mapItem, attr: attr}});
    }

    hasIcon(): boolean {
        return (this.config.attr && this.config.attr.icon && this.config.attr.icon != '');
    }

    private isCancelButton(): boolean {
        return (this.config.attr && this.config.attr.action && this._config.attr.action == FormEvent.CANCEL);
    }

    private isBackButton(): boolean {
        return (this.config.attr && this.config.attr.action && this.config.attr.action == FormEvent.BACK);
    }

    getIcon(): string {
        let result: string = '';

        if (this.hasIcon()) {
            result = this.config.attr.icon;
        }

        return result;
    }

    getToolTip(): string {
        let result = '';

        if (this.config.attr && this.config.attr.toolTip) {
            result = this.config.attr.toolTip;
        }

        return result;
    }
    
    public isSubmitButton() {
        return !(this.config.attr && this.config.attr.submit === false);
    }
    
    public getRank() {
        if (!this.isSubmitButton()) {
            return ButtonRank.SECONDARY;
        }
        return ButtonRank.PRIMARY;
    }
}

export interface FormButtonComponentEvent {
    event: string
    data: {
        url: string
        mapItem: number
        attr: object
    }
    config?: FormGroupConfig
}
