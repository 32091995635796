<div class="full-height-wrapper">
    <div class="dashboard-container dashboardV2">
        <div class="row">
            <div class="menu-wrapper d-none d-lg-block col-lg-3 col-xxl-2">
                <div class="areaal-container">
                    <div class="areaal-image" *ngIf="areaLogo">
                        <img [src]="areaLogo" [title]="areaName" [alt]="areaName" />
                    </div>
                    <div class="areaal-name" [ngClass]="{'no-image':!areaLogo}">{{areaName}}</div>
                </div>
                <div class="dashboard-menu" *ngIf="availableDashboards.length > 0">
                    <ul>
                        <li class="d-flex justify-content-start" *ngFor="let dashboardItem of availableDashboards" (click)="openDashboard(dashboardItem.url)"
                            [ngClass]="{
                            'active':dashboardItem.active,
                            'energy':dashboardItem.dashboardCode == 'energy-report',
                            'separator':dashboardItem.showSeparatorLine
                        }">
                            <span class="mr-2 mr-lg-0">
                                <fa-icon class="icon m-0 mr-lg-2" *ngIf="dashboardItem.faIcon" [icon]="dashboardItem.faIcon"></fa-icon>
                            </span>
                            <span class="m-0 ml-lg-3">{{dashboardItem.name}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-9 col-xxl-10">
                <div class="dashboard-mobile-menu d-block d-lg-none" *ngIf="availableDashboards.length > 0">
                    <div class="d-flex mb-3">
                        <div class="areaal-image small" *ngIf="areaLogo">
                            <img [src]="areaLogo" [title]="areaName" [alt]="areaName" />
                        </div>
                        <div class="areaal-name ml-2" [ngClass]="{'no-image':!areaLogo}">{{areaName}}</div>
                    </div>

                    <div class="d-flex flex-wrap">
                        <div class="badge badge-pill badge-info" *ngFor="let dashboardItem of availableDashboards" (click)="openDashboard(dashboardItem.url)"
                             [ngClass]="{
                            'active':dashboardItem.active,
                            'energy':dashboardItem.dashboardCode == 'energy-report',
                            'separator':dashboardItem.showSeparatorLine
                        }">
                            <span class="mr-2 mr-lg-0">
                                <fa-icon class="icon m-0 mr-lg-2" *ngIf="dashboardItem.faIcon" [icon]="dashboardItem.faIcon"></fa-icon>
                            </span>
                            <span class="m-0 ml-lg-3">{{dashboardItem.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="dashboard-details" *ngIf="currentDashboard && activeDashboard !== null && !showInfo">
                    <ng-container *ngIf="currentDashboard.code == 'DEFAULT_DASHBOARD'">
                        <div class="title">{{timeOfDay()}} {{getFullName()}}</div>
                        <div class="subtitle">{{'Welkom bij Luminizer' | translate}} {{getVersionNumber()}}</div>
                        <p class="mt-3" *ngIf="getLastFormItemFromHistory()">{{'dashboard.goto-history-form' | translate}} <span class="link-text" (click)="clickHistoryItem()">{{getLastFormItemFromHistory().title}}</span></p>
                    </ng-container>
                    <div class="dashboard-widgets mt-4">
                        <ng-container *ngIf="!isDashboardLoading() && currentDashboard">
                            <div class="row widget-row" *ngFor="let row of currentDashboard.rows;">
                                <div class="col-12 col-lg-{{12 / row.widgets.length}} widget-component-container px-0 px-lg-2 mb-3" *ngFor="let widget of row.widgets;">
                                    <small-widget-component *ngIf="widget.type == WIDGET_TYPE_TEXT" [widget]="widget"></small-widget-component>
                                    <widget-component *ngIf="widget.type == WIDGET_TYPE_CHART" [widget]="widget"></widget-component>
                                    <map-widget-component *ngIf="widget.type == WIDGET_TYPE_MAP" [widget]="widget"></map-widget-component>
                                    <table-widget-component *ngIf="widget.type == WIDGET_TYPE_TABLE" [widget]="widget"></table-widget-component>
                                    <feed-widget-component *ngIf="widget.type == WIDGET_TYPE_FEED" [widget]="widget"></feed-widget-component>
                                    <static-widget-component *ngIf="widget.type == WIDGET_TYPE_STATIC" [widget]="widget" [isDashboardV2]="true"></static-widget-component>
                                </div>
                            </div>
                        </ng-container>
                        <div class="d-flex justify-content-center mt-5" *ngIf="isDashboardLoading()">
                            <div class="loader loader-transparent"></div>
                        </div>
                    </div>
                </div>

                <div class="dashboard-details" *ngIf="showInfo">
                    <div class="title">
                        <span *ngIf="showInfo">{{'info' | translate}}</span>
                    </div>
                    <div class="mt-4">
                        <ng-container *ngIf="showInfo">
                            <div class="info-table">
                                <div class="info-row">
                                    <div class="info-col">Luminizer versie:</div>
                                    <div class="info-col">{{getLuminizerVersion()}}</div>
                                </div>
                                <div class="info-row">
                                    <div class="info-col">{{'Gebruiker' | translate}}:</div>
                                    <div class="info-col">{{model.user.getValue().username}}</div>
                                </div>
                                <div class="info-row">
                                    <div class="info-col">{{'Rol' | translate}}:</div>
                                    <div class="info-col">
                                        <div class="d-flex flex-wrap blocks">
                                            <div class="block" *ngFor="let rol of model.getUserRoleLabels()">{{rol}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="info-col">{{'Areaal:' | translate}}</div>
                                    <div class="info-col">{{areaName}}</div>
                                </div>

                                <div class="info-row">
                                    <div class="info-col">{{'Arealen' | translate}}:</div>
                                    <div class="info-col">{{getAreasAvailableText(model.arealen.getValue().length)}}</div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="d-flex justify-content-center mt-5" *ngIf="isDashboardLoading()">
                            <div class="loader loader-transparent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
