import { Component, OnInit } from '@angular/core';
import {DashboardComponent} from "./dashboard.component";
import {DashboardV2Component} from "./dashboard-V2.component";
import {AuthorizationService} from "../../shared/services/authorization/authorization.service";

@Component({
    selector: 'dashboard-component-wrapper',
    template: `<ng-container *ngComponentOutlet="currentDashboard"></ng-container>`
})
export class DashboardWrapperComponent implements OnInit {
    public currentDashboard: any;

    constructor(private auth:AuthorizationService){}

    ngOnInit() {
        if(this.auth.showDashboardV2()){
            this.currentDashboard = DashboardV2Component;
        } else {
            this.currentDashboard = DashboardComponent;
        }
    }
}
