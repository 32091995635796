import {
    ApplicationRef,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {FormDataService} from '../../services/form-data.service';
import Utils from '../../../../utils/utils';
import {AuthorizationService} from '../../../../services/authorization/authorization.service';
import * as exif from 'exif-js';
import {FormEvent} from '../../containers/form/form.interface';
import {GlobalModel} from '../../../../services/state/global.model';
import {FileSelectorComponent} from '../../../file/file-selector/file-selector.component';
import {ListItem, ListItemMenuItemEvent} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';
import {Attachment, IAttachmentCarousel, IImageCarousel} from './form-attachment.interface';
import { AppSettings } from 'src/app/app.settings';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {LuminizerRoutes} from '../../../../interfaces/routes';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-attachment',
    templateUrl: './form-attachment.component.html'
})
export class FormAttachmentComponent extends AbstractFormFieldComponent {
    public static readonly ATTACHMENT_GET_PATH: string = 'components/attachments/get/';
    private static readonly ATTACHMENT_CREATE_PATH: string = 'components/attachments/create/';
    private static readonly ATTACHMENT_MAX_SIZE: number = 10; // in MB (bytes / 1000000)
    private static readonly ATTACHMENT_MAX_WIDTH: number = 2560; // px
    private static readonly ATTACHMENT_COMPRESSION_QUALITY: number = 0.7; // Ranging from 0 to 1
    
    @ViewChild('fileSelector', {static: false}) fileSelector: FileSelectorComponent;
    
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter<any>();
    
    public attachments: Attachment[] = [];
    public isLoading: boolean = false;
    private uploadCount: number;
    public AppSettings = AppSettings;

    public allowMultiSelect: boolean = true;
    
    constructor(
        public renderer: Renderer2,
        private appRef: ApplicationRef,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        private globalAlertService: GlobalAlertService,
        private ts: TranslateService,
        private cd: ChangeDetectorRef,
        private formDataService: FormDataService,
        private auth: AuthorizationService,
        public globalModel: GlobalModel,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }
    
    ngOnInit() {

        // Set the logs for the view
        if (this.config && this.config.attr && this.config.attr.attachments) {
            this.attachments = this.config.attr.attachments.map(attachment => {
                attachment.listItem = this.getListItem(attachment);
                return attachment;
            });
            
            // NOTE: een GB is 1024MB op windows, een GB is 1000 MB op MacOS
            // this.logger.log("[FormAttachmentComponent] " + "navigator platform: ", navigator.platform);
            if (Utils.isWindows()) {
                this.attachments.forEach((attachment: any) => {
                    attachment.fileSize = Math.round((attachment.fileSize * 1000) / 1024);
                });
            }
        }

        if(this.config && this.config.type == 'attachment_single_component'){
            this.allowMultiSelect = false;
        }
    }
    
    @HostListener('window:click', ['$event'])
    public destroyToolTip() {
        this.tooltipService.destroyToolTip(this.fileSelector.toolTipAnchor);
    }
    
    public getFileSize(attachment: any): string {
        // NOTE: als je hier MB's zou willen berekenen moet je /100 doen voor mac, en /1024 voor windows
        /*if (attachment.fileSize > 1000){
            return (attachment.fileSize / 1000) + ' MB'
        }else */
        
        if (attachment.fileSize <= 0) {
            return '<0 KB';
        } else {
            return attachment.fileSize + ' KB';
        }
    }
    
    public getName(attachment: any): string {
        if (attachment.name !== '') {
            return attachment.name;
        } else {
            return this.ts.translate('attachment.unknown');
        }
    }
    
    public isDeletable(attachment: any): boolean {
        return attachment.deleteUrl && attachment.deleteUrl !== '';
    }
    
    public hasThumbNail(attachment: any): boolean {
        return attachment.thumbNail && attachment.thumbNail !== '';
    }

    public getFileTypeThumbnail(attachment:any):string{
        switch(attachment.extension){
            case 'ms-excel':
            case 'sheet':
            case 'csv':
                return 'filetypethumb_xls.png';
            case 'msword':
            case 'document':
                return 'filetypethumb_doc.png';
            case 'ms-powerpoint':
            case 'presentation':
                return 'filetypethumb_ppt.png';
            case 'pdf':
                return 'filetypethumb_pdf.png';
            case 'plain':
                return 'filetypethumb_txt.png';
            case 'zip':
                return 'filetypethumb_zip.png';
            default:
                return 'filetypethumb_general.png';
        }
    }
    
    public onFilesSelected(files: FileList): void {
        this.uploadCount = 0;
        if (files && files.length > 0) {
            Array.from(files).forEach(async (file: File) => {
                let exifData: string;
                let fileData: string;
                let fileSize: number;
                let fileName = file.name;
                
                // Read all EXIF data from selected image
                exif.getData(String(file), function () {
                    exifData = exif.getAllTags(this);
                    this.logger.log('[FormAttachmentComponent] EXIF DATA -> ', exifData);
                });
                
                // De maxwidth en quality geldt alleen voor images die exif hebben. Dus JPG, en dan een eentje met de exifdata.
                // iOS en Android foto-apps voldoen hieraan
                // NOTE: als de filedata leeg is, was geen exif orientation data gevonden. Dus het was geen JPG of de JPG bevatte geen exif.
                // Doe niks met de afbeelding en haal de filedata via de normale reader op.
                await Utils.getRotatedImageUrl(
                    file,
                    1,
                    FormAttachmentComponent.ATTACHMENT_MAX_WIDTH,
                    FormAttachmentComponent.ATTACHMENT_COMPRESSION_QUALITY
                ).then(rotatedFileData => {
                    if (!rotatedFileData) {
                        const fileReader: FileReader = new FileReader();
                        this.logger.log('[FormAttachmentComponent] ' + 'No orientation found, load the file like any other');
                        
                        fileReader.onload = (event: any) => {
                            fileData = event.target.result;
                            fileSize = this.estimateFileSize(fileData);
                            this.logger.log('[FormAttachmentComponent] ' + 'Selected file size (estimate): ' + fileSize);
                            this.upload(fileData, fileSize, fileName, exifData);
                        };
                        
                        // TODO: testen in de praktijk
                        fileReader.onerror = () => {
                            this.logger.log('[FormAttachmentComponent] ' + 'Error while reading file');
                            this.clearInput();
                            if (this.auth.allowShowDebug()) {
                                // Show error message for service engineers. Do it with a popup because mobile devices have no console
                                this.globalAlertService.addAlertDebugMessage(
                                    'Error',
                                    'Read error',
                                    'This file could not be read. File is ignored.'
                                );
                            }
                        };
                        
                        fileReader.readAsDataURL(file);
                    } else {
                        // foto gevonden met orientatie. orientatie rechtgezet en waarde naar 1
                        fileData = rotatedFileData;
                        fileSize = this.estimateFileSize(fileData);
                        this.logger.log('[FormAttachmentComponent] ' + 'Selected file size (estimate): ' + fileSize);
                        
                        this.upload(fileData, fileSize, fileName, exifData);
                    }
                });
            });
        }
    }
    
    public handleOpenAttachment(event: any, attachment: any, directDownload: boolean = false): void {
        // Skip files already downloading
        if (attachment.isDownloading) {
            return;
        }
        let url = FormAttachmentComponent.ATTACHMENT_GET_PATH;
        if (this.globalModel.currentModule.value === LuminizerRoutes.REPORT_MALFUNCTION_PAGE &&
            this.globalModel.reportMalfunctionTree.value.code === 'MSB_MELDING') {
            url = FormAttachmentComponent.ATTACHMENT_GET_PATH + 'msb/';
        }
        attachment.url = url;

        if (!this.hasThumbNail(attachment) || directDownload) {
            this.logger.log('[FormAttachmentComponent] ' + 'Download attachment', attachment.fileName);
            attachment.isDownloading = true;
            this.cd.detectChanges();


            this.formDataService.getAttachment(url + attachment.id,
                (data: any) => {
                    this.logger.log('[FormAttachmentComponent] ' + 'Attachmentdata received for: ' + data.fileName);
                    Utils.downloadAsFile(true, data.fileData, data.fileName, 'form-attachment .form-attachment-title-container');
                    attachment.isDownloading = false;
                    this.cd.detectChanges();
                }
                ,
                () => {
                    attachment.isDownloading = false;
                    this.cd.detectChanges();
                },
                () => {
                    attachment.isDownloading = false;
                    this.cd.detectChanges();
                }
            );
        } else {
            this.logger.log('[FormAttachmentComponent] ' + 'Open attachment in preview popup');
            let attachmentImageList:Attachment[] = [];

            this.attachments.map(attachmentItem =>{
                if(this.hasThumbNail(attachmentItem)){
                    attachmentImageList.push(attachmentItem)
                }
            })
            if(attachmentImageList.length > 0){
                this.globalAlertService.addPopupViewImagesCarousel({
                    attachmentList: attachmentImageList,
                    activeAttachmentId:attachment.id
                }, () => {
                }, () => {
                });
            }
        }
    }

    public handleClickDeleteAttachment(event: MouseEvent, attachment: any): void {
        this.globalAlertService.addPopup(
            this.ts.translate('attachment.deletetitle'),
            this.ts.translate('attachment.delete'),
            [{
                label: this.ts.translate('Annuleren'),
                code: ButtonCode.ANNULEREN,
                isPrimary: true
            }, {
                label: this.ts.translate('Verwijderen'),
                code: ButtonCode.DELETE,
                callback: () => {
                    // Show grayed-out attachment until delete call finished
                    attachment.isDeleted = true;
                    this.cd.detectChanges();
                    
                    this.formDataService.deleteAttachment(attachment.deleteUrl,
                        () => {
                            // Remove the old attachment, client side
                            let deleteIndex: number = this.attachments.indexOf(attachment);
                            
                            if (deleteIndex > -1) {
                                this.attachments.splice(deleteIndex, 1);
                                this.cd.detectChanges();
                            }
                            
                            this.onComponentEvent.emit({event: FormEvent.ATTACHMENT_DELETE_SUCCESS, data: {}});
                        }, () => {
                            attachment.isDeleted = false;
                            this.cd.detectChanges();
                        }, () => {
                            attachment.isDeleted = false;
                            this.cd.detectChanges();
                        });
                },
                isPrimary: false
            }],
            () => {
            }
        );
    }
    
    private estimateFileSize(fileData: any): number {
        let imgFileSize = Math.round((fileData.length) * 3 / 4);
        // Get size in MB
        return imgFileSize / 1000000;
    }
    
    private upload(fileData: string, fileSize: number, fileName: string, exifData: string): void {
        this.logger.log('[FormAttachmentComponent] ' + 'trying to upload file ' + fileName + ', with fileSize ' + fileSize);
        let baseObjectId: number = this.rootConfig.base_object_id;
        
        if (!fileData) {
            this.tooltipService.createAndShowTooltip(
                this.renderer,
                this.fileSelector.toolTipAnchor,
                this.ts.translate('attachment.empty'),
                TooltipService.PLACEMENT_BOTTOM,
                TooltipService.TOOLTIP_MODE_MANUAL,
                '',
                true
            );
            this.logger.log('[FormAttachmentComponent] ' + 'fileData NOT OK');
            this.clearInput();
            return;
        }
        
        this.logger.log('[FormAttachmentComponent] ' + 'fileData OK');
        
        if (fileSize > FormAttachmentComponent.ATTACHMENT_MAX_SIZE) {
            this.tooltipService.createAndShowTooltip(
                this.renderer,
                this.fileSelector.toolTipAnchor,
                this.ts.translate('attachment.toobig', [fileName, FormAttachmentComponent.ATTACHMENT_MAX_SIZE]),
                TooltipService.PLACEMENT_BOTTOM,
                TooltipService.TOOLTIP_MODE_MANUAL,
                '',
                true
            );
            this.logger.log('[FormAttachmentComponent] ' + 'fileSize NOT OK');
            this.clearInput();
            return;
        }
        
        this.logger.log('[FormAttachmentComponent] ' + 'fileSize OK');
        
        // Check for safe filename. Chars like ë can be composed of 1 or 2 UTF-8 unicode characters (composed or decomposed).
        // Two isn't supported by all fonts.
        // The current restriction is quite strict. More characters could be allowed. (But not 776, for example)
        // Another solution could be to convert decomposed (110 + 776) to composed (235).
        if (!this.isSafeFileName(fileName)) {
            this.tooltipService.createAndShowTooltip(
                this.renderer,
                this.fileSelector.toolTipAnchor,
                this.ts.translate('attachment.invalidchars', [fileName]),
                TooltipService.PLACEMENT_BOTTOM,
                TooltipService.TOOLTIP_MODE_MANUAL,
                '',
                true
            );
            this.logger.log('[FormAttachmentComponent] ' + 'fileName NOT OK');
            this.clearInput();
            return;
        }
        this.logger.log('[FormAttachmentComponent] ' + 'fileName OK; continue to upload');
        
        this.isLoading = true;
        
        let encodedFileData: string = encodeURIComponent(fileData);
        
        // keep track of pending uploads
        this.uploadCount++;
        
        let url = FormAttachmentComponent.ATTACHMENT_CREATE_PATH + baseObjectId;
        if (this.config && this.config.attr && this.config.attr.alternativeUploadPath && this.config.attr.alternativeUploadPath !== '') {
            url = FormAttachmentComponent.ATTACHMENT_CREATE_PATH + this.config.attr.alternativeUploadPath + baseObjectId;
        }
        if (this.globalModel.currentModule.value == LuminizerRoutes.AREA_SETTINGS_PAGE) {
            url = FormAttachmentComponent.ATTACHMENT_CREATE_PATH + 'logo';
        }

        
        return this.formDataService.addAttachment(
            url,
            fileName,
            exifData,
            encodedFileData,
            (data: any) => {
                this.uploadCount--;
                this.logger.log('[FormAttachmentComponent] ' + fileName + ' uploaded successfully');
                this.clearInput();
                
                // Trigger animation + cleanup
                data.isNew = true;
                setTimeout(() => {
                    data.isNew = false;
                    this.cd.detectChanges();
                }, 1000);
                
                // Add the new attachment at the beginning of the array
                data.listItem = this.getListItem(data);
                this.attachments.unshift(data);
                
                if (this.uploadCount < 1) {
                    // Enable the input again
                    this.isLoading = false;
                    
                    // Update the view
                    this.cd.detectChanges();
                    
                    this.onComponentEvent.emit({event: FormEvent.ATTACHMENT_ADD_SUCCESS, data: {}});
                }
            },
            () => {
                this.uploadCount--;
                this.logger.log('[FormAttachmentComponent] ' + fileName + ' upload failed');
                this.isLoading = false;
                this.cd.detectChanges();
            },
            () => {
                this.uploadCount--;
                this.logger.log('[FormAttachmentComponent] ' + fileName + ' upload failed');
                this.isLoading = false;
                this.cd.detectChanges();
            });
    }
    
    private clearInput(): void {
        // Clear the input
        this.fileSelector.fileInput.nativeElement.value = '';
    }
    
    // Block files containing strange characters
    private isSafeFileName(fileName: string): boolean {
        let i: number = fileName.length;
        
        while (i--) {
            if (fileName.charCodeAt(i) > 125) {
                return false;
            }
        }
        
        return true;
    }
    
    private getListItem(attachment: Attachment): ListItem {
        const listItem = {
            id: attachment.id,
            title: attachment.fileName,
            icon: 'attach_file',
            menuTitle: this.ts.translate('attachment.menu'),
            menuItems: [{
                label: this.ts.translate('menuItem.download'),
                icon: 'get_app',
                action: 'download'
            }, {
                label: this.ts.translate('menuItem.view'),
                icon: 'preview',
                action: 'view'
            }]
        };
        if (!this.isControlDisabled() && attachment.deleteUrl) {
            listItem.menuItems.push({
                label: this.ts.translate('menuItem.delete'),
                icon: 'delete',
                action: 'delete'
            });
        }
        return listItem;
    }
    
    public onAttachmentAction($event: ListItemMenuItemEvent, attachment: Attachment): void {
        switch ($event.action) {
            case 'download':
                this.handleOpenAttachment(null, attachment, true);
                break;
            case 'delete':
                this.handleClickDeleteAttachment(null, attachment);
                break;
            case 'view':
                this.handleOpenAttachment(null, attachment);
        }
    }
}
