import {ChangeDetectorRef, Component, Renderer2} from '@angular/core';
import {FormInputComponent} from './form-input.component';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {PasswordGeneratorService} from '../../../../services/authorization/password-generator.service';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {LoggerService} from "../../../../services/logger/logger.service";
import {GlobalModel} from '../../../../services/state/global.model';

@Component({
    selector: 'form-password-input',
    providers: [PasswordGeneratorService],
    styleUrls:['./form-password-input.component.scss'],
    template: `
        <ng-container [formGroup]="group">
            <div class="w-100 position-relative">
                <div class="d-flex w-100 position-relative">
                    <label class="m-0 pr-2 form-label-part" for="{{config.name}}">{{ config.label }}
                        <required-field [visible]="config.required"></required-field>
                    </label>
                    <div class="w-100">
                        <input
                                #currentFormField
                                [disableControl]="isControlDisabled()"
                                name="{{config.name}}"
                                type="password"
                                [attr.placeholder]="config.placeholder"
                                [formControlName]="config.name"
                                (input)="checkPasswordStrength($event)"
                                class="m-0 password-input"
                                [(ngModel)]="initialValue"
                                autocomplete="{{getAutocomplete()}}"
                        >
                        <div class="strength-container d-flex justify-content-end align-items-center">
                            <div class="text-indicator" [innerHTML]="strengthText"></div>
                            <div class="strength-bar"><div class="inner" [ngClass]="{'weak':strengthLevel>=1}"></div></div>
                            <div class="strength-bar"><div class="inner" [ngClass]="{'medium':strengthLevel>=2}"></div></div>
                            <div class="strength-bar"><div class="inner" [ngClass]="{'strong':strengthLevel>=4}"></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    `,
})
export class FormPasswordInputComponent extends FormInputComponent {
    public strengthLevel: number = 0;
    public strengthText:string = '&nbsp;';

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, public globalAlertService: GlobalAlertService, public ts: TranslateService, public generatorService: PasswordGeneratorService, public cd:ChangeDetectorRef, protected logger:LoggerService, public model:GlobalModel, private translate:TranslateService){
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model)
    }

    public checkPasswordStrength(passwordInput:any) {
        const password = passwordInput.target.value;
        let strength = 0;
        if(password.length > 0){
            strength = 1;
        }
        if(password.length >= 8){
            if (/[a-z]/.test(password))strength++; // Check lowercase
            if (/[A-Z]/.test(password))strength++; // Check uppercase
            if (/[0-9]/.test(password))strength++; // Check digits
            if (/[^a-zA-Z0-9]/.test(password))strength++; // Check special characters
        }

        if(strength == 1){
            this.strengthText = this.translate.translate('password-strength.weak');
        } else if(strength == 2 || strength == 3){
            this.strengthText = this.translate.translate('password-strength.medium');
        } else if(strength >= 4){
            this.strengthText = this.translate.translate('password-strength.strong');
        } else {
            this.strengthText = '&nbsp;';
        }

        this.strengthLevel = strength;
    }

    protected showValidationConstraints(validationConstraints: any, placement: string) {
        super.showValidationConstraints(validationConstraints, TooltipService.PLACEMENT_TOP);
    }

    public getAutocomplete():string {
        return (this.config.attr && typeof this.config.attr.autocomplete === 'string')
            ? this.config.attr.autocomplete
            : 'on';
    }
}
