import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {DashboardRoutingModule} from './dashboard.routing';
import {DashboardComponent} from './dashboard.component';
import {WidgetModule} from "./widgets/widget.module";
import {TranslateService} from '../../shared/services/translate/translate.service';
import {DashboardV2Component} from "./dashboard-V2.component";
import {DashboardWrapperComponent} from "./dashboard-wrapper.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {CommonPipesModule} from "../../shared/pipes/common-pipes.module";

@NgModule({
    imports: [
        CommonModule,
        DashboardRoutingModule,
        WidgetModule,
        FaIconComponent,
        CommonPipesModule,
        DragDropModule
    ],
    declarations: [
        DashboardComponent,
        DashboardV2Component,
        DashboardWrapperComponent
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: TranslateService.LOCALE
        }
    ]
})

export class DashboardModule {
}
