<default-popup-wrapper-component [componentRef]="this" [disableButtons]="readOnly"
                                 [hideButtons]="isLoading" [globalPopup]="globalPopup"
                                 [title]="((activityDropDownData && activityDropDownData.length > 0) ?
                                    'check-activity.createtitle':'check-activity.updatetitle') | translate"
                                 (onClose)="closePopup($event)">

    <ng-container *ngIf="activityDropDownData && activityDropDownData.length > 0">
        <div class="dynamic-form mb-3">
            <div class="d-flex mt-1 ">
                <label class="m-0 form-label-part">
                    {{'Kies type:' | translate}}
                </label>
                <div class="d-flex w-100">
                    <select #activityDropDown name="activityDropDown" [disabled]="isLoading"
                            class="form-control p-0 m-0" (change)="handleChangeActivity($event)">
                        <ng-container *ngFor="let data of activityDropDownData">
                            <option value="{{data.id}}">{{data.label}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="isLoading" class="py-3 d-flex justify-content-center">
        <div class="loader"></div>
    </div>

    <ng-container *ngIf="formData && config">
        <form-component #form
                        [config]="config"
                        [name]="getFormName()"
                        [validationConstraints]="validationConstraints"
                        [invalidControlsErrors]="invalidControlsErrors"
                        [readOnly]="readOnly"
                        [formIsSubmitted]="formIsSubmitted"
                        (onComponentEvent)="handleComponentEvent($event)"
        ></form-component>
    </ng-container>
    <div class="empty-form mt-3 mb-3" *ngIf="isEmptyForm">
        {{'edit-activity.no-form' | translate}}
    </div>
</default-popup-wrapper-component>
