/**
 * Created by Christiaan on 15/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone, OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import {MapCoreComponent} from './map-core.component';
import {MapItem} from './map-item/map-item';
import MapIconGenerator from '../../utils/map-icon-generator';
import {TranslateService} from '../../services/translate/translate.service';
import {CyclomediaInterface} from '../../interfaces/areaal';
import Utils from '../../utils/utils';
import {LatLng} from './lat-lng';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {RequestFailure} from '../../services/http/request-failure';
import {GlobalModel} from '../../services/state/global.model';
import {LoggerService} from "../../services/logger/logger.service";

declare var google: any;
declare const StreetSmartApi: any;

@Component({
    selector: 'streetview-core-component',
    template: `
        <div #streetviewPlaceholder class="streetview-panorama"></div>
        <div class="card streetview-options-container map-button {{this.streetViewMapType==='google'?'':'float-right'}}">
            <div class="streetview-options-button" (click)="handleClickClose($event)"
                 title="{{'Sluit Streetview'|translate}}">
                <i class="material-icons">close</i>
            </div>
            <div *ngIf="cyclomediaSettings && cyclomediaSettings.enabled && isCyclomediaInitialized"
                 class="streetview-options-button" (click)="handleClickSwitch($event)"
                 title="{{'Switch van Streetview'|translate}}">
                <i class="material-icons">switch_camera</i>
            </div>
            <div *ngIf="!ALWAYS_ENABLE_LINKMODE" class="streetview-options-button {{linkModeEnabled?'selected':''}}"
                 (click)="handleClickLink($event)" title="Auto-link met laatst geselecteerde item">
                <i class="material-icons">link</i>
            </div>
        </div>
        <div *ngIf="showNoLocation" class="map-component-info-container">
            <div class="loading-form">
                <div class="map-component-no-marker">{{'Locatie niet gevonden op Streetview' | translate}}</div>
            </div>
        </div>
    `
})

export class StreetviewCoreComponent implements AfterViewInit, OnDestroy {
    private static readonly MAX_STREETVIEW_RADIUS_FROM_TARGET: number = 50;
    private static readonly ALWAYS_ENABLE_LINKMODE: boolean = true; // Disable the linkbutton and always enable linkmode

    @ViewChild('streetviewPlaceholder', {static: false}) streetviewPlaceholder: ElementRef;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Input('streetViewMapType') public streetViewMapType: string;
    @Input('cyclomediaSettings') public cyclomediaSettings: CyclomediaInterface;

    public linkModeEnabled = StreetviewCoreComponent.ALWAYS_ENABLE_LINKMODE;
    public showNoLocation: boolean = false;
    public isCyclomediaInitialized: boolean = false;
    private streetviewMap: any;
    private streetViewService: any;
    private markers: any = []; // Reference to all markers on the map
    private panoramaData: any = null;
    private infoWindow: any;
    private mapIconGenerator: MapIconGenerator;
    private latestAddress: LatLng = null;
    private mapItem: MapItem = null;
    private StreetSmartViewer: any = null;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private ts: TranslateService,
        protected elementRef: ElementRef,
        private alertService: GlobalAlertService,
        private model: GlobalModel,
        protected logger:LoggerService
    ) {
        // super(elementRef);
        this.streetViewService = new google.maps.StreetViewService;
        this.infoWindow = new google.maps.InfoWindow({disableAutoPan: MapCoreComponent.DISABLE_AUTO_PAN_INFOWINDOW});
        this.mapIconGenerator = new MapIconGenerator(ts, this.handleMarkerIconLoaded, this.logger);
    }

    get ALWAYS_ENABLE_LINKMODE(): boolean {
        return StreetviewCoreComponent.ALWAYS_ENABLE_LINKMODE;
    }

    ngAfterViewInit() {
        // this.ngZone.runOutsideAngular(()=>{
        //     this.createMap();
        // });
        if (this.streetViewMapType === 'google') {
            this.initGoogleStreetview();
        } else {
            this.initCyclomedia();
        }
    }

    public setTarget(target: MapItem): void {
        this.latestAddress = {lat: target.lat, lng: target.lng};
        this.mapItem = target;

        if (this.streetViewMapType === 'cyclomedia' && this.isCyclomediaInitialized) {
            this.openCyclomediaQuery();
        } else {
            this.googleSetPosition(target);
        }
    }

    public handleSelectMapItem(mapItem: any): void {
        if (this.linkModeEnabled) {
            this.setTarget(mapItem);
        }
    }

    public handleClickClose(event: any): void {
        this.onClose.emit(event);
    }

    public handleClickSwitch(event: any): void {
        if (this.streetViewMapType === 'google' && this.isCyclomediaInitialized) {
            this.streetViewMapType = 'cyclomedia';
            if (this.streetviewMap) {
                this.streetviewMap.setVisible(false);
            }
            this.showNoLocation = false;
            this.openCyclomediaQuery();
        } else {
            this.streetViewMapType = 'google';
            if (this.streetviewMap) {
                this.streetviewMap.setVisible(true);
            }
            this.initGoogleStreetview();
        }
    }

    public handleClickLink(event: any): void {
        this.logger.log('[StreetviewCoreComponent] ' + 'HANDLE CLICK LINK');
        // event.preventDefault();
        this.linkModeEnabled = !this.linkModeEnabled;
    }

    public showMap(): void {
        // Incorrect resize fix
        this.onResize(null);
    }

    // Might be useful in the future to open addresses. Cyclomedia can't handle Country name.
    // private trimCyclomediaQuery(query:string):string{
    //     let conditions = ['Netherlands', ', Netherlands', ',Netherlands', 'netherlands', ', netherlands', ',netherlands']
    //     if(conditions.some(el => query.includes(el))){
    //         // Replace all possible instance of Netherlands with empty. the i stands for no case-sensitive mode
    //         query = query.replace(/Netherlands|, Netherlands|,Netherlands/gi, '')
    //         if(query.includes(',')){
    //             return query.replace(/,/g, '');
    //         }
    //     }
    // }


    private handleMarkerIconLoaded(iconName: any): void {

        // TODO: a marker icon is loaded, you could redraw invisible icons now.

    }

    private initGoogleStreetview(): void {
        if (this.streetviewMap === undefined) {
            let panoOptions = {
                panControl: false,
                addressControl: false,
                linksControl: false,
                zoomControlOptions: true,
                enableCloseButton: false
            };
            this.streetviewMap = new google.maps.StreetViewPanorama(this.streetviewPlaceholder.nativeElement, panoOptions);
            this.googleSetPosition(this.mapItem);
        } else {
            this.googleSetPosition(this.mapItem);
        }
    }

    private googleSetPosition(mapItem: MapItem) {
        if (!this.latestAddress) {
            return;
        }
        if (!this.streetviewMap) {
            this.initGoogleStreetview();
        }
        let newTarget: any = new google.maps.LatLng({lat: this.latestAddress.lat, lng: this.latestAddress.lng});
        // Find the closest streetview position
        this.streetViewService.getPanoramaByLocation(
            newTarget,
            StreetviewCoreComponent.MAX_STREETVIEW_RADIUS_FROM_TARGET,
            (panoData: any) => {
                this.panoramaData = panoData;

                // Sometimes the panoramaData is not picked up by binding, so trigger manual changes
                // this.changeDetectorRef.detectChanges();

                // If a streetview position was found within range
                if (this.panoramaData != null) {
                    // Store that position
                    let panoCenter = this.panoramaData.location.latLng;

                    this.streetviewMap.setPosition(panoCenter);

                    if (this.showNoLocation) {
                        this.showNoLocation = false;
                    }

                    // Calculate the heading from the streetview position to the target
                    let heading = google.maps.geometry.spherical.computeHeading(panoCenter, newTarget);

                    // Apply the heading to the map
                    let pov = this.streetviewMap.getPov();
                    pov.heading = heading;
                    this.streetviewMap.setPov(pov);

                    // Add a marker on the target position
                    this.addMarker(mapItem);
                    // For debug
                    // this.logger.log("[StreetviewCoreComponent] " + "Set new heading for mapitem: ", target);
                    // this.logger.log("[StreetviewCoreComponent] " + "POV: ", pov);

                } else {
                    this.logger.log('[StreetviewCoreComponent] ', 'no google streetview result');
                    this.showNoLocation = true;
                    this.handleNoStreetviewResult();
                }
            }
        );
    }

    private initCyclomedia(): void {
        // Set variables for initialization.
        let options = {
            targetElement: this.streetviewPlaceholder.nativeElement,
            username: this.cyclomediaSettings.username,
            password: this.cyclomediaSettings.password,
            srs: 'EPSG:28992',
            apiKey: this.cyclomediaSettings.apiKey,
            locale: 'en-US',
            overlayDrawDistance: 30,
            addressSettings: {
                locale: 'nl',
                database: 'CMDatabase'
            }
        };

        this.model.loadScript('https://unpkg.com/react@18.2.0/umd/react.production.min.js', ()=> {
            this.model.loadScript('https://unpkg.com/react-dom@18.2.0/umd/react-dom.production.min.js', ()=> {
                this.model.loadScript('https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@main/dist/en/v6.12.0/build/ol.js',  ()=> {
                    this.model.loadScript('https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js', ()=> {
                        this.model.loadScript('https://streetsmart.cyclomedia.com/api/v24.9/StreetSmartApi.js', ()=> {
                            setTimeout(() => {
                                if (typeof StreetSmartApi !== 'undefined') {
                                    StreetSmartApi.init(options).then(() => {
                                        this.isCyclomediaInitialized = true;
                                        this.logger.log('[StreetviewCoreComponent] ', 'Cyclomedia successfully initialized');
                                    }, (reason) => {
                                        this.isCyclomediaInitialized = false;
                                        if (!this.model.streetSmartWarningShown) {
                                            this.model.streetSmartWarningShown = true;
                                            this.logger.log('[StreetviewCoreComponent] Streetsmart is niet goed geinitialiseerd, check de areaalinstellingen');
                                        }
                                        this.logger.log('[StreetviewCoreComponent] ', reason);
                                    });
                                }
                                else {
                                    this.isCyclomediaInitialized = false;
                                    if (!this.model.streetSmartWarningShown) {
                                        this.model.streetSmartWarningShown = true;
                                        this.logger.log('[StreetviewCoreComponent] Streetsmart is niet goed geinitialiseerd, check de areaalinstellingen');
                                    }
                                }
                            })

                        })
                    })
                })
            })
        })
    }

    private openCyclomediaQuery() {
        let coords = Utils.wgs2rd_2(this.latestAddress.lat, this.latestAddress.lng);
        let query = [];
        coords.forEach((coord: any) => {
            query.push(coord);
        });
        query.reverse();
        this.logger.log('[StreetviewCoreComponent] Cyclomedia query > ', query);
        if(typeof this.StreetSmartViewer !== 'undefined'){
            if (this.StreetSmartViewer === null) {
                if (typeof StreetSmartApi !== 'undefined') {
                    StreetSmartApi.open(query.toString(), {
                        viewerType: [StreetSmartApi.ViewerType.PANORAMA],
                        srs: 'EPSG:28992',
                        panoramaViewer: {
                            navbarVisible: true,
                            sidebarVisible: true
                        }
                    }).then((result) => {
                        this.StreetSmartViewer = StreetSmartApi.getViewers()[0];
                        this.StreetSmartViewer.toggleButtonEnabled('panorama.openOblique');
                        this.StreetSmartViewer.toggleButtonEnabled('panorama.reportBlurring');
                        this.StreetSmartViewer.toggleButtonEnabled('panorama.overlays');
                        this.logger.log('[StreetviewCoreComponent] opened viewer', result);
                        this.showNoLocation = false;
                    }).catch((reason) => {
                        this.logger.log('Error opening viewers: ' + reason);
                        this.showNoLocation = true;
                    });
                }
            } else {
                this.StreetSmartViewer.openByCoordinate(query)
                    .then((result) => {
                        this.logger.log('[StreetviewCoreComponent] opened coordinate', result);
                        this.showNoLocation = false;
                    }).catch((reason) => {
                    this.logger.log('[StreetviewCoreComponent] error opening coordinate', reason);
                    this.showNoLocation = true;
                });
            }
        }
    }

    private addMarker(target: MapItem): void {
        this.clearMarkers();

        let marker: any = new google.maps.Marker({
            map: this.streetviewMap,
            icon: this.mapIconGenerator.getIconWithLabel(target),
            dataRef: target,
            position: target
        });

        // Mouseover is disabled for now, keep the code
        // Streetview is used to see a map item in it's real surroundings.
        // If we implement a mouseover people also expect to be able to select baseobjects and see the status.
        // That functionality is already available on the map and form, and implementing it here too will only
        // overcomplicate things (for the user and for development)
        /*marker.addListener('mouseover', (googleMapsMarker:any) => (
            (marker:any) => {
                this.handleMouseOverMarker(marker);
            }
        )(marker));*/

        this.markers.push(marker);
    }

    private handleMouseOverMarker(marker: any): void {
        this.infoWindow.setContent(this.mapIconGenerator.constructLabel(marker.dataRef));
        this.infoWindow.open(this.streetviewMap, marker);
    }

    private clearMarkers(): void {
        while (this.markers.length) {
            this.markers.pop().setMap(null);
        }
    }

    private handleNoStreetviewResult(): void {
        this.clearMarkers();
        this.logger.log('[StreetviewCoreComponent] ' + 'Geen streetview result gevonden');
    }

    private onResize(event: any): void {
        // Fix for incorrect display of map center after resizing
        // The stored center is reapplied to keep the map center in center after resizing
        google.maps.event.trigger(this.streetviewMap, 'resize');
        // this.streetviewMap.setCenter(this.currentCenter);

        // Fix for content of infowindow being erased, leaving a messed-up popup
        this.infoWindow.close();
    }

    ngOnDestroy() {
        if (typeof StreetSmartApi !== 'undefined') {
            StreetSmartApi.destroy({targetElement: this.streetviewPlaceholder.nativeElement});
            this.logger.log('[StreetviewCoreComponent] ' + 'StreetSmartApi destroyed');
        }
    }
}
