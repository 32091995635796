import {IMapItem, IMapItemLabel} from './map-device-mapping.interface';
import {MapDeviceMappingComponent} from './map-device-mapping.component';

export class MapItem {
    public emptyMapItem: IMapItem = {
        baseObjectId: null,
        marker: null,
        icon: null,
        infoWindow:null
    };
    public mapItem: IMapItem = this.emptyMapItem;
    public isSelected:boolean = false;
    public listeners: google.maps.MapsEventListener[] = [];
    public infoWindow: google.maps.InfoWindow = null;

    constructor(private mapDeviceMappingComponent: MapDeviceMappingComponent) {}

    public initGoogleObject(baseObjectId:number, icon:string, lat:number, lng:number, infoWindow:IMapItemLabel|null, clickable:boolean, sourceItem:boolean, isSelected:boolean): void {
        //Initialize the map item
        if (this.mapItem.marker === null) {
            this.mapItem.baseObjectId = baseObjectId;
            this.mapItem.marker = new google.maps.Marker({
                icon: '/assets/img/lumicon/16x16/'+icon,
                draggable:false,
                clickable:clickable,
                visible: true,
                position: new google.maps.LatLng(lat,lng),
                zIndex: sourceItem ? 99 : 1
            });
            this.mapItem.icon = '/assets/img/lumicon/16x16/'+icon;
            this.mapItem.marker.setMap(this.mapDeviceMappingComponent.map)

            if(isSelected){
                this.selectMarker(false)
            }
            if(infoWindow){
                this.createInfoWindow(infoWindow)
            }
            this.createListeners()
        }
    }

    private createInfoWindow(infoWindow:IMapItemLabel):void{
        this.infoWindow = new google.maps.InfoWindow({
            content: `<div>
                    <strong>${this.mapDeviceMappingComponent.ts.translate('asset-mapping.label.code')}:</strong> ${infoWindow.code}<br />
                   </div>`,
            position: new google.maps.LatLng(this.mapItem.marker.getPosition().lat(), this.mapItem.marker.getPosition().lng()),
            ariaLabel: 'mapitem-infowindow',
            disableAutoPan: true,
        })
        //Offset so info window is above icon
        this.infoWindow.set('pixelOffset', new google.maps.Size(0, -25))
    }

    private showInfoWindow():void{
        this.infoWindow.open({map:this.mapDeviceMappingComponent.map})
    }

    public hideInfoWindow():void{
        this.infoWindow.close()
    }

    private selectMarker(loadInfo:boolean):void{
        //Remove selected from any other markers
        this.mapDeviceMappingComponent.clearSelectedMarkers()
        //Set current marker as selected
        this.isSelected = true;
        this.mapDeviceMappingComponent.selectMarker(this.mapItem,loadInfo)
    }

    private createListeners():void{
        this.listeners.push(google.maps.event.addListener(this.mapItem.marker, 'click', () => {
            this.selectMarker(true)
        }));
        this.listeners.push(google.maps.event.addListener(this.mapItem.marker, 'mouseover', () => {
            this.showInfoWindow()
        }));
        this.listeners.push(google.maps.event.addListener(this.mapItem.marker, 'mouseout', () => {
            this.hideInfoWindow()
        }));
    }

    private removeListeners(){
        this.listeners.map(_listener =>{
            google.maps.event.removeListener(_listener)
        })
    }

    public removeFromMap(): void {
        if (this.mapItem.marker !== null) {
            this.mapItem.marker.setVisible(false)
            this.mapItem.marker.setMap(null);
            this.mapItem = this.emptyMapItem;
            this.isSelected = false;
            this.removeListeners()
        }
    }
}
