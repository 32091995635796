import {Component, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, OnInit} from '@angular/core';
import {DashboardService} from './dashboard.service';
import {GlobalModel} from '../../shared/services/state/global.model';
import {Subscription} from 'rxjs';
import {HTTPService} from '../../shared/services/http/http.service';
import {Dashboard} from './dashboard';
import {StorageService} from '../../shared/services/storage/storage.service';
import {take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggerService} from "../../shared/services/logger/logger.service";
import {IDashboard, IGenericIcons} from "./dashboard.interface";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import {
    faLeaf,
    faChartSimple,
    faChartBar,
    faChartLine,
    faChartColumn,
    faCubesStacked,
    faTableCellsLarge,
    faLayerGroup,
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import {AppSettings} from "../../app.settings";
import {TranslateService} from "../../shared/services/translate/translate.service";
import * as dayjs from 'dayjs'
import {IHistoryList} from "../../dynamic-menu/menu/menu-history/history.interface";

@Component ({
    selector: 'dashboard-V2-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dashboard-V2.component.html',
    styleUrls: ['./dashboard-V2.component.scss']
})

export class DashboardV2Component implements OnDestroy, OnInit {
    protected subscriptions: Subscription[] = [];

    public static readonly WIDGET_TYPE_TEXT: string = 'TEXT';
    public static readonly WIDGET_TYPE_CHART: string = 'GRAPH';
    public static readonly WIDGET_TYPE_MAP: string = 'MAP';
    public static readonly WIDGET_TYPE_TABLE: string = 'TABLE';
    public static readonly WIDGET_TYPE_FEED: string = 'FEED';
    public static readonly WIDGET_TYPE_STATIC: string = 'STATIC';

    public currentDashboard: Dashboard;
    public availableDashboards:IDashboard[] = [];
    public activeDashboard:IDashboard = null;
    public showInfo:boolean = false;
    private genericIconList:IGenericIcons[] = [];
    private settingsUrl:string = 'settings';
    private infoUrl:string = 'info';

    public areaName:string = this.model.currentAreaal.getValue().label;
    public areaLogo:string = this.model.currentAreaal.getValue().logo;

    constructor(
        private dashboardService: DashboardService,
        public model: GlobalModel,
        private httpService: HTTPService,
        private changeDetectorRef: ChangeDetectorRef,
        protected elementRef: ElementRef,
        private storageService: StorageService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        protected logger:LoggerService,
        private ts:TranslateService
    ) {
        this.subscriptions.push(this.model.currentDashboard.subscribe((currentDashboard: Dashboard) => {
            this.currentDashboard = currentDashboard;
            this.changeDetectorRef.markForCheck();
        }));
        this.subscriptions.push(this.httpService.pendingCallPaths.subscribe((paths: string[]) => {
            this.changeDetectorRef.markForCheck();
        }));
    }
    
    ngOnInit(): void {
        this.buildGenericIconList();
        this.subscriptions.push(this.model.dashboardV2Items.subscribe((value: []) => {
            if (value.length > 0) {
                this.buildGenericIconList();
                this.buildDashboardItems();
                this.activatedRoute.params.pipe(take(1)).subscribe(params => {
                    if(params && params.dashboardName){
                        this.changeDashboard(params.dashboardName);
                    } else {
                        this.changeDashboard(null);
                    }
                });
            }
        }));
    }

    public timeOfDay():string{
        const hour = dayjs().hour();
        if (hour >= 5 && hour < 12) {
            return this.ts.translate('dashboard.goodmorning')
        } else if (hour >= 12 && hour < 18) {
            return this.ts.translate('dashboard.goodafternoon')
        } else if (hour >= 18 && hour < 22) {
            return this.ts.translate('dashboard.goodevening')
        } else {
            return this.ts.translate('dashboard.goodnight')
        }
    }

    public getLastFormItemFromHistory():IHistoryList{
        return this.model.historyList.length > 0 ? this.model.historyList[0] : null;
    }

    public clickHistoryItem(){
        this.model.mobileStateBack.next(null); //Reset mobile state to null otherwise last state is used and this might not be the form (state 2).
        this.router.navigateByUrl(this.getLastFormItemFromHistory().urlFull);
    }

    public getFullName():string{
        const user = this.model.user.getValue();
        return user.infix ? user.firstName+' '+user.infix+' '+user.surname : user.firstName+' '+user.surname;
    }

    public openDashboard(dashboardUrl:string):void{
        if(dashboardUrl != this.activeDashboard?.url){
            dashboardUrl = this.handleDashboardUrl(dashboardUrl);
            this.router.navigate(['/dashboard/'+dashboardUrl]).then(_x => {
                this.changeDashboard(dashboardUrl);
            });
        }
    }

    private changeDashboard(dashboardUrl:string):void{
        dashboardUrl = this.handleDashboardUrl(dashboardUrl);
        this.availableDashboards.map(_x => {
            _x.active = _x.url === dashboardUrl;
        })
        if(dashboardUrl == this.settingsUrl) {
        } else if(dashboardUrl == this.infoUrl){
            this.showInfoPage();
        } else {
            this.closeInfoPage();
            this.activeDashboard = this.availableDashboards.find(_x => _x.active);
            this.dashboardService.getDashboard(this.activeDashboard.uri).pipe(take(1)).subscribe();
            this.storageService.setValue(StorageService.KEY_LAST_USED_DASHBOARD, this.activeDashboard.id);
        }
    }

    private handleDashboardUrl(dashboardUrl:string):string{
        if(dashboardUrl != this.settingsUrl && dashboardUrl != this.infoUrl && this.availableDashboards.filter(_x => {return _x.url == dashboardUrl}).length == 0){
            return this.availableDashboards.find(_x => {return _x.uri == this.getLastUsedDashboard()}).url;
        } else {
            return dashboardUrl;
        }
    }


    private showInfoPage():void{
        this.activeDashboard = null;
        this.showInfo = true;
    }

    private closeInfoPage():void{
        this.showInfo = false;
    }

    private buildDashboardItems():void{
        this.resetDashboard();
        this.model.dashboardV2Items.value.map(_y => {
            this.availableDashboards.push({
                id:_y.id,
                name:_y.label,
                faIcon: !_y.icon ? (_y.code == 'energy-report' ? faLeaf : this.getRandomIcon()) : null,
                url:(_y.label.toLowerCase().replace(/\s/g, '')),
                uri:_y.uri,
                dashboardCode: _y.code,
                active: false,
                showSeparatorLine: false
            })
        })

        this.availableDashboards.push({
            id: null,
            name:this.ts.translate('info'),
            faIcon: faCircleInfo,
            url:this.infoUrl,
            uri:'',
            dashboardCode: 'info',
            active: false,
            showSeparatorLine: true
        })
    }

    private resetDashboard():void{
        this.availableDashboards = [];
        this.activeDashboard = null;
    }

    private buildGenericIconList():void{
        //Make sure you import the icon
        this.genericIconList.push(
            {icon:faChartSimple,used:false},
            {icon:faChartBar,used:false},
            {icon:faChartLine,used:false},
            {icon:faChartColumn,used:false},
            {icon:faCubesStacked,used:false},
            {icon:faTableCellsLarge,used:false},
            {icon:faLayerGroup,used:false},
        )
    }

    private getRandomIcon():IconDefinition{
        let availableIcons = this.genericIconList.filter(_x => {return !_x.used})
        const currentIcon = availableIcons[Math.floor(Math.random()*availableIcons.length)].icon;
        this.genericIconList.find(_x => {return _x.icon === currentIcon}).used = true;
        return currentIcon;
    }

    // Get last used dashboard from local storage
    private getLastUsedDashboard(): string {

        // Set default dashboard
        let resultUri: string = this.model.dashboardV2Items.value[0].uri;
        let matchFound: boolean = false;

        // Check if present in storage
        this.storageService.getStringValue(StorageService.KEY_LAST_USED_DASHBOARD, (value: string) => {
            this.logger.log('[DashboardComponent] ' + 'Last used dashboard found. ID: ' + value);

            // Check for match with dashboard items
            this.model.dashboardV2Items.value.forEach((dashboard: any) => {
                if (dashboard.id == value) {
                    // Found a match with a existing dashboard, you can use this value now
                    this.logger.log('[DashboardComponent] ' + 'Match found, return uri: ' + dashboard.uri);
                    resultUri = dashboard.uri;
                    matchFound = true;
                }
            });

            if (!matchFound) {
                this.logger.log('[DashboardComponent] ' + 'No match found, return default dashboard');
            }
        });

        this.logger.log('[DashboardComponent] ' + 'Load dashboard uri: ' + resultUri);

        return resultUri;
    }

    // You can't reach static/global vars from template, only local vars, so use this workaround...
    get WIDGET_TYPE_TEXT() {
        return DashboardV2Component.WIDGET_TYPE_TEXT;
    }

    get WIDGET_TYPE_CHART() {
        return DashboardV2Component.WIDGET_TYPE_CHART;
    }

    get WIDGET_TYPE_MAP() {
        return DashboardV2Component.WIDGET_TYPE_MAP;
    }

    get WIDGET_TYPE_TABLE() {
        return DashboardV2Component.WIDGET_TYPE_TABLE;
    }

    get WIDGET_TYPE_FEED() {
        return DashboardV2Component.WIDGET_TYPE_FEED;
    }

    get WIDGET_TYPE_STATIC() {
        return DashboardV2Component.WIDGET_TYPE_STATIC;
    }

    public isDashboardLoading(): boolean {
        return this.httpService.isPendingCallPath([DashboardService.GET_DASHBOARD_PATH]);
    }

    public getLuminizerVersion():string {
        return AppSettings.VERSION_ARRAY[0] + "." + AppSettings.VERSION_ARRAY[1] + "." + AppSettings.VERSION_ARRAY[2];
    }

    public getAreasAvailableText(numAreas:number):string{
        return this.ts.translate("areasavailable", [numAreas]);
    }

    public getVersionNumber():string {
        return AppSettings.VERSION_ARRAY[0] + "." + AppSettings.VERSION_ARRAY[1];
    }

    ngOnDestroy(): any {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
