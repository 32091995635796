/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Renderer2,
} from '@angular/core';

import {GlobalAlertService} from '../global-alert.service';
import {ButtonInterface, GlobalPopup} from '../global-popup';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {Subscription} from 'rxjs';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {Areaal, WmsDataInterface} from '../../../shared/interfaces/areaal';
import {MapCoreComponent} from '../../../shared/components/map/map-core.component';
import {GlobalEvent} from '../../../shared/interfaces/global-event';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {AuthorizationService} from '../../../shared/services/authorization/authorization.service';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {PopupDisplayMode} from './default-popup-wrapper/default-popup-wrapper.interface';
import {PrintService} from '../../../shared/services/print/print.service';
import {TableOptionsSet} from "../../../shared/components/table/tableColumnSelector/table-options";
import {LumiSelectOption} from "../../../shared/components/commonUI/select/lumi-select/lumi-select.interface";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-map-option-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: ['.heatmap-dowpdown-container{position:relative;} .heatmap-dowpdown-container .loading-block-absolute{border-radius:0;} .close-icon{font-size:14px;cursor:pointer;}'],
    template: `
        <default-popup-wrapper-component [displayMode]="POPUP_DISPLAY_MODE.SMALL" [globalPopup]="globalPopup"
                                         [title]="'map.layers' | translate" (onClose)="closePopup($event)">
            <hr class="my-0">
            <div class="mt-2 mb-1"><b>{{'Kaarttype' | translate}}</b></div>
            <div class="mb-1" title="{{'layers.full' | translate}}">
                <ng-container *ngFor="let mapType of MAP_TYPES">
                    <div class="" (click)="handleClickMapType($event,mapType)">
                        <input name="group1" class="with-gap" type="radio" id="{{mapType.code}}"
                               [checked]="mapTypeId == mapType.code">
                        <label for="{{mapType.code}}">{{mapType.label}}</label>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="!this.isBasicMode" class="mt-2 mb-1"><b>{{'Zichtbaarheid' | translate}}</b></div>
            <div *ngIf="!mobileMode && !this.isBasicMode" class="form-inline map-option-row pt-0"
                 title="{{'layers.mappopupfull' | translate}}">
                <div class="map-option-label">
                    <div>{{'layers.mappopup' | translate}}</div>
                </div>
                <lumi-switch [initialValue]="mapPopupVisible"
                             (onSwitch)="handleToggleMapPopup()">
                </lumi-switch>
            </div>
            <div *ngIf="!this.isBasicMode" class="form-inline map-option-row {{mobileMode?'pt-0':''}}"
                 title="{{'layers.showlabelsfull' | translate}}">
                <div class="map-option-label">
                    <div>{{'layers.showlabels' | translate}}</div>
                </div>
                <lumi-switch [initialValue]="markerLabelsVisible"
                             (onSwitch)="handleToggleMarkerLabels()">
                </lumi-switch>
            </div>
            <div *ngIf="!this.isBasicMode" class="form-inline map-option-row" title="{{'poi.full' | translate}}">
                <div class="map-option-label">
                    <div>{{'Toon POI' | translate}}</div>
                </div>
                <lumi-switch [initialValue]="POIVisible"
                             (onSwitch)="handleTogglePOI()">
                </lumi-switch>
            </div>
            <div *ngIf="!this.isBasicMode" class="form-inline map-option-row" title="{{'wms.full' | translate}}">
                <div class="map-option-label">
                    <div>{{'Toon WMS-lagen' | translate}}</div>
                </div>
                <lumi-switch [initialValue]="isWmsUrlAvailable() && wmsLayerVisible"
                             (onSwitch)="handleClickWMSToggle(); handleToggleWMSLayer()"
                             [disabled]="!isWmsUrlAvailable()">
                </lumi-switch>
                <div class="map-option-label w-100" *ngIf="isMobileUserAgent">
                    <div class="badge badge-pill badge-warning">{{'mapoptions.wmsmobilelayersinfo' | translate}}</div>
                </div>
            </div>
            <ng-container *ngFor="let wmsData of _wmsData, let i = index">
                <div *ngIf="!this.isBasicMode" class="form-inline map-option-row ml-4" title="{{wmsData.label}}">
                    <div class="map-option-label">
                        <div>{{wmsData.label}}</div>
                    </div>
                    <lumi-switch [initialValue]="isSingleWmsUrlAvailable(i) && wmsData.active && wmsLayerVisible && !wmsData.disabled"
                                 (onSwitch)="handleClickSingleWMSToggle($event, i); handleWmsLayers($event, i)"
                                 [disabled]="!isSingleWmsUrlAvailable(i) || !wmsLayerVisible || wmsData.disabled">
                    </lumi-switch>
                </div>
            </ng-container>
            <div *ngIf="!this.isBasicMode" class="form-inline map-option-row">
                <div class="map-option-label">
                    <div>{{ 'mapoptions.clustertoggle' | translate }}</div>
                </div>
                <lumi-switch [initialValue]="mapClustererVisible"
                             (onSwitch)="handleToggleMapClusters()">
                </lumi-switch>
            </div>
            <div *ngIf="!this.isBasicMode && this.model.heatmapValueOptions?.length > 1" class="form-inline map-option-row" title="{{'layers.heatmap.title' | translate}}">
                <div class="map-option-label">
                    <div>{{'layers.heatmap.title' | translate}} <i class="material-icons p-0 align-middle" title="{{'layers.heatmap.warning' | translate}}">info</i></div>
                </div>
                <div class="map-option-dropdown heatmap-dowpdown-container">
                    <div class="loading-block-absolute d-flex justify-content-center align-items-center" *ngIf="_isHeatmapLoading">
                        <div class="d-none d-md-block"><img src="/assets/img/loading2022.gif" alt="{{'loading' | translate}}" title="{{'loading' | translate}}" /></div>
                    </div>
                    <lumi-select [options]="model.heatmapValueOptions"
                                 [selectedOptions]="getSelectedHeatmapOption()"
                                 [showOptionFilter]="model.heatmapValueOptions?.length > 10"
                                 (onOptionsSelect)="handleToggleHeatmapPopup($event)">
                    </lumi-select>
                </div>
            </div>
            <div *ngIf="!this.isBasicMode" class="mt-2 mb-1"><b>{{'layers.additional' | translate}}</b></div>
            <div *ngIf="!mobileMode && !this.isBasicMode" class="form-inline map-option-row pt-0"
                 title="{{'layers.additional.filefull' | translate}}">
                <div class="map-option-label">
                    <div>{{'layers.additional.file' | translate}}</div>
                </div>
                <file-selector [multiInput]="false"
                               [buttonOnly]="true"
                               (onChanges)="handleAdditionalLayer($event)">
                </file-selector>
            </div>
            <div class="mt-3" *ngIf="!this.isBasicMode && model.tableColumnOptions.length > 0">
                <div class="map-option-label custom-label d-flex justify-content-between align-items-center mr-0">
                    <div>{{'mapoptions.categorize-per-color.label' | translate}}</div>
                    <i class="material-icons close-icon m-0 p-0" (click)="handleCategorizeByColorChange(null)" *ngIf="model.selectedTableColumnColorCode != ''">close</i>
                </div>
                <lumi-select [options]="model.tableColumnOptions"
                             [selectedOptions]="getSelectedTableColumnColor()"
                             [showOptionFilter]="model.tableColumnOptions.length > 10"
                             (onOptionsSelect)="handleCategorizeByColorChange($event)">
                </lumi-select>
            </div>
            <div *ngIf="!mobileMode && !this.isBasicMode && this.model.additionalLayerList.length > 0"
                 class="form-inline map-option-row pt-0 font-weight-bold mt-3"
                 title="{{'layers.additional.list' | translate}}">
                <div class="map-option-label w-100 font-weight-bold">
                    <div>{{'layers.additional.list' | translate}}</div>
                </div>
                <div *ngFor="let layer of this.model.additionalLayerList" class="w-100 my-2">
                    <div class="d-inline-block" style="margin-top: 8px">{{layer}}</div>
                    <div class="float-right">
                        <lumi-button (onClick)="handleClickDeleteLayer(layer)"
                                     [rank]="'secondary'"
                                     [label]="'layers.additional.delete' | translate">
                        </lumi-button>
                    </div>
                </div>
            </div>
            <div *ngIf="!mobileMode && !this.isBasicMode" class="form-inline map-option-row pt-3 ml-0"
                 title="{{'mapoptions.printmap' | translate}}">
                <div class="switch mr-auto">
                    <p class="d-flex align-items-center"><i
                            class="material-icons pl-0 cursor-pointer text-color-secondary-blue"
                            (click)="printService.printMap()">print</i> {{'mapoptions.printmap' | translate}}</p>
                </div>
            </div>
        </default-popup-wrapper-component>
    `,
})
export class GlobalPopupMapOptionComponent extends AbstractBasePopupComponent implements AfterViewInit{
    private static MAP_TYPES: MapTypeInterface[] = []; //[{code:'roadmap', label:'Google Maps'}, {code:"OSM", label:"OpenStreetMap"}, {code:"satellite", label:'Satelliet'}];

    public POPUP_DISPLAY_MODE = PopupDisplayMode;
    mapTypeId: string;
    wmsLayerVisible: boolean;
    POIVisible: boolean;
    heatmapOptionCode: string;
    mapPopupVisible: boolean;
    markerLabelsVisible: boolean;
    mapClustererVisible: boolean;
    isMobileUserAgent:boolean = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    hasActiveWmsLayer:boolean = false;

    private subAreaal: Subscription;
    public _wmsData: WmsDataInterface[] = [];
    private subMobileMode: Subscription = null;
    private subIsHeatmapLoading: Subscription;
    mobileMode: boolean = null;
    isBasicMode: boolean = false;
    public _isHeatmapLoading:boolean = false;

    private additionalLayerFile: File = null;
    private fileContent: string = null;

    constructor(
        protected tooltipService: TooltipService, protected elementRef: ElementRef, private renderer: Renderer2,
        public model: GlobalModel, protected cd: ChangeDetectorRef, protected ts: TranslateService,
        protected globalAlertService: GlobalAlertService, private authorizationService: AuthorizationService,
        private storage: StorageService, public printService:PrintService, protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);

        GlobalPopupMapOptionComponent.MAP_TYPES = [
            {
                code: MapCoreComponent.MAP_TYPE_ROADMAP,
                label: ts.translate('Google Maps'),
            },
            {
                code: 'OSM', label: ts.translate('OpenStreetMap'),
            },
            {
                code: 'satellite',
                label: this.ts.translate('Satelliet'),
            },
        ];
    }

    ngAfterViewInit(): void {
        const popupData = this.globalPopup.data;

        this.mapTypeId = popupData.mapTypeId;
        this.wmsLayerVisible = popupData.wmsLayerVisible;
        this.POIVisible = popupData.POIVisible;
        this.heatmapOptionCode = popupData.heatmapOptionCode;
        this.mapPopupVisible = popupData.mapPopupVisible;
        this.markerLabelsVisible = popupData.markerLabelsVisible;
        this.mapClustererVisible = popupData.mapClustererVisible;
        this.isBasicMode = popupData.isBasicMode || this.model.publicPortalMode.value;

        this.subAreaal = this.model.currentAreaal.subscribe((areaal: Areaal) => {
            this._wmsData = areaal.wmsData;
            this.cd.detectChanges();
        });

        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            //Don't forget to unsubscribe
            this.mobileMode = value;
            this.cd.detectChanges(); //Mark for change was not enough here
        });

        this.subIsHeatmapLoading = this.model.isHeatmapLoading.subscribe((value: boolean) => {
            //Don't forget to unsubscribe
            this._isHeatmapLoading = value;
            this.cd.detectChanges();
        });

        this.storage.getObjectValue(StorageService.KEY_USER_MAP_LAYERS, (value: {geoJson: string, fileName: string}[]) => {
            this.model.additionalLayerList = [];
            if (value) {
                for (let i = 0; i < value.length; i++) {
                    this.model.additionalLayerList.push(value[i].fileName);
                }
            }
        });
    }

    getSelectedHeatmapOption() {
        return this.model.heatmapValueOptions.filter(set => String(set.id) === this.heatmapOptionCode) || [];
    }

    handleClickMapType(event: MouseEvent, mapType: MapTypeInterface): void {
        //Prevent event from triggering twice
        if (event) {
            event.preventDefault();
        }
        this.mapTypeId = mapType.code;

        const selectOption = {mapType: mapType, storeChoice: true};
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_SELECT_MAP_TYPE, {selectOption: selectOption}));
        //this.onSelectMapType.emit(selectOption);

    }

    handleTogglePOI(): void {
        //this.onTogglePOI.emit();
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_TOGGLE_POI, {}));
    }

    handleToggleHeatmapPopup(selectedOption: LumiSelectOption[]): void {
        this.heatmapOptionCode = String(selectedOption[0].id);
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_HEATMAP_OPTION_CODE, {selectOption: selectedOption[0].id}));
    }

    handleToggleMapPopup(): void {
        //this.onToggleMapPopup.emit();
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_TOGGLE_MAP_POPUP, {}));
    }

    handleToggleMarkerLabels(): void {
        //this.onToggleMapPopup.emit();
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_TOGGLE_MARKER_LABELS, {}));
    }

    handleToggleWMSLayer(): void {
        // this.onToggleWMS.emit();
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_TOGGLE_WMS_LAYER, {}));
    }
    
    handleToggleSingleWMSLayer(event: WmsDataInterface): void {
        //this.onToggleWMS.emit();
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_TOGGLE_SINGLE_WMS_LAYER, {wmsLayer:event}));
    }

    getSelectedTableColumnColor():TableOptionsSet[] {
        return this.model.tableColumnOptions.filter(set => String(set.code) === this.model.selectedTableColumnColorCode) || [];
    }


    handleAdditionalLayer(event: FileList) {
        const files: FileList = event;
        this.additionalLayerFile = files[0];
        this.model.additionalLayerList.push(files[0].name);
        let storageLayers: {geoJson: string, fileName: string}[] = [];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.fileContent = <string>reader.result;
            this.model.loadUserMapLayer.next({geoJson: this.fileContent, fileName: files[0].name});

            // Check if there is a layer
            if (!this.storage.keyExists(StorageService.KEY_USER_MAP_LAYERS)) {
                // Push layer in array
                storageLayers.push({geoJson: this.fileContent, fileName: files[0].name});
                // Set added layer in storage
                this.storage.setObjectValue(StorageService.KEY_USER_MAP_LAYERS, storageLayers);
            }
            else {
                // If one already exists, get old layer and 'push' new combined layers in storage
                this.storage.getObjectValue(StorageService.KEY_USER_MAP_LAYERS, (value: {geoJson: string, fileName: string}[]) => {
                    // Check if value is in array or not (happens when u delete a layer and want to add a new one)
                    if (value.length > 0) {
                        for (let i = 0; i < value.length; i++) {
                            storageLayers.push(value[i]);
                            // Only add new layer to the end of array
                            if (i >= value.length - 1) {
                                storageLayers.push({geoJson: this.fileContent, fileName: files[0].name});
                            }
                        }
                    }
                });
                // Set new layers
                this.storage.setObjectValue(StorageService.KEY_USER_MAP_LAYERS, storageLayers);
            }

            this.cd.detectChanges();
        });
        reader.readAsText(this.additionalLayerFile);
    }

    handleClickDeleteLayer(layer: string) {
        this.model.deleteUserMapLayer.next(layer);
        this.model.additionalLayerList = this.model.additionalLayerList.filter(function (obj) {
            return obj !== layer;
        });

        // Get all stored layers, check if one equals deleted layer, set new layers (with one removed of array)
        const storageLayers: {geoJson: string, fileName: string}[] = [];
        this.storage.getObjectValue(StorageService.KEY_USER_MAP_LAYERS, (value: {geoJson: string, fileName: string}[]) => {
            if (value) {
                // Store local storage in local array
                for (let i = 0; i < value.length; i++) {
                    if (value[i].fileName !== layer) {
                        storageLayers.push(value[i]);
                    }
                }
                // If array is empty, fully remove storage item to avoid unnecessary storage
                if (storageLayers.length < 1) {
                    this.storage.removeItem(StorageService.KEY_USER_MAP_LAYERS);
                }
                else {
                    this.storage.setObjectValue(StorageService.KEY_USER_MAP_LAYERS, storageLayers);
                }
            }
        });

        this.cd.detectChanges();
    }

    handleWmsLayers(activate:boolean, wmsDataIndex:number){
        //Make sure max only 1 wms layer can be selected for mobile devices
        if(this.isMobileUserAgent && activate){
            this._wmsData.forEach((wmsData, index) => {
                if(index != wmsDataIndex){
                    wmsData.active = true;
                } else {
                    wmsData.active = false;
                }
                this.handleToggleSingleWMSLayer(wmsData);
            });
        } else {
            this.handleToggleSingleWMSLayer(this._wmsData[wmsDataIndex]);
        }

    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: ButtonInterface): void {
        //Hide alert and perform callback of button
        this.onPopupAction.emit({event: event, alert: alert, button: button});
    }

    closePopup(event: MouseEvent): void {
        this.doDefaultCloseHandling(event, false);
    }

    isWmsUrlAvailable(): boolean {
        return (this._wmsData && this._wmsData.length > 0 && this._wmsData[0].url && this._wmsData[0].url != '');
    }

    isSingleWmsUrlAvailable(index:number): boolean {
        return (this._wmsData?.length > 0 && !!this._wmsData[index]?.url);
    }

    handleClickWMSToggle(): void {
        if (!this.isWmsUrlAvailable()) {
            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_NO_CONFIG, this.ts.translate('WMS-lagen'), this.ts.translate('Voor dit areaal is geen WMS ingesteld. Een areaal administrator kan een WMS opgeven bij areaal-instellingen.'));
        } else {
            this.wmsLayerVisible = !this.wmsLayerVisible;
        }
    }
    
    handleClickSingleWMSToggle($event: boolean, index: number): void {
        if (!this.isSingleWmsUrlAvailable(index)) {
            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_NO_CONFIG, this.ts.translate('WMS-lagen'), this.ts.translate('Voor dit areaal is geen WMS ingesteld. Een areaal administrator kan een WMS opgeven bij areaal-instellingen.'));
        }
    }

    handleCategorizeByColorChange(selectedOption: LumiSelectOption[] | null):void{
        if(selectedOption){
            this.model.selectedTableColumnColorCode = selectedOption[0].code;
            setTimeout(()=>{
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_CATEGORIZE_BY_COLOR_ICONS, {columnCode: this.model.selectedTableColumnColorCode}));
            })
        } else {
            this.model.selectedTableColumnColorCode = '';
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MODULE_REFRESH, {}));
        }
    }

    handleToggleMapClusters(): void {
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MAP_TOGGLE_MAP_CLUSTERS, {}));
    }

    //You can't reach static/global vars from template, only local vars, so use this workaround...
    get MAP_TYPES(): MapTypeInterface[] {
        return GlobalPopupMapOptionComponent.MAP_TYPES;
    }

    ngOnDestroy(): void {
        if (this.subMobileMode) {
            this.subMobileMode.unsubscribe();
        }
        if(this.subAreaal){
            this.subAreaal.unsubscribe();
        }
        if(this.subIsHeatmapLoading){
            this.subIsHeatmapLoading.unsubscribe();
        }
    }
}

export interface MapTypeInterface {
    code: string
    label: string
}
