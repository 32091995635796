/**
 * Created by Christiaan on 20/03/2017.
 */
import {GlobalModel} from "./shared/services/state/global.model";
import {TranslateService} from "./shared/services/translate/translate.service";

export class AppSettings {
    
    // Stored here to prevent circular references between services
    public static readonly SWITCH_AREAAL_PATH:string = "switch/a";
    public static readonly GET_LOGIN_TOKEN_PATH:string = "get-token";
    public static readonly CHECK_LOGIN_PATH:string = "application-state/check-login";
    public static readonly LOGIN_PATH:string = "application-state/login";
    
    //All colors are defined in CSS, but in some special cases they are needed here in angular
    //https://color.adobe.com/nl/create/color-wheel/?base=2&rule=Custom&selected=0&name=Mijn%20
    // Color-thema&mode=rgb&rgbvalues=0.796078431372549,0.4745098039215686,0.796078431372549,0.3137254901960784,0.5568627450980392,0.788235294117647,
    // 1,0.7686274509803922,0.38823529411764707,0.3686274509803922,0.8196078431372549,0.5137254901960784,1,0.4823529411764706,0.38823529411764707&swatchOrder=0,1,2,3,4
    public static readonly COLOR_YELLOW:string ='ffc463';
    public static readonly COLOR_GREEN:string ='5ed183';
    public static readonly COLOR_RED:string ='ff7b63';
    public static readonly COLOR_BLUE:string ='508ec9';
    public static readonly COLOR_PURPLE:string ='cb79cb';
    public static readonly COLOR_GREY:string ='939393';
    
    public static readonly COLOR_TURQUUOISE:string ='50c2c9';
    public static readonly COLOR_CANARY_YELLOW:string ='FFE481';
    public static readonly COLOR_ORANGE:string ='FF9E58';
    public static readonly COLOR_RASPBERRY:string ='CB5477';
    public static readonly COLOR_MAGENTA:string ='7F6DC9';
    public static readonly COLOR_SOFT_BLUE:string ='50BDE8';
    public static readonly COLOR_EMERALD:string ='5AAB9C';
    public static readonly COLOR_SOFT_GREEN:string ='C1E8A9';
    public static readonly COLOR_LIGHT_GREY:string ='C7C7C7';
    public static readonly COLOR_DARK_GREY:string ='525252';

    public static readonly ADVANCED_COLOR_PICKER_DEFAULT_OPTIONS: string[] = ['#FFE481', '#FFC463', '#FF9E58', '#FF7B63', '#CB5477', '#CB79CB', '#7F6DC9', '#508EC9', '#50BDE8', '#50C2C9', '#5AAB9C', '#5ED183', '#C1E8A9', '#C7C7C7', '#939393', '#525252', '#FF1493', '#FFA500', '#FF0000', '#800080', '#0000FF', '#00FFFF', '#90E900', '#008000', '#BC8F8F', '#A52A2A']
    
    private static COLOR = {};
    //LAYOUT
    public static readonly LAYOUT_TYPE_LUMINIZER:string = 'layout-luminizer';
    public static readonly LAYOUT_TYPE_STEDIN:string = 'layout-stedin';
    public static LAYOUT_TYPE:string = "";
    public static MOBILE_BREAKPOINT_WIDTH:number = 768; // Used to calculate mobileMode on/off
    public static TABLET_BREAKPOINT_WIDTH:number = 992; // Used to calculate tabletMode on/off
    public static LAPTOP_BREAKPOINT_WIDTH:number = 1460; // Used to calculate laptopMode on/off
    
    //USABILITY SETTINGS - Application wide settings for application behaviour
    public static readonly AUTO_DESELECT_GRANDCHILDREN:boolean = true;
    public static readonly AUTO_DESELECT_GRANDPARENTS:boolean = true;
    public static readonly SHOW_ROOT_COMPONENT:boolean = true;
    public static readonly AUTO_EXPAND_ROOT_NODE:boolean = true;
    public static readonly AUTO_HIDE_EMPTY_TREE:boolean = true;
    public static readonly AUTO_EXPAND_FORM:boolean = true;
    public static readonly REAPPLY_SMART_SELECTION_ON_AREA_SWITCH:boolean = false; //When true, smart selections will be reapplied when entering the application, and switching areas. When false, smart selections will only be saved for the current session in the current area. (so switching between modules still keeps the smart selection applied)

    public static readonly DEFAULT_OBJECT_TYPES:string[] = ['schakelkast', 'mast', 'genericasset'];
    public static readonly DEFAULT_OBJECT_GRID_TYPES:string[] = ['schakelkast', 'mast', 'cable', 'wire', 'conduit', 'joint','annotation', 'genericasset'];

    //VERSION
    public static VERSION_ARRAY = [6,4,0];                                       //major,minor,(bugfix|hotfix)
    public static MANUAL_VERSION:string = "2.1";
    public static FLASH_CACHE_VERSION:string = "";
    
    //DEBUG SETTINGS. DEBUG ONLY, NEVER LIVE
    // NOTE: alle variabelen moeten zo zijn geschreven dat ze standaard op false staan
    //  De hele rij op false is OK voor productie
    public static readonly DISABLE_FLASH:boolean = false;                       //Disable the flash player, for fast local development
    public static readonly ENABLE_VISIBLE_CHANGE_DETECTION:boolean = false;     //Show black area's for bad performing components. Components need to be ChangeableComponents to make it work
    public static readonly ALWAYS_SHOW_FIRSTLOGIN_MESSAGE:boolean = false;      //Pretend like firstlogin is true
    public static readonly DISABLE_CLIENTSIDE_AUTHORISATION:boolean = false;    //Stop the client from checking URL's for access
    public static readonly ALLOW_ALL_AUTH_ACTIONS:boolean = false;              //Client is authorized for every auth action
    public static readonly ALWAYS_USE_IE11:boolean = false;                     //Pretend as if working with IE 11
    public static readonly ALWAYS_DISABLE_PHP_DEBUG:boolean = false;            //Skip the php debug, making the app run faster, but no stacktraces. Debug is always skipped on productie environment
    
    private static model:GlobalModel;
    private static translateService:TranslateService;
    
    public static setModel(model:GlobalModel){
        AppSettings.model = model;
    }
    
    public static setTranslateService(translateService:TranslateService){
        AppSettings.translateService = translateService;
    }
    
    public static getBaseUrl():string
    {
             return window.location.origin + '/';
    }
    
    public static getBaseAngularUrl(path:string):string
    {
        return AppSettings.getBaseUrl() + 'v2' + AppSettings.getLanguageURLByPath(path) + '/';
    }
    
    private static getLanguageURLByPath(path:string):string
    {
        path = path.replace("application-state/", "");
        
        //TODO: Zorg dat de loginpaden geen language meesturen. Kan vast mooier dan deze hack
        return (path != "login" && path != "check-login" && path != "get-token" && path != "logout"?"/" + AppSettings.translateService.getLanguage():"")
    }
    
    public static getVersionNumber():string
    {
        let result:string = "";
        
        if (AppSettings.VERSION_ARRAY) {
            for(let i = 0; i < AppSettings.VERSION_ARRAY.length; i++) {
                result += (AppSettings.VERSION_ARRAY[i] + (i < AppSettings.VERSION_ARRAY.length-1?".":""));
            }
        }
        
        return result;
    }
    
    static setColor(name: string, value: string): void {
        AppSettings.COLOR['$' + name] = value;
    }
    
    static getColor(name: string): string {
        return AppSettings.COLOR[name];
    }
    
}
