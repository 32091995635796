/**
 * Created by Christiaan on 10/05/2017.
 */
export class GlobalEvent {

    public static readonly EVENT_SCROLL_TO_SECTION: string = 'EVENT_SCROLL_TO_SECTION';
    public static readonly EVENT_EXPAND_FORM_GROUP: string = 'EVENT_EXPAND_FORM_GROUP';
    public static readonly EVENT_LOAD_FORM_REQUEST: string = 'EVENT_LOAD_FORM_REQUEST';
    public static readonly EVENT_TABLE_OPTIONS_SETS_CHANGED: string = 'EVENT_TABLE_OPTIONS_SETS_CHANGED';
    public static readonly EVENT_MAP_SELECT_MAP_TYPE: string = 'EVENT_MAP_SELECT_MAP_TYPE';
    public static readonly EVENT_MAP_TOGGLE_POI: string = 'EVENT_MAP_TOGGLE_POI';
    public static readonly EVENT_MAP_TOGGLE_MAP_POPUP: string = 'EVENT_MAP_TOGGLE_MAP_POPUP';
    public static readonly EVENT_MAP_TOGGLE_WMS_LAYER: string = 'EVENT_MAP_TOGGLE_WMS_LAYER';
    public static readonly EVENT_MAP_TOGGLE_SINGLE_WMS_LAYER: string = 'EVENT_MAP_TOGGLE_SINGLE_WMS_LAYER';
    public static readonly EVENT_MAP_TOGGLE_MARKER_LABELS: string = 'EVENT_MAP_TOGGLE_MARKER_LABELS';
    public static readonly EVENT_MAP_TOGGLE_MAP_CLUSTERS: string = 'EVENT_MAP_TOGGLE_MAP_CLUSTERS';
    public static readonly EVENT_MAP_HEATMAP_OPTION_CODE: string = 'EVENT_MAP_HEATMAP_OPTION_CODE';
    public static readonly EVENT_REMOVE_BASEOBJECT: string = 'EVENT_REMOVE_BASEOBJECT';
    public static readonly EVENT_REMOVE_BASEOBJECT_SUCCESS: string = 'EVENT_REMOVE_BASEOBJECT_SUCCESS';
    public static readonly EVENT_REMOVE_BASEOBJECT_BATCH_SUCCESS: string = 'EVENT_REMOVE_BASEOBJECT_BATCH_SUCCESS';
    public static readonly EVENT_REMOVE_UNDERGROUND_BASEOBJECT: string = 'EVENT_REMOVE_UNDERGROUND_BASEOBJECT';
    public static readonly EVENT_REMOVE_UNDERGROUND_BASEOBJECT_SUCCESS: string = 'EVENT_REMOVE_UNDERGROUND_BASEOBJECT_SUCCESS';
    public static readonly EVENT_MOVE_MAPITEM_FROM_MAP_SUCCESS: string = 'EVENT_MOVE_MAPITEM_FROM_MAP_SUCCESS';
    public static readonly EVENT_MODULE_REFRESH: string = 'EVENT_MODULE_REFRESH';
    public static readonly EVENT_MODULE_START_AUTO_REFRESH: string = 'EVENT_MODULE_START_AUTO_REFRESH';
    public static readonly EVENT_MODULE_STOP_AUTO_REFRESH: string = 'EVENT_MODULE_STOP_AUTO_REFRESH';
    public static readonly EVENT_PRE_READ_FORM: string = 'EVENT_PRE_READ_FORM';
    public static readonly EVENT_OUT_OF_SESSION: string = 'EVENT_OUT_OF_SESSION';
    public static readonly EVENT_TIME_REGISTRATION_UPDATE: string = 'EVENT_TIME_REGISTRATION_UPDATE';
    public static readonly EVENT_GPS_POSITION_UPDATE: string = 'EVENT_GPS_POSITION_UPDATE';
    public static readonly EVENT_TABLE_SORT: string = 'EVENT_TABLE_SORT';
    public static readonly EVENT_REFRESH_FORM: string = 'EVENT_REFRESH_FORM';
    public static readonly EVENT_BUSH_NAVIGATE_TO_MAIN: string = 'EVENT_BUSH_NAVIGATE_TO_MAIN';
    public static readonly EVENT_ADD_MAP_ITEM_TO_TABLE: string = 'EVENT_ADD_MAP_ITEM_TO_TABLE';
    public static readonly EVENT_FORM_MARKS_CLICK: string = 'EVENT_FORM_MARKS_CLICK';
    public static readonly EVENT_INPUT_TRIGGER_MODEL: string = 'EVENT_INPUT_TRIGGER_MODEL';
    public static readonly EVENT_LOAD_FORM_FROM_TAB: string = 'EVENT_LOAD_FORM_FROM_TAB';
    public static readonly EVENT_MAP_CATEGORIZE_BY_COLOR_ICONS: string = 'EVENT_MAP_CATEGORIZE_BY_COLOR_ICONS';
    public type: string;
    public data: any = {};

    constructor(type: string, data: any) {
        this.type = type;
        this.data = data;
    }
}
