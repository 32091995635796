/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable, NgZone} from '@angular/core';

@Injectable()
export class StorageService {
    //Only set/get via keys for save communication with the storage
    public static readonly KEY_MAP_WMS_ENABLED: string = 'KEY_MAP_WMS_ENABLED';
    public static readonly KEY_MAP_SINGLE_WMS_ENABLED: string = 'KEY_MAP_SINGLE_WMS_ENABLED';
    public static readonly KEY_MAP_POI_ENABLED: string = 'KEY_MAP_POI_ENABLED';
    public static readonly KEY_MAP_POPUP_ENABLED: string = 'KEY_MAP_POPUP_ENABLED';
    public static readonly KEY_MAP_MARKER_LABELS_ENABLED: string = 'KEY_MAP_MARKER_LABELS_ENABLED';
    public static readonly KEY_MAP_MAP_TYPE: string = 'KEY_MAP_MAP_TYPE';
    public static readonly KEY_SELECTED_MAP_TABLE_VIEW: string = 'KEY_SELECTED_MAP_TABLE_VIEW';
    public static readonly KEY_MAP_CLUSTERER: string = 'KEY_MAP_CLUSTERER';
    public static readonly KEY_SELECTED_LANGUAGE: string = 'KEY_SELECTED_LANGUAGE';
    public static readonly KEY_LAST_USED_DASHBOARD: string = 'KEY_LAST_USED_DASHBOARD';
    public static readonly KEY_SELECTED_TREE: string = 'KEY_SELECTED_TREE';
    public static readonly KEY_SELECTED_MODULE_TREE: string = 'KEY_SELECTED_MODULE_TREE';
    public static readonly KEY_WIDGET_PARAMS: string = 'KEY_WIDGET_PARAMS';
    public static readonly KEY_MODULE_AUTOREFRESH: string = 'KEY_MODULE_AUTOREFRESH';
    public static readonly KEY_IDLE_RETURN_URL: string = 'KEY_IDLE_RETURN_URL';
    public static readonly KEY_USER_MAP_LAYERS: string = 'KEY_USER_MAP_LAYERS';
    public static readonly KEY_SELECTED_MECHANIC: string = 'KEY_SELECTED_MECHANIC';
    public static readonly KEY_MECHANIC_FILTER: string = 'KEY_MECHANIC_FILTER';
    public static readonly KEY_GLOBAL_SEARCH_CATEGORY: string = 'KEY_GLOBAL_SEARCH_CATEGORY';
    public static readonly KEY_HISTORY_LIST: string = 'KEY_HISTORY_LIST';
    public static readonly KEY_ACTIVE_DEVICE_TAB: string = 'KEY_ACTIVE_DEVICE_TAB';
    public static readonly KEY_DEBUG_MODE: string = 'KEY_DEBUG_MODE';
    public static readonly KEY_TRANSLATION_MODE: string = 'KEY_TRANSLATION_MODE';
    public static readonly KEY_PROFILER_MODE: string = 'KEY_PROFILER_MODE';
    public static readonly KEY_PROFILE_POSITION: string = 'KEY_PROFILE_POSITION';
    public static readonly KEY_PROFILER_OPEN: string = 'KEY_PROFILER_OPEN';


    private readonly localStorageAvailable: boolean = false;

    constructor(private zone: NgZone) {
        if (typeof (Storage) !== 'undefined') {
            this.localStorageAvailable = true;
        }
        else {
            this.localStorageAvailable = false;
        }
    }

    public keyExists(key: string): boolean {
        if (this.localStorageAvailable) {
            if (localStorage.getItem(key)) {
                return true;
            }
        }

        return false;
    }

    public setValue(key: string, value: any) {
        this.zone.runOutsideAngular(() => {
            if (this.localStorageAvailable) {
                localStorage.setItem(key, value);
            }
        });
    }

    public setObjectValue(key: string, value: any) {
        this.zone.runOutsideAngular(() => {
            if (this.localStorageAvailable) {
                localStorage.setItem(key, JSON.stringify(value));
            }
        });
    }

    //Specific function for getting a boolean, so casting ect. will only be performed here
    public getBooleanValue(key: string, successCallBack: (value: boolean) => any) {
        this.zone.runOutsideAngular(() => {
            if (this.localStorageAvailable) {
                let value: string = localStorage.getItem(key);

                //If the value is not found, it should not influence the settings, so don't do successCallBack
                if (value) {
                    successCallBack(value == 'true');
                }
            }
        });
    }

    //Specific function for getting a string, so casting ect. will only be performed here
    public getStringValue(key: string, successCallBack: (value: string) => any, noKeyCallBack?: () => any) {
        this.zone.runOutsideAngular(() => {

            if (!this.keyExists(key)) {
                if (noKeyCallBack) {
                    noKeyCallBack();
                }
                return;
            }

            if (this.localStorageAvailable) {
                let value: string = localStorage.getItem(key);

                //If the value is not found, it should not influence the settings, so don't do successCallBack
                if (value) {
                    successCallBack(value);
                }
            }
        });
    }

    //Specific function for getting a number, so casting ect. will only be performed here
    public getNumberValue(key: string, successCallBack: (value: number) => any) {
        this.zone.runOutsideAngular(() => {
            if (this.localStorageAvailable) {
                let value: number = Number(localStorage.getItem(key));

                //If the value is not found, it should not influence the settings, so don't do successCallBack
                if (value) {
                    successCallBack(value);
                }
            }
        });
    }

    public getObjectValue(key: string, successCallBack: (value: object) => any) {
        this.zone.runOutsideAngular(() => {
            if (this.localStorageAvailable) {
                let value: any = localStorage.getItem(key);

                //If the value is not found, it should not influence the settings, so don't do successCallBack
                if (value) {
                    try {
                        JSON.parse(value);
                        successCallBack(JSON.parse(value));
                    } catch (err) {
                        //err
                    }
                }
            }
        });
    }

    public removeItem(key: string): void {
        if (this.localStorageAvailable) {
            localStorage.removeItem(key);
        }
    }
}
