import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TranslateService} from "../../../../services/translate/translate.service";
import * as $ from 'jquery';
import {FormEvent, LumiForm} from './form.interface';
import {FormComponent} from './form.component';
import {LoggerService} from "../../../../services/logger/logger.service";
import {HTTPService} from "../../../../services/http/http.service";
import {GlobalEvent} from "../../../../interfaces/global-event";

@Component({
    selector: 'abstract-base-form',
    template: ''
})
export abstract class AbstractBaseFormComponent
{
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() invalidControlsErrors:any;
    @ViewChild('baseFormGroup', {static: false}) baseFormGroupComponent: FormComponent;

    config:any;
    public _formData:any;
    validationConstraints:any = null;
    formIsSubmitted: boolean = false;

    protected constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected elementRef:ElementRef, protected ts:TranslateService, protected logger:LoggerService) {}

    protected handleValidationConstraints(response:any):void
    {
        //this.logger.log('[AbstractBaseFormComponent] handleValidationConstraints');
        if (response && response.errors && response.errors.children){
            //this.logger.log('[AbstractBaseFormComponent] handleValidationConstraints -> response and errors and errors children');
            this.validationConstraints = response.errors.children;
        }else{
            //this.logger.log("[AbstractBaseFormComponent] " + "No children in formerrors > invalid data. No errors applied.");
            this.validationConstraints =  null;
        }

        // this variable is used for, if a user types after validation constraints were fired, the popups will disappear
        //TODO: waarom wordt dit nu pas op true gezet? (of nu weer)
        this.formIsSubmitted = true;
    }

    public hasFailedConstraints():boolean
    {
        return this.validationConstraints != null;
    }

    /**
     * Listen only for changes to form data (e.g.: retrieved from observable)
     * @param data
     */
    @Input()
    set formData(data: LumiForm)
    {
        //Store old scrollposition and name before changing data
        let oldFormName:string = this.getFormName();
        let oldScrollPosition:number = 0;


        if (this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.parentElement){
            oldScrollPosition = this.elementRef.nativeElement.parentElement.scrollTop;
        }


        //Clear possible validation errors of a previous form. Should get called before errors are passed to the form
        this.handleValidationConstraints(null);

        this._formData = data;

        //this.parseFormConfiguration(data);
        if (data){
            this.config = data.schema;
        }

        this.addGlobalAlert(data);
       // this.config = this._configuration;



        //Search for an active section and try to reapply it to the new form
        let activeSection:any = $('.module-section-item.active');
        if (activeSection) {

            let sectionHash:string = activeSection.attr('href');
            this.logger.log("[AbstractBaseFormComponent] " + "active section: ", sectionHash);

            if (sectionHash) {
                setTimeout(() => {
                    // Scroll to the same section, directly (no smooth) and dont expand the section from here (cookies will be used later)
                    this.onComponentEvent.emit({event: FormEvent.SCROLL_TO_SECTION, data: {sectionHash: sectionHash, scrollSmooth:false, autoExpandSection:false}});
                }, 100);
            }else{
                //The section has no valid hash
                this.logger.log("[AbstractBaseFormComponent] " + "invalid hash for: ", activeSection);
            }

        }else {
            //There is no active section, use hard position instead

            //Apply old scroll position if form names are the same
            if (oldFormName != "" && oldFormName == this.getFormName()) {

                //Formdata is applied twice sometimes, so prevent it from setting to 0 in those cases
                if (oldScrollPosition > 0) {
                    this.scrollFormToPosition(oldScrollPosition);
                }
            } else {
                //Formdata is applied twice sometimes, so prevent it from setting to larger than 0 in those cases
                this.scrollFormToPosition(0);
            }
        }
    }

    //Scroll the form to the scroll position
    private scrollFormToPosition(scrollPosition:number):void
    {
        setTimeout( () => {
            this.logger.log("[AbstractBaseFormComponent] " + "scroll form to: " + scrollPosition);
            if (this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.parentElement) {
                this.elementRef.nativeElement.parentElement.scrollTop = scrollPosition;
            }
        })
    }

    get formData():any
    {
        return this._formData;
    }

    getFormName():string
    {
        if (this.config) {
            return this.config.name;
        }

        return "";
    }

    //Store readonly state in config, so that if two forms share formdata, form A becomes readonly when form B is submitted.
    public get readOnly():boolean{
        return this.config?this.config.clientReadOnly:true;
    }

    public set readOnly(readOnly:boolean){
        if (this.config) {
            this.config.clientReadOnly = readOnly;
        }
    }

    protected handleSubmitForm():void
    {
        this.formIsSubmitted = true;
        this.readOnly = true;
    }

    protected handleSubmitResponse(formErrors:any = null, isHTTPError:boolean = false):void
    {
        // TODO: Bij een http-error is er iets niet goed gegaan op de server. Als we daar ooit aparte afhandeling voor willen kan die hier geplaatst worden
        // Denk bv aan het terugbrengen van het formulier naar de oude staat (van voor het opsturen)

        this.logger.log('[AbstractBaseFormComponent] handleSubmitResponse, formErrors: ', formErrors);
        this.handleValidationConstraints(formErrors);
        this.showGlobalFormErrors(formErrors);
        this.readOnly = false;
        this.cd.detectChanges();
    }

    private showGlobalFormErrors(data: any): void
    {
        // Display errors that are not linked to a specific field, but are global
        if (data && data.errors && data.errors.errors && data.errors.errors.length > 0){

            let errorMessages: string = '';

            for (let i = 0; i < data.errors.errors.length; i++) {
                errorMessages += data.errors.errors[i].message + ((i + 1) < data.errors.errors.length ? '<br>' : '');
            }

            this.globalAlertService.addPopup(this.ts.translate('Waarschuwing'), errorMessages + '<br><i>' +
                this.ts.translate('Het formulier is niet geüpdatet, probeer het opnieuw.' ) + '</i>',
                [{label: this.ts.translate('Ok'), code: 'OK', isPrimary: true}], () => {});
        }
    }

    //Add potential global alert popup when new formdata is received
    private addGlobalAlert(data: any):void
    {
        if (data && data.globalAlert && (!data.globalAlert.hasOwnProperty('didDisplay') || data.globalAlert.didDisplay == false)) {
            let alert:any = data.globalAlert;
            alert.didDisplay = true;
            if (alert.title && alert.subTitle && alert.label && alert.icon && alert.type) {
                this.globalAlertService.addAlert(alert.title, alert.subTitle, alert.label, alert.icon, alert.type);
            }
        }
    }
}
