import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {DashboardWrapperComponent} from "./dashboard-wrapper.component";

const routes: Routes = [{path: '', pathMatch: 'full', redirectTo: 'view'},
    {path: 'view', pathMatch: 'full', component: DashboardWrapperComponent},
    {path: 'view/:dashboard', component: DashboardWrapperComponent},
    {path: ':dashboardName', pathMatch: 'full', component: DashboardWrapperComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class DashboardRoutingModule {}
