/**
 * Created by Jasper on 10/03/2021.
 */

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
}) export class PrintService {

    constructor() {}

    public printMap() {
        const body = $('body');
        const mapContainer = $('.map-component-container');
        const mapContainerParent = mapContainer.parent();
        const printContainer = $('<div style="position:relative;width:100%;max-width:1920px;">'); //Give max width to prevent smaller tekst in pdf

        printContainer
            .height(mapContainer.height())
            .append(mapContainer)
            .prependTo(body);

        const content = body
            .children()
            .not(printContainer)
            .not('script')
            .detach();

        /**
         * Needed for those who use Bootstrap 3.x, because some of
         * its `@media print` styles ain't play nicely when printing.
         */
        const patchedStyle = $('<style media="print">')
            .text(`
              img { max-width: none !important; }
              a[href]:after { content: ""; }
            `)
            .appendTo('head');

        window.focus();
        window.print();

        body.prepend(content);
        mapContainerParent.prepend(mapContainer);

        printContainer.remove();
        patchedStyle.remove();
        return false;
    }


    public printManual(htmlToPrint:string) {
        const downloadButtonElement = document.getElementById('print-btn');
        const printElement = document.createElement('div');
        printElement.id = 'manual-print-container';
        printElement.innerHTML = htmlToPrint;
        document.body.style.background = '#FFFFFF'
        downloadButtonElement.style.display = 'none';

        document.body.appendChild(printElement);

        setTimeout(()=>{
            window.print();
            window.onfocus = function(){
                document.body.style.background = '#f5f5f5';
                downloadButtonElement.style.display = 'block';
                document.body.removeChild(printElement);
            }
        })
    }
}
