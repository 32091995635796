import {
    ITreeTableFormState,
    ITreeTableFormNewState,
    ITreeMapFormState,
    ITreeMapFormNewState,
    INetworkMonitor
} from "./global-state.interface";
import {BehaviorSubject} from "rxjs";
import {GlobalModel} from './global.model';
import {Injectable} from "@angular/core";
import {LoggerService} from "../logger/logger.service";

@Injectable()
export class GlobalStateService {
    private treeTableFormState$ = new BehaviorSubject<any>([]);
    private treeMapFormState$ = new BehaviorSubject<any>([]);
    private networkMonitorState$ = new BehaviorSubject<any>([]);
    public treeTableFormState: ITreeTableFormState = this.treeTableFormState$.getValue() || {};
    public treeMapFormState: ITreeMapFormState = this.treeMapFormState$.getValue() || {};
    public networkMonitorState: INetworkMonitor[] = this.networkMonitorState$.getValue() || [];

    constructor(private model: GlobalModel, protected logger:LoggerService) {}

    useState(currentTreeCode: string):boolean{
        let useState = true;

        if(currentTreeCode == "USER_MANAGEMENT" || currentTreeCode == "KABEL_BONNEN"){
            useState = false;
        }
        return useState;
    }

    getSetNetworkTrafficState(action: 'get'|'set', newMonitorLine?: INetworkMonitor): INetworkMonitor[]{
        if(action === "get"){
            return this.networkMonitorState$.value
        } else if(action === "set" && newMonitorLine){
            this.networkMonitorState.unshift({
                method: newMonitorLine.method,
                callUrl: newMonitorLine.callUrl,
                profilerUrl: newMonitorLine.profilerUrl,
                datetime: new Date()
            });
            this.networkMonitorState$.next(this.networkMonitorState);
        }
    }

    clearNetworkTrafficState():void{
        this.networkMonitorState = [];
        this.networkMonitorState$.next(this.networkMonitorState);
    }

    getSetTreeFormState(action: 'get'|'set', currentTreeCode: string, newTableStateItem: ITreeTableFormNewState | null){
        if(this.useState(currentTreeCode)){
            // Save empty array if not exist
            if(!this.treeTableFormState.tableData){
                this.treeTableFormState.tableData = [{
                    treeCode: null,
                    sorting: null,
                    tableItems: null
                }];
                this.treeTableFormState$.next(this.treeTableFormState);
            }
            if(action === "get" && currentTreeCode){ //Get
                this.logger.log('[GlobalStateService] - treeTableFormState$ - action: '+action+' - code: '+currentTreeCode, this.treeTableFormState.tableData);
                return this.treeTableFormState.tableData.find(_x =>
                    _x.treeCode === currentTreeCode &&
                    _x.tableItems
                );
            } else if(action === "set" && currentTreeCode && newTableStateItem) { //Set
                let tableDataIndex = this.treeTableFormState.tableData.findIndex(_x =>
                    _x.treeCode === currentTreeCode
                );
                newTableStateItem.treeCode = currentTreeCode;

                if (tableDataIndex > -1){ //Update
                    if(this.treeTableFormState.tableData[tableDataIndex] != newTableStateItem){
                        this.treeTableFormState.tableData[tableDataIndex] = newTableStateItem;
                        this.logger.log('[GlobalStateService] - treeTableFormState$ - action: '+action+' (update)', newTableStateItem);
                    }
                } else { //Add
                    this.treeTableFormState.tableData.push(newTableStateItem);
                    this.logger.log('[GlobalStateService] - treeTableFormState$ - action: '+action+' (add)', newTableStateItem);
                }
                // Submit
                this.treeTableFormState$.next(this.treeTableFormState);
            } else {
                return null;
            }
        }

    }

    getSetMapFormState(action: 'get'|'set', currentTreeCode: string, newMapStateItem: ITreeMapFormNewState | null){
        if(this.useState(currentTreeCode)) {
            // Save empty array if not exist
            if (!this.treeMapFormState.mapData) {
                this.treeMapFormState.mapData = [{
                    treeCode: null,
                    mapItems: null,
                    zoomOnMarkers: null
                }];
                this.treeMapFormState$.next(this.treeMapFormState);
            }
            if (action === "get" && currentTreeCode) { //Get
                this.logger.log('[GlobalStateService] - treeMapFormState$ - action: ' + action + ' - code: ' + currentTreeCode, this.treeMapFormState.mapData);
                return this.treeMapFormState.mapData.find(_x =>
                    _x.treeCode === currentTreeCode &&
                    _x.mapItems
                );
            } else if (action === "set" && currentTreeCode && newMapStateItem) { //Set
                let mapDataIndex = this.treeMapFormState.mapData.findIndex(_x =>
                    _x.treeCode === currentTreeCode
                );
                newMapStateItem.treeCode = currentTreeCode;

                if (mapDataIndex > -1) { //Update
                    if (this.treeMapFormState.mapData[mapDataIndex] != newMapStateItem) {
                        this.treeMapFormState.mapData[mapDataIndex] = newMapStateItem;
                        this.logger.log('[GlobalStateService] - treeMapFormState$ - action: ' + action + ' (update)', newMapStateItem);
                    }
                } else { //Add
                    this.treeMapFormState.mapData.push(newMapStateItem);
                    this.logger.log('[GlobalStateService] - treeMapFormState$ - action: ' + action + ' (add)', newMapStateItem);
                }
                // Submit
                this.treeMapFormState$.next(this.treeMapFormState);
            } else {
                return null;
            }
        }
    }

    clearSpecificFormState(currentTreeCode: string):void{
        //Tableitems
        let tableDataIndex = this.treeTableFormState.tableData.findIndex(_x =>
            _x.treeCode === currentTreeCode
        );
        if (tableDataIndex > -1) {
            const cleanTableStateItem = {
                treeCode: currentTreeCode,
                tableItems: null,
                sorting: null,
            };
            this.treeTableFormState.tableData[tableDataIndex] = cleanTableStateItem;
            this.treeTableFormState$.next(this.treeTableFormState);
        }
        //Mapitems
        let mapDataIndex = this.treeMapFormState.mapData.findIndex(_x =>
            _x.treeCode === currentTreeCode
        );
        if (mapDataIndex > -1) {
            const cleanMapStateItem = {
                treeCode: currentTreeCode,
                mapItems: null,
                zoomOnMarkers:null
            };
            this.treeMapFormState.mapData[mapDataIndex] = cleanMapStateItem;
            this.treeMapFormState$.next(this.treeMapFormState);
        }
        this.logger.log('[GlobalStateService] - clearSpecificFormState() - currentTreeCode: '+currentTreeCode);
    }

    clearEntireFormState():void{
        this.treeTableFormState.tableData = [{treeCode: null,sorting: null,tableItems: null}];
        this.treeTableFormState$.next(['']);
        this.treeMapFormState.mapData = [{treeCode: null,mapItems: null,zoomOnMarkers:null}];
        this.treeMapFormState$.next(['']);

        this.logger.log('[GlobalStateService] - clearEntireFormState()', this.treeTableFormState);
    }
}
