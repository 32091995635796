import {Component, Input, OnInit} from '@angular/core';
import {ITabs} from "./form-tabs.interface";
import {GlobalEvent} from "../../../../interfaces/global-event";
import {GlobalModel} from "../../../../services/state/global.model";

@Component({
    selector: 'form-tabs',
    templateUrl: './form-tabs.component.html',
    styleUrls: ['./form-tabs.component.scss']
})

export class FormTabsComponent implements OnInit {
    @Input('config') config: any;
    public availableTabs:ITabs[] = [];
    public combinedWithDropdownButtons:boolean = false;

    constructor(private model:GlobalModel) {}

    ngOnInit(): void {
        if(this.config && this.config.attr && this.config.attr.tabs){
            this.availableTabs = this.config.attr.tabs;
            if(this.hasDropdownButtons()){
                this.combinedWithDropdownButtons = true;
            }
        }
    }

    private hasDropdownButtons():boolean{
        return !!document.getElementById('formDropdown');
    }

    public openTab(tab:ITabs):void{
        if(!tab.active){
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_LOAD_FORM_FROM_TAB, {baseObjectId:tab.baseObjectId, previousBaseObjectId:tab.previousBaseObjectId, tabIndex:tab.tabIndex}));
        }
    }
}
