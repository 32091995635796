import {ChangeDetectorRef, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormComponent} from './containers/form/form.component';
import {FormButtonComponent} from './components/form-button/form-button.component';
import {FormInputComponent} from './components/form-input/form-input.component';
import {FormSelectComponent} from './components/form-select/form-select.component';
import {FieldDirective} from './components/field/field.directive';
import {FormFileComponent} from './components/form-file/form-file.component';
import {FormGroupComponent} from './components/form-group/form-group.component';
import {FormPasswordInputComponent} from './components/form-input/form-password-input.component';
import {FormDateTimeInputComponent} from './components/form-input/form-date-time-input.component';
import {FormTimeInputComponent} from './components/form-input/form-time-input.component';
import {FormDateInputComponent} from './components/form-input/form-date-input.component';
import {FormDataService} from './services/form-data.service';
import {FormGraphComponent} from './components/form-widgets/form-graph.component';
import {FormSectionComponent} from './components/section/form-section.component';
import {ScrollSpyService} from './services/scroll-spy.service';
import {CommonUIModule} from '../commonUI/common-ui.module';
import {FormTableComponent} from './components/form-widgets/form-table.component';
import {FormIntegerInputComponent} from './components/form-input/form-integer-input-component';
import {CommonDirectivesModule} from '../../directives/common/common-directives.module';
import {FormCheckBoxComponent} from './components/form-checkbox/form-checkbox.component';
import {FormCheckBoxBulkComponent} from './components/form-checkbox/bulk/form-checkbox-bulk.component';
import {FormCheckBoxDefaultComponent} from './components/form-checkbox/default/form-checkbox-default.component';
import {CheckBoxControlValueOverrideAccessor} from './components/form-checkbox/checkbox-control-value-override-accessor';
import {FormRangeInputComponent} from './components/form-input/form-range-input-component';
import {FormAnchorComponent} from './components/form-anchor/form-anchor.component';
import {FormBushItemTreeComponent} from './components/form-bush-item-tree/form-bush-item-tree.component';
import {FormBushItemTreeNodeComponent} from './components/form-bush-item-tree/form-bush-item-tree-node.component';
import {FormLocationComponent} from './components/form-location/form-location';
import {MapModule} from '../map/map.module';
import {ValidationWarningComponent} from './components/field/validation-warning.component';
import {FormItemCountComponent} from './components/form-item-count/form-item-count';
import {DefaultFormComponent} from './components/default-form-component';
import {DefaultBaseFormComponent} from './components/default-base-form-component';
import {FormSpacerComponent} from './components/field/form-spacer.component';
import {AuthFormTableComponent} from './components/form-table/auth-form-table.component';
import {AuthActionTableComponent} from './components/form-table/auth-action-table.component';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {AuthFieldTableComponent} from './components/form-table/auth-field-table.component';
import {SectionFormComponent} from './components/section-form.component';
import {AssetsFormComponent} from '../../../modules/assets/assets-form.component';
import {DeviceManagementFormComponent} from '../../../modules/deviceManagement/device-management-form.component';
import {ControlFormComponent} from '../../../modules/control/control-form.component';
import {FormMultiSelectComponent} from './components/form-select/form-multi-select/form-multi-select.component';
import {FormColorPickerComponent} from './components/form-select/form-color-picker/form-color-picker.component';
import {FormAdvancedColorPickerComponent} from './components/form-select/form-advanced-color-picker/form-advanced-color-picker.component'
import {DimmingExceptionsComponent} from './components/dimming/dimming-exceptions.component';
import {TinyGraphComponent} from './components/form-graph/tiny-graph.component';
import {DimmingSchemeSelectComponent} from './components/dimming/dimming-scheme-select.component';
import {FormButtonInlineComponent} from './components/form-button/form-button-inline.component';
import {ReportMalfunctionFormComponent} from '../../../modules/reportMalfunction/report-malfunction-form.component';
import {TableFilterComponent} from './components/form-table/table-filter.component';
import {WorkOrdersFormComponent} from '../../../modules/workOrders/work-orders-form.component';
import {FormLogComponent} from './components/form-log/form-log.component';
import {FormCommentComponent} from './components/form-comment/form-comment.component';
import {FormTextAreaComponent} from './components/form-input/form-text-area.component';
import {FormMapComponent} from './components/form-widgets/form-map.component';
import {FormAttachmentComponent} from './components/form-attachment/form-attachment.component';
import {FormWorkPreparationComponent} from './components/work-preparation/form-work-preparation.component';
import {AbstractFormFieldComponent} from './components/abstract/abstract-form-field.component';
import {FormWorkActivityComponent} from './components/work-activity/form-work-activity.component';
import {FormWorkSpecificationComponent} from './components/work-specification/form-work-specification.component';
import {FormWeekprijsComponent} from './components/weekprijs/form-weekprijs.component';
import {FormLinkComponent} from './components/form-link/form-link.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {PublicPortalFormComponent} from '../../../modules/publicPortal/public-portal-form.component';
import {ProjectsCreateFormComponent} from '../../../modules/projectsCreate/projects-create-form.component';
import {ProjectsManageFormComponent} from '../../../modules/projectsManage/form/projects-manage-form.component';
import {FormMultiInputComponent} from './components/form-input/form-multi-input.component';
import {AbstractFormWidget} from './components/form-widgets/abstract-form-widget';
import {ReportGenericFormComponent} from '../../../modules/reportGeneric/report-generic-form.component';
import {StedinOrdersFormComponent} from '../../../modules/stedinOrders/stedin-orders-form.component';
import {FormTimeTrackingComponent} from './components/form-time-tracking/form-time-tracking.component';
import {UserManagementFormComponent} from '../../../modules/userManagement/user-management-form.component';
import {MasterDataFormComponent} from '../../../modules/masterData/master-data-form.component';
import {BaseTableModule} from '../table/shared/baseTable/baseTable.module';
import {CheckActivitiesModule} from '../check-activities/check-activities.module';
import { FormDummyComponent } from './components/form-dummy/form-dummy.component';
import {StatusModule} from '../status/status.module';
import {FileModule} from '../file/file.module';
import {FormFileManagerComponent} from './components/form-file-manager/form-file-manager.component';
import { FormButtonGroupComponent } from './components/form-button-group/form-button-group.component';
import { FormMapIconPickerComponent } from './components/form-select/form-map-icon-picker/form-map-icon-picker.component';
import {MapIconSelectorModule} from '../map-icon-selector/map-icon-selector.module';
import {TranslateService} from '../../services/translate/translate.service';
import {FormMapServerComponent} from './components/form-map-server/form-map-server.component';
import {FormGroupWithStatusComponent} from './components/form-group/form-group-with-status/form-group-with-status.component';
import { FormTimelineComponent } from './components/form-timeline/form-timeline.component';
import {DeviceTimelineComponent} from './components/form-timeline/device-timeline/device-timeline.component';
import {TimelineComponent} from './components/form-timeline/timeline/timeline.component';
import {OwlDateTimeModule,OwlNativeDateTimeModule,OwlDateTimeIntl,OWL_DATE_TIME_LOCALE} from "ng-pick-datetime-ex";
import {OwlDateTimeLocalization} from '../../components/commonUI/input/date-input/date-input.translations';
import {FormImageComponent} from "./components/form-image/form-image.component";
import {NgxColorsModule} from 'ngx-colors';
import {DeviceMalfunctionFormComponent} from "../../../modules/deviceMalfunction/device-malfunction-form.component";
import {EditorComponent} from '@tinymce/tinymce-angular';
import {FormDropdownComponent} from "./components/form-dropdown/form-dropdown.component";
import {FormAttachmentSimpleComponent} from "./components/form-attachment-simple/form-attachment-simple.component";



@NgModule({
    imports: [
        CommonModule,
        CommonUIModule,
        CommonDirectivesModule,
        ReactiveFormsModule,
        MapModule,
        CommonPipesModule,
        FormsModule,
        ZXingScannerModule,
        BaseTableModule,
        CheckActivitiesModule,
        StatusModule,
        FileModule,
        MapIconSelectorModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxColorsModule,
        EditorComponent
    ],
    providers: [
        FormDataService,
        ScrollSpyService,
        {
            provide: LOCALE_ID,
            useValue: TranslateService.LOCALE
        },
        {
            provide: OWL_DATE_TIME_LOCALE, useValue: 'nl'
        },
        {
            provide: OwlDateTimeIntl, useClass: OwlDateTimeLocalization
        }
    ],
    declarations: [
        AbstractFormFieldComponent,
        AbstractFormWidget,
        FormInputComponent,
        FormMultiInputComponent,
        FormTextAreaComponent,
        FormIntegerInputComponent,
        FormSelectComponent,
        FormMultiSelectComponent,
        CheckBoxControlValueOverrideAccessor,
        FormCheckBoxComponent,
        FormCheckBoxBulkComponent,
        FormCheckBoxDefaultComponent,
        FormRangeInputComponent,
        FormButtonComponent,
        FormDropdownComponent,
        FormFileComponent,
        FieldDirective,
        FormComponent,
        FormGroupComponent,
        FormGroupWithStatusComponent,
        FormPasswordInputComponent,
        FormDateTimeInputComponent,
        FormTimeInputComponent,
        FormDateInputComponent,
        FormGraphComponent,
        TinyGraphComponent,
        FormTableComponent,
        FormSectionComponent,
        FormAnchorComponent,
        FormImageComponent,
        FormBushItemTreeComponent,
        FormBushItemTreeNodeComponent,
        FormLocationComponent,
        FormItemCountComponent,
        DefaultFormComponent,
        DefaultBaseFormComponent,
        SectionFormComponent,
        ValidationWarningComponent,
        FormSpacerComponent,
        AuthFormTableComponent,
        AuthActionTableComponent,
        AuthFieldTableComponent,
        AssetsFormComponent,
        UserManagementFormComponent,
        DeviceManagementFormComponent,
        ControlFormComponent,
        ReportMalfunctionFormComponent,
        DeviceMalfunctionFormComponent,
        ReportGenericFormComponent,
        PublicPortalFormComponent,
        WorkOrdersFormComponent,
        StedinOrdersFormComponent,
        ProjectsCreateFormComponent,
        ProjectsManageFormComponent,
        FormColorPickerComponent,
        FormAdvancedColorPickerComponent,
        DimmingExceptionsComponent,
        DimmingSchemeSelectComponent,
        FormButtonInlineComponent,
        TableFilterComponent,
        FormLogComponent,
        FormAttachmentComponent,
        FormAttachmentSimpleComponent,
        FormCommentComponent,
        FormMapComponent,
        FormWorkPreparationComponent,
        FormWorkActivityComponent,
        FormWorkSpecificationComponent,
        FormWeekprijsComponent,
        FormLinkComponent,
        FormTimeTrackingComponent,
        MasterDataFormComponent,
        FormDummyComponent,
        FormFileManagerComponent,
        FormButtonGroupComponent,
        FormMapIconPickerComponent,
        FormMapServerComponent,
        FormTimelineComponent,
        TimelineComponent,
        DeviceTimelineComponent
    ],
    exports: [
        FormComponent,
        FormSectionComponent,
        DefaultFormComponent,
        DefaultBaseFormComponent,
        ValidationWarningComponent,
        FormSpacerComponent,
        SectionFormComponent,
        TinyGraphComponent,
        FormDateTimeInputComponent,
        FormTimeInputComponent,
        FormDateInputComponent,
        FormMultiSelectComponent,
        FormSelectComponent,
        TableFilterComponent,
        FormCheckBoxComponent,
        FormCheckBoxBulkComponent,
        FormCheckBoxDefaultComponent,
        FormFileManagerComponent,
        FormInputComponent,
        FormButtonGroupComponent,
        FormMapIconPickerComponent,
        FormMapServerComponent,
        TimelineComponent,
        DeviceTimelineComponent
    ]
})
export class LumiFormModule {
    constructor() {
    }
}
