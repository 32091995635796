<div class="tree-map-form-navigation d-flex align-items-center justify-content-between w-100 d-block d-md-none" *ngIf="!isPublicPortal()">
    <div class="item ml-3" (click)="handleTabsOnStateChange(this.STATE_TREE, true)" *ngIf="!isTreeHidden()">
        <i class="material-icons p-0 m-0 mt-1" [ngClass]="{'rotate':!showTree, 'active':showTree}">menu_open</i>
    </div>
    <div class="item" [ngClass]="{'ml-3':isTreeHidden()}" (click)="handleTabsOnStateChange(this.STATE_MAP, true)">
        <i class="material-icons p-0 m-0 mt-1" *ngIf="activeMapTableTab == 'TABLE' || activeMapTableTab == 'MIXED' || activeMapTableTab == ''" [ngClass]="{'active':showTableMap}">reorder</i>
        <i class="material-icons p-0 m-0 mt-1" *ngIf="activeMapTableTab == 'MAP'" [ngClass]="{'active':showTableMap}">image</i>
    </div>
    <div class="item mr-3" (click)="handleTabsOnStateChange(this.STATE_FORM, true)">
        <i class="material-icons p-0 m-0 mt-1" [ngClass]="{'rotate active':showForm}">menu_open</i>
    </div>
</div>
<div class="module-wrapper"
     (globalResizeOutsideAngular)="handleResize()">
    <div class="container-fluid full-height tree-map-form-component">
            <div #dragContainer
                 class="row full-height flex-nowrap">
                <div [style.width]="mobileMode?'100%':treeCollapsed?TREE_COLLAPSED_WIDTH + 'px':leftPanelWidth + 'px'"
                     class="module-tree-container full-height no-mobile-padding pl-0 is-tab"
                     [ngClass]="{'is-active-tab': showTree, 'no-navigation':isTreeHidden()}"
                     [hidden]="isTreeHidden()">
                    <tree-component #treeComponent
                                    *ngIf="initialized"
                                    [hideActionIcon]="_treeSettings.hideActionIcon"
                                    [childNodeIcon]="_treeSettings.childNodeIcon"
                                    [nodeActionIcon]="_treeSettings.nodeActionIcon"
                                    [nodeActionTitleKey]="_treeSettings.nodeActionTitleKey"
                                    [unfoldFolderClick]="_treeSettings.unfoldFolderClick"
                                    [tree]="currentTree"
                                    [hideTree]="hideTree"
                                    [module]="MODULE_PATH"
                                    [counts]="MODULE_COUNTS"
                                    [collapsed]="treeCollapsed"
                                    (onSwitchTree)="handleSwitchTree()"
                                    (onToggleCollapse)="handleToggleCollapseTree($event)"
                                    (onTreeNodeCreate)="handleTreeNodeCreate($event)"
                                    (onTreeNodeAction)="handleTreeNodeAction($event)"
                                    (onSelectionChange)="handleTreeSelectionChanged($event)"
                                    (onTreeNodeClick)="handleTabsOnStateChange(1)">
                    </tree-component>
                </div>
                <slider-component [refId]="leftSliderId"
                                  *ngIf="!mobileMode && leftSliderId"
                                  class="{{isTreeSliderHidden()?'hidden':''}}"
                                  [minX]="-(leftPanelDefaultWidth /2)"
                                  [maxX]="(leftPanelDefaultWidth /2)"
                                  (onSlide)="handleTreeSliderSlide($event)">
                </slider-component>
                <div [style.min-width]="MAP_TABLE_MIN_WIDTH + 'px'"
                     [style.paddingLeft]="mobileMode?'':treeCollapsed?'0.5rem':''"
                     [style.paddingRight]="mobileMode || hideForm ?'':formCollapsed?'0.5rem':''"
                     class="module-map-container-stretched module-map-container no-mobile-padding is-tab"
                     [ngClass]="{'is-active-tab': showTableMap, 'no-navigation':isTreeHidden()}">
                    <map-table-component #mapTableComponent
                                         *ngIf="initialized"
                                         [tableOptions]="tableOptions"
                                         [filterString]="filterString"
                                         [allowSingleSelect]="_mapTableSettings.allowSingleSelect"
                                         [allowMultiSelect]="(_mapTableSettings.allowMultiSelect === false)? false : !mobileMode && auth.allowBatchUpdate()"
                                         [allowCreateMarker]="_mapTableSettings.allowCreateMarker"
                                         [allowMixedView]="(_mapTableSettings.allowMixedView === false)? false : !mobileMode"
                                         [allowAutoLoadMarkers]="_mapTableSettings.allowAutoLoadMarkers"
                                         [allowMarkerDrag]="(_mapTableSettings.allowMarkerDrag === false)? false : auth.allowMoveMapItem()"
                                         [useCustomIconColors]="_mapTableSettings.useCustomIconColors"
                                         [iconSet]="_mapTableSettings.iconSet"
                                         [isBasicMode]="_mapTableSettings.isBasicMode"
                                         [hideMap]="_mapTableSettings.hideMap"
                                         [isPublicPortal]="_mapTableSettings.isPublicPortal"
                                         [showReportInfoText]="_mapTableSettings.showReportInfoText"
                                         [showSearchBar]="_mapTableSettings.showSearchBar"
                                         [allowMultiLineInRow]="_mapTableSettings.allowMultiLineInRow"
                                         [showMapTableLoaders]="showMapTableLoaders"
                                         (onAutoLoadRequest)="handleAutoLoadRequest($event)"
                                         (onSelectionChange)="handleTableMapSelectionChange($event)"
                                         (onTableFilterChange)="handleTableFilterChange($event)"
                                         (onMapLongPress)="handleMapLongPress($event)">
                        <ng-content unbroken-top-bar select="[mapTableButtons]"></ng-content>
                        <ng-content broken-top-bar select="[mapTableButtonsBrokenTopBar]"></ng-content>
                    </map-table-component>
                </div>
                <slider-component [refId]="rightSliderId"
                                  *ngIf="!mobileMode && rightSliderId"
                                  class="{{isFormSliderHidden()?'hidden':''}}"
                                  [minX]="-(rightPanelDefaultWidth/2)"
                                  [maxX]="(rightPanelDefaultWidth/2)"
                                  (onSlide)="handleFormSliderSlide($event)">
                </slider-component>
                <div [style.min-width]="!formCollapsed?FORM_MIN_WIDTH + 'px':FORM_COLLAPSED_WIDTH + 'px'"
                     class="module-form-container no-mobile-padding pr-0 is-tab"
                     [style.width]="mobileMode ?'100%':formCollapsed?FORM_COLLAPSED_WIDTH + 'px':rightPanelWidth + 'px'"
                     [ngClass]="{'is-active-tab': showForm, 'no-navigation':isTreeHidden()}"
                     [hidden]="hideForm">
                    <section-form-component [module]="this.MODULE_PATH"
                                            *ngIf="initialized"
                                            #formComponent
                                            [collapsed]="formCollapsed"
                                            [formData]="formData"
                                            (onToggleCollapse)="handleToggleCollapseForm($event)"
                                            (onComponentEvent)="handleComponentEvent($event)">
                    </section-form-component>
                    <div class="no-form-data d-block d-md-none" *ngIf="!formData">{{getTranslation('Geen informatie beschikbaar')}}</div>
                </div>
            </div>
    </div>
</div>
