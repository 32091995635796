import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {HTTPService} from "../../shared/services/http/http.service";
import {Subscription} from 'rxjs';
import {INetworkMonitor} from "../../shared/services/state/global-state.interface";
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import * as dayjs from 'dayjs'
import {GlobalModel} from "../../shared/services/state/global.model";
import { faSymfony } from '@fortawesome/free-brands-svg-icons';
import {StorageService} from "../../shared/services/storage/storage.service";
import {UserRoles} from "../../shared/interfaces/user.interface";
import {environment} from "../../../environments/environment";

@Component ({
    selector: 'profiler',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'profiler.component.html',
    styleUrls: ['profiler.component.scss']
})
export class ProfilerComponent implements OnDestroy {
    faSymfony = faSymfony;
    public profilePosition:string = 'right-side';
    public profilerModeEnabled:boolean = false;
    private subPendingCallPaths:Subscription;
    public searchQuery:string = '';

    constructor(public model:GlobalModel, private httpService:HTTPService, private globalStateService:GlobalStateService, private cd:ChangeDetectorRef, private storageService:StorageService) {
        this.subPendingCallPaths = this.httpService.pendingCallPaths.subscribe((paths:string[]) => {
            //Refresh the monitor by checking the pending calls
            this.cd.markForCheck();
        });

        this.storageService.getStringValue(StorageService.KEY_PROFILE_POSITION, (value: any) => {
            this.profilePosition = value;
        });
        this.storageService.getBooleanValue(StorageService.KEY_PROFILER_MODE, (value: boolean) => {
            this.profilerModeEnabled = value;
        });
        this.storageService.getBooleanValue(StorageService.KEY_PROFILER_OPEN, (value: boolean) => {
            this.model.showProfilerWindow = value;
        });
    }

    public clearSearch():void{
        this.searchQuery = '';
    }

    public getNetworkList():INetworkMonitor[]{
        if(this.searchQuery != ''){
            return this.globalStateService.getSetNetworkTrafficState("get").filter(_x => {
                return _x.callUrl.includes(this.searchQuery);
            });
        } else {
            return this.globalStateService.getSetNetworkTrafficState("get");
        }

    }

    public hasUserEngineerRole(): boolean {
        if (!environment.production) {
            return this.model.getUserRoleCodes().some(role => role === UserRoles.ENGINEER);
        } else {
            return false;
        }
    }

    public closeProfiler():void{
        this.model.showProfilerWindow = false;
        this.storageService.setValue(StorageService.KEY_PROFILER_OPEN, false);
    }

    public openProfiler():void{
        this.model.showProfilerWindow = true;
        this.storageService.setValue(StorageService.KEY_PROFILER_OPEN, true);
    }

    public clearProfiler():void{
        this.globalStateService.clearNetworkTrafficState();
    }

    public changePosition():void{
        const position:string = this.profilePosition == 'right-side' ? 'left-side' : 'right-side';
        this.profilePosition = position;
        this.storageService.setValue(StorageService.KEY_PROFILE_POSITION, position);
    }

    public formatDateTime(dateTime:string|Date):string{
        let dateTimeFormat = "HH:mm:ss";
        return dayjs(dateTime).format(dateTimeFormat);
    }

    ngOnDestroy():any {
        this.subPendingCallPaths.unsubscribe();
    }
}
