<nav #containerElement
     class="navbar navbar-inverse navbar-toggleable-lg navbar-dark bg-faded menu-navbar mobile-flex-direction-column"
     (globalResizeOutsideAngular)="handleResize()"
     (globalClickOutsideAngular)="handleClickWindow()">
  <div class="menu-left-container">
    <a class="navbar-brand menu-logo-link" href="/">
      <img class="menu-logo" [style.margin-top]="getLogoMargin()" src="{{ getLogoPath() }}"
           alt="{{'Luminizer logo' | translate}}">
    </a>
  </div>
  <history-component *ngIf="!isPublicPortal()" [isMobile]="true"></history-component>
  <a *ngIf="!isPublicPortal()" routerLink="/notifications" id="notificationMobileHighlightId" class="notifications-bell navbar-toggler-right d-block d-xl-none" [ngClass]="model.hasNewNotifications ? 'has-notifications' : ''">
    <div class="notify-badge" *ngIf="model.hasNewNotifications"></div>
    <i class="material-icons md-light menu-icon">circle_notifications</i>
  </a>
  <button #collapseMenuButton
          class="{{isSimpleMenu?'d-none':''}} navbar-toggler navbar-toggler-right menu-collapse-button collapsed"
          type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div #menu [style.visibility]="isSimpleMenu?'hidden':'visible'"
       class="collapse navbar-collapse menu-navbar-collapse" id="navbarNavAltMarkup">
    <div class="d-flex justify-content-between d-block d-xl-none">
        <div class="d-none d-sm-block">
          <img class="menu-logo" [style.margin-top]="getLogoMargin()" src="{{ getLogoPath() }}" alt="{{'Luminizer logo' | translate}}">
        </div>
        <div class="d-flex justify-content-end">
          <menu-search-mobile-component class="mobile-search-dropdown" id="searchHighlightIdMobile"></menu-search-mobile-component>
          <button #collapseMenuButton
                  class="{{isSimpleMenu?'d-none':''}} navbar-toggler navbar-toggler-right menu-collapse-button mobile-menu-close-button"
                  type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-close-icon" aria-hidden="true">&times;</span>
          </button>
        </div>
    </div>


    <div class="navbar-nav d-flex menu-content-container">
      <li id="areaalHighlightId" class="mr-3 {{getAreaalMenuListClasses()}}" #areaalDropDown>
        <a style="font-size:15px;font-weight: 400;border-radius: 2px;"
           class="{{getAreaalMenuItemClasses()}} blue-areaal-button-background" aria-haspopup="true" aria-expanded="false"
           [title]="getFullAreaName()"
           (click)="handleClickAreaal($event)">{{this.getShortenedAreaName()}}</a>
        <div class="dropdown-menu dropdown menu-areaal-dropdown"
             [style.display]="showAreaalMenu?'inline':'none'" (click)="handleClickMenuDropdown($event)">
          <!-- Only visible if atleast 1 recent areas and total areas bigger than 3-->
          <ng-container
                  *ngIf="_recentArealen.length >= 1 && _arealen.length > VISIBLE_RECENT_AREALEN_COUNT">
            <h6 class="menu-dropdown-header">{{'RECENT GEKOZEN' | translate}}</h6>
            <ng-container *ngFor="let item of _recentArealen">
              <a class="luminizer-main-menu-drop-down-item"
                 (click)="handleSelectAreaal(item,$event)">{{item.label}}</a>
            </ng-container>
            <div class="dropdown-divider"></div>
          </ng-container>
          <div *ngIf="_arealen.length > SEARCHBOX_VISIBLE_ITEM_COUNT"
               class="menu-areaal-search-container">
            <input #searchInput class="form-control menu-areaal-search" type="text"
                   placeholder="{{'Zoek areaal' | translate}}"
                   (keydownOutsideAngular)="handleSearchKey($event)"
                   (inputOutsideAngular)="filterAreaalDropdown()">
          </div>
          <div class="menu-areaal-list-container" #searchItemList
               (mouseup)="handleClickListContainer()"
               (mousedown)="handleClickListContainer()">
            <div *ngFor="let item of filteredSearchItems" #searchItems>
              <a class="luminizer-main-menu-drop-down-item"
                 (click)="handleSelectAreaal(item,$event)">{{item.label}}</a>
            </div>
            <!-- Fix for IE's infinite scrollbar. Always supply one item. See css for more info-->
            <a *ngIf="_arealen.length > SEARCHBOX_VISIBLE_ITEM_COUNT && filteredSearchItems.length == 0"
               class="luminizer-main-menu-drop-down-item-no-results">{{'Geen resultaten' | translate}}</a>
          </div>
        </div>
      </li>
      <ng-container *ngIf="_mainMenuItems">
        <dynamic-menu-item-component class="d-flex flex-wrap" id="itemsHighlightId"
                                     [menuItems]="_mainMenuItems" [level]="0"
                                     (menuAction)="handleMenuAction()"></dynamic-menu-item-component>
      </ng-container>
    </div>

    <ul class="navbar-nav ml-auto nav-flex-icons d-flex align-items-center">
      <div class="menu-icon-container">
        <div class="d-none d-xl-block">
          <menu-search-component class="d-flex h-100" id="searchHighlightId"></menu-search-component>
        </div>
        <history-component [isMobile]="false"></history-component>
        <right-side-menu-component id="settingsHighlightId"
                                   (menuAction)="handleMenuAction()"></right-side-menu-component>
        <li id="userHighlightId" class="nav-item dropdown {{getRouteActiveClass('user-profile')}}">
          <a class="nav-link waves-effect waves-light menu_icon" id="navbarDropdownMenuLink2"
             data-toggle="dropdown">
            <i class="material-icons md-light menu-icon">account_circle</i>
          </a>
          <div class="dropdown-menu dropdown-secondary" aria-labelledby="navbarDropdownMenuLink2"
               data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
            <!--<a routerLink="settings/user" routerLinkActive="active" (click)="handleMenuAction($event)" class="luminizer-main-menu-drop-down-item waves-effect waves-light">{{'settings' | translate}}</a>-->
            <h6 class="menu-dropdown-header">{{("menu.thisuser" | translate).toUpperCase()}}</h6>
            <div class="d-flex pl-3 w-100">
              <i class="material-icons md-light menu-icon text-color-widget-grey" style="font-size: 32px;">account_circle</i>
              <div class="px-2 text-color-widget-grey">
                <div>{{this.getUserName()}}</div>
                <div style="font-size: 14px; white-space: normal; min-width: 12rem;">{{this.model.getUserRoleLabels().join(", ")}}</div>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <a routerLink="/information/contact" routerLinkActive="active"
               (click)="handleMenuAction()"
               class="luminizer-main-menu-drop-down-item waves-effect waves-light">{{'Support' | translate}}</a>
              <!-- NOTE: Stedin gebruikt nog een oude pdf handleiding.. en anderstalige gebruikers ook. Dan moeten we deze helaas toch nog even erin houden-->
              <a routerLink="/information/manual" routerLinkActive="active"
                 (click)="handleMenuAction()"
                 class="luminizer-main-menu-drop-down-item waves-effect waves-light">{{'Handleiding' | translate}}</a>
              <a routerLink="/information/release-notes" routerLinkActive="active"
                 (click)="handleMenuAction()"
                 class="luminizer-main-menu-drop-down-item waves-effect waves-light">{{'Release notes' | translate}}</a>
              <!-- <a routerLink="/information/about-us" routerLinkActive="active" (click)="handleMenuAction($event)" class="luminizer-main-menu-drop-down-item waves-effect waves-light">{{'Over Luminizer' | translate}}</a>-->
              <a routerLinkActive="active" (click)="handleClickMenuItemAbout()"
                 class="luminizer-main-menu-drop-down-item waves-effect waves-light">{{'Over Luminizer' | translate}}</a>
              <div class="dropdown-divider"></div>

              <a routerLink="settings/user-profile" routerLinkActive="active"
               (click)="handleMenuAction()"
               class="luminizer-main-menu-drop-down-item waves-effect waves-light">{{'menu.profile' | translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="luminizer-main-menu-drop-down-item waves-effect waves-light"
               (click)="handleLogout()">{{'Log uit' | translate}}</a>
          </div>
        </li>
        <li class="nav-item" id="notificationHighlightId">
          <notifications-sidebar-component></notifications-sidebar-component>
        </li>
      </div>
    </ul>
  </div>
  <div class="menu-flat-loader-container">
    <div class="progress menu-flat-loader" [style.visibility]="callsPending()?'visible':'hidden'">
      <div class="indeterminate menu-flat-loader-inside"></div>
    </div>
  </div>
</nav>
