<p class="mb-1 device-mapping-intro-text" [innerHTML]="popupIntroText"></p>
<div class="device-mapping-container">
    <div class="selected-device-info-container" [ngClass]="{'open':infoWindowOpen}">
        <div class="inner">
            <div class="d-flex justify-content-center" *ngIf="infoWindowLoading">
                <div class="loader-smaller"></div>
            </div>
            <div *ngIf="!infoWindowLoading && selectedMapItemInfo">
                <div class="header mb-2 d-flex justify-content-between">
                    <h5>{{'device-mapping.title' | translate}}</h5>
                    <i class="material-icons" (click)="closeMarkerInfoWindow()">close</i>
                </div>
                <div class="info">
                    <div class="d-flex justify-content-between">
                        <label>{{'device-mapping.label-id' | translate}}</label>
                        <div>{{selectedMapItemInfo.baseObjectId}}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <label>{{'device-mapping.label-code' | translate}}</label>
                        <div>{{selectedMapItemInfo.code}}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <label>{{'device-mapping.label-type' | translate}}</label>
                        <div class="text-uppercase">{{selectedMapItemInfo.baseObjectType}}</div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" *ngIf="availableDriverList.length > 0">
                        <label *ngIf="availableDriverList.length > 1">{{'device-mapping.select-driver' | translate}}</label>
                        <label *ngIf="availableDriverList.length == 1">{{'device-mapping.selected-driver' | translate}}</label>
                        <lumi-select class="map-item-select"
                                     [options]="availableDriverList"
                                     [disabled]="availableDriverList.length == 1"
                                     [selectedOptions]="availableDriverList.length == 1 ? availableDriverList : []"
                                     [showOptionFilter]="availableDriverList.length > 10"
                                     (onOptionsSelect)="setSelectedDropdownOption($event)">
                        </lumi-select>
                    </div>
                    <div class="extra-info">
                        <ng-container *ngIf="selectedDriverToMapTo && selectedDriverToMapTo.code == 'occupied'">
                            <div class="d-flex description">
                                <p>{{'device-mapping.occupied-intro' | translate}}</p>
                            </div>
                            <div class="d-flex mt-2 occupied-by">
                                <img *ngIf="getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.icon"
                                     [src]="'/assets/img/lumicon/16x16/'+getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.icon+'.png'"
                                     [alt]="getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.code"
                                     class="mr-1" />
                                <div>
                                    <span class="link-text" [title]="'device-mapping.go-to-olc' | translate" (click)="switchSelectedDevice(selectedDriverToMapTo.id)"><strong>{{getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.code}}</strong></span><br />
                                    <div class="d-flex align-items-center">
                                        <span *ngIf="getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.state">{{getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.state}}&nbsp;</span>
                                        <span *ngIf="getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.state && getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.lastCommunication">&nbsp;|&nbsp;</span>
                                        <span *ngIf="getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.lastCommunication">{{getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.lastCommunication | humanDate}}&nbsp;</span>
                                        <i *ngIf="getOccupiedDeviceById(selectedDriverToMapTo.id).occupiedBy.lastCommunication" class="material-icons" data-toggle="tooltip" data-placement="top" matTooltip="{{'lastcommunication' | translate}}">info</i>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex mt-2 description">
                                <p [innerHTML]="'device-mapping.occupied-action-question' | translate: [selectedMapItemInfo.code]"></p>
                            </div>
                            <div class="d-flex mt-2">
                                <lumi-button
                                        [label]="'device-mapping.action-decouple' | translate"
                                        [rank]="'secondary'"
                                        [size]="'medium'"
                                        (click)="mapDevice(getOccupiedDeviceById(selectedDriverToMapTo.id).decoupleAndJoinUrl)"
                                ></lumi-button>
                                <lumi-button
                                        [label]="'device-mapping.action-archive' | translate"
                                        [rank]="'secondary'"
                                        [size]="'medium'"
                                        (click)="mapDevice(getOccupiedDeviceById(selectedDriverToMapTo.id).archiveAndJoinUrl)"
                                ></lumi-button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="selectedDriverToMapTo && selectedDriverToMapTo.code == 'free'">
                            <div class="d-flex description">
                                <p>{{'device-mapping.free-intro' | translate}}</p>
                            </div>
                            <div class="d-flex mt-2">
                                <lumi-button
                                        [label]="'device-mapping.action-couple' | translate"
                                        [rank]="'secondary'"
                                        [size]="'medium'"
                                        (click)="mapDevice(getFreeDeviceById(selectedDriverToMapTo.id).joinUrl)"
                                ></lumi-button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div #mapContainer id="mapContainer" class="w-100"></div>
</div>
<div class="action d-flex  mt-4" [ngClass]="showUnmapBtn ? 'justify-content-between' : 'justify-content-end'">
    <div *ngIf="showUnmapBtn">
        <lumi-button
                [title]="'button.unlink-asset' | translate"
                [rank]="'secondary'"
                [size]="'medium'"
                [icon]="'call_split'"
                (click)="unMapDevice()"
        ></lumi-button>
    </div>
    <lumi-button
            [label]="'close' | translate"
            [rank]="'tertiary'"
            [size]="'large'"
            (click)="closePopupClick()"
    ></lumi-button>
</div>
