import {MapItem} from '../components/map/map-item/map-item';
import {TranslateService} from '../services/translate/translate.service';
import {LoggerService} from "../services/logger/logger.service";

declare let google: any;

/**
 * Created by Christiaan on 21/03/2017.
 */
export default class MapIconGenerator {
    //TODO: staat deze class op de juiste plaats? is wel een soort util/setting class
    //TODO: moet dit niet een soort singleton class zijn? heeft weinig zin om meerdere instanties te maken

    //TODO: wat moet het standaard formaat worden?
    private static readonly CANVAS_WIDTH: number = 200;
    private static readonly CANVAS_HEIGHT: number = 50; //50;

    //Use numbers dividable by two
    private static readonly COLOR_MARKER_CANVAS_WIDTH: number = 16;
    private static readonly COLOR_MARKER_CANVAS_HEIGHT: number = 16;

    public static readonly ICON_BASE_PATH: string = '/assets/img/lumicon/';
    public static readonly ICON_PATH_SIZE_16: string = '16x16/';
    public static readonly ICON_PATH_RWS: string = 'rws/';
    public static readonly ICON_PATH_STEDIN: string = 'stedin/';
    private static readonly ICON_SELECTED_SUFFIX: string = '-sel';
    private static readonly ICON_EXTENSION_SUFFIX: string = '.png';
    private static readonly DEFAULT_ICON: string = 'mst-blue';
    public static readonly LOCATION_ICON_ACTIVE: string = 'location_active';
    public static readonly LOCATION_ICON_INACTIVE: string = 'location_inactive';
    private static MULTIPLE_ICONS_LABEL: string; //= "meerdere items";

    private static COLOR_BLACK: string = '#000000';
    private static COLOR_WHITE: string = '#ffffff';

    private readonly canvas: HTMLCanvasElement;
    private readonly context: CanvasRenderingContext2D;

    private readonly colorMarkerCanvas: HTMLCanvasElement;
    private readonly colorMarkerContext: CanvasRenderingContext2D;

    private dynamicMarkerImages: MapIconInterface[] = [];
    private dynamicColorMarkers: ColorMarkerInterface[] = [];

    private readonly imageLoadedHandler: Function;

    public static readonly CANVAS_MARKER_HEIGHT_OFFSET: number = 10;

    //Deze worden nu niet meer gebruikt. nog even bewaren voor als we iets speciaals met de markers willen
    private staticMarkerImages: StaticMarkerImagesInterface = {

        //Mogelijke marker opties for googlemaps:
        /*// This marker is 20 pixels wide by 32 pixels high.
         size: new google.maps.Size(20, 32),
         // The origin for this image is (0, 0).
         origin: new google.maps.Point(0, 0),
         // The anchor for this image is the base of the flagpole at (0, 32).
         anchor: new google.maps.Point(0, 32),
         labelOrigin: new google.maps.Point(11, 10)*/

        //TODO: vul alle positioneringsgegevens in voor nieuwe icons
        //TODO: deze worden altijd ingeladen van te voren, gaan we dat ooit nog gebruiken?
        markerImageDefault: {
            url: MapIconGenerator.ICON_BASE_PATH + MapIconGenerator.ICON_PATH_SIZE_16 + MapIconGenerator.DEFAULT_ICON + MapIconGenerator.ICON_EXTENSION_SUFFIX,
            //anchor: new google.maps.Point(0, 32),   //20x32
            anchor: new google.maps.Point(8, 8),    //16x16
            img: new Image(),
            shape: null,
        },

        markerImageLocationActive: {
            url: MapIconGenerator.ICON_BASE_PATH + MapIconGenerator.LOCATION_ICON_ACTIVE + MapIconGenerator.ICON_EXTENSION_SUFFIX,
            //anchor: new google.maps.Point(0, 32),   //20x32
            anchor: new google.maps.Point(8, 38),    //17x38
            img: new Image(),
            shape: null,
        },
        markerImageLocationInactive: {
            url: MapIconGenerator.ICON_BASE_PATH + MapIconGenerator.LOCATION_ICON_INACTIVE + MapIconGenerator.ICON_EXTENSION_SUFFIX,
            //anchor: new google.maps.Point(0, 32),   //20x32
            anchor: new google.maps.Point(8, 38),    //17x38
            img: new Image(),
            shape: null,
        },
    };

    constructor(private ts: TranslateService, imageLoadedHandler: Function, protected logger:LoggerService) {

        MapIconGenerator.MULTIPLE_ICONS_LABEL = ts.translate('marker.multiple'); //Meedere items geselecteerd

        this.imageLoadedHandler = imageLoadedHandler;

        this.staticMarkerImages.markerImageDefault.img.setAttribute('src', this.staticMarkerImages.markerImageDefault.url);

        //Creating the canvas via a function by reference doesn't seem to work. So create them manually
        this.canvas = document.createElement('canvas');
        this.context = this.canvas.getContext('2d');

        this.colorMarkerCanvas = document.createElement('canvas');
        this.colorMarkerContext = this.colorMarkerCanvas.getContext('2d');

        MapIconGenerator.initCanvas(this.canvas, this.context, MapIconGenerator.CANVAS_WIDTH, MapIconGenerator.CANVAS_HEIGHT);
        MapIconGenerator.initCanvas(this.colorMarkerCanvas, this.colorMarkerContext, MapIconGenerator.COLOR_MARKER_CANVAS_WIDTH, MapIconGenerator.COLOR_MARKER_CANVAS_HEIGHT);
        this.loadStaticMarkerImages();
    }

    //Load the real images (not just url's) for drawing on the canvas
    private loadStaticMarkerImages(): void {
        let markerImage;
        for (let key in this.staticMarkerImages) {
            markerImage = this.staticMarkerImages[key];
            markerImage.img = new Image();
            markerImage.img.setAttribute('src', markerImage.url);
        }
    }

    private static initCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, width: number, height: number) {
        // canvas = document.createElement('canvas');
        // context = canvas.getContext("2d");

        canvas.width = width;
        canvas.height = height;

        MapIconGenerator.setLabelStyle(context);
    }

    private static setLabelStyle(context: CanvasRenderingContext2D) {
        //Set label style
        context.strokeStyle = 'white';
        context.lineWidth = 3;
        context.miterLimit = 2;
        context.fillStyle = 'black';
    }

    //Construct a label for a map item, depending on the base objects in it
    public constructLabel(marker: MapItem): string {
        //If baseobjects present
        if (marker.baseObjects) {

            //Only use visible base objects to construct the label
            let visibleBaseObjects: any = [];

            marker.baseObjects.forEach((baseObject: any) => {
                if (!baseObject.hidden) {
                    visibleBaseObjects.push(baseObject);
                }
            });

            /*            if (visibleBaseObjects.length == 0){
                            //There are no baseobjects visible for this item, so the item is hidden. But the item still needs a label. This can happen wile filtering on baseobjects
                            visibleBaseObjects = marker.baseObjects;
                        }*/

            if (visibleBaseObjects.length == 1) {

                //If one baseobject, take that label
                return visibleBaseObjects[0].label;

            }
            else if (visibleBaseObjects.length > 1) {
                //Multiple baseobjects

                //Collect all labels
                let stringArray: any = [];
                visibleBaseObjects.forEach((baseObject: any) => {
                    stringArray.push(baseObject.label);
                });

                //Get similar part of strings in array, checked from the start of the string
                let result: string = MapIconGenerator.getSharedStringStart(stringArray);

                if (result == '') {
                    //No similarities, show default text
                    result = MapIconGenerator.MULTIPLE_ICONS_LABEL;
                }
                else {
                    //Similarities, show base+X
                    //TODO: doesn't work perfectly in case of two map items with the same code, X should not get appended

                    //Search for the longest item to know how many X's to add
                    let longestString: string = stringArray.sort(function (a: any, b: any) {
                        return b.length - a.length;
                    })[0];
                    let variableStringPartLength: number = longestString.length - result.length;

                    //Limit to max 3 x-es
                    variableStringPartLength = Math.min(3, variableStringPartLength);

                    for (let i = 0; i < variableStringPartLength; i++) {
                        result += 'X';
                    }
                }

                return result;
            }
            else {
                //no visible baseobjects or an empty baseobject object
                return '';
            }
        }
        else if (marker.label) {
            return marker.label;
        }
    }

    //Return the shared part of an array of strings. Checked from the start of the string ('207-rotonde 2a' + '207-rotonde 2b' will return '207-rotonde 2'
    private static getSharedStringStart(stringArray: string[]) {
        let sortedArray = stringArray.concat().sort();
        let startString: string = sortedArray[0];
        let endString: string = sortedArray[sortedArray.length - 1];
        let stringLength: number = startString.length;

        let i: number = 0;
        while (i < stringLength && startString.charAt(i) == endString.charAt(i)) {
            i++;
        }

        return startString.substring(0, i);
    }

    private static drawCircle(context: CanvasRenderingContext2D, centerX: number, centerY: number, radius: number, strokeSize: number, fillColor: string, strokeColor: string) {
        //TODO: styling zit nu vast in deze functie, dat kan er ooit uit
        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        context.fillStyle = fillColor; //'white';
        context.fill();

        context.lineWidth = strokeSize;
        context.strokeStyle = strokeColor;
        context.stroke();
    }

    //Draw icon+labels on canvas and return the image
    public getIconWithLabel(marker: MapItem): MapIconInterface {
        //Clear the canvas
        this.context.clearRect(0, 0, MapIconGenerator.CANVAS_WIDTH, MapIconGenerator.CANVAS_HEIGHT);

        //Here, marker.icon is the icon in general. So, for example, it's selected state isn't included in the name. This is determined later, in the getIcon() function.
        let icon: any = this.getIcon(marker);
        let iconImg: any = icon.img;

        //this.logger.log("[MapIconGenerator] " + "img.complete: " + iconImg.complete);
        //this.logger.log("[MapIconGenerator] " + "img: " , iconImg.src);

        if (!iconImg.complete) {

            //Only add 1 event listener per image
            if (!iconImg.hasLoadHandler) {
                iconImg.hasLoadHandler = true;

                this.logger.log('[MapIconGenerator] ' + 'Listen to onLoad for: ', iconImg.src);

                //Wait until image is loaded. When it is, send an event to redraw incomplete markers
                iconImg.addEventListener('load', (e: Event) => {

                    //The source of the new icon is passed, this isn't the same as marker.icon per se.
                    //e.g. it could be the selected state

                    //TODO: iconImg.src is het complete pad. Je wilt hier misschien alleen de naam van het icoontje bv 'dyn-blue-sel'
                    this.imageLoadedHandler(iconImg.src);
                });
            }

            //Image is not loaded, return empty marker
            return null;

        }
        else {
            return this.buildIconWithLabel(icon, iconImg, marker);
        }
    }

    private buildIconWithLabel(icon: MapIconInterface, iconImg: HTMLImageElement, marker: MapItem): MapIconInterface {
        let iconX: number = MapIconGenerator.CANVAS_WIDTH / 2 - icon.anchor.x; //(iconImg.width /2);

        //Draw the icon
        this.context.drawImage(iconImg, iconX, MapIconGenerator.CANVAS_MARKER_HEIGHT_OFFSET);

        let markerCharacter: string = marker.char;
        if (markerCharacter && markerCharacter != '') {
            MapIconGenerator.drawCircle(this.context, (MapIconGenerator.CANVAS_WIDTH / 2) - 10, (MapIconGenerator.CANVAS_HEIGHT / 2) - 13, 6, 1, 'white', 'grey');
            MapIconGenerator.setLabelStyle(this.context);
            this.context.fillText(markerCharacter, MapIconGenerator.CANVAS_WIDTH / 2 - 12, MapIconGenerator.CANVAS_MARKER_HEIGHT_OFFSET + 5);
        }

        let markerLabel: string = this.constructLabel(marker);
        let textWidth: number = this.context.measureText(markerLabel).width;

        //Draw the textstroke
        this.context.strokeText(markerLabel, MapIconGenerator.CANVAS_WIDTH / 2 - (textWidth / 2), iconImg.height + 9 + MapIconGenerator.CANVAS_MARKER_HEIGHT_OFFSET);

        //Draw the text
        this.context.fillText(markerLabel, MapIconGenerator.CANVAS_WIDTH / 2 - (textWidth / 2), iconImg.height + 9 + MapIconGenerator.CANVAS_MARKER_HEIGHT_OFFSET); //TODO: measureText geeft geen height :s dus even hardcoded voor nu

        // TODO: moet de anchor niet weer uit de img komen? Nu werkt het niet lekker voor bv het plaatje van het vlaggetje. <= speelt dit nog wel?

        // TODO: Op computers met een touchscreen werkt de shape.coords niet om de hitbox aan te geven, dus wordt nu de marker zelf wat kleiner gehouden om de clickarea kleiner te krijgen en iig een gedeelte van het probleem op te lossen
        // TODO: De marker hoeft nooit groter te zijn dan de textbreedte, of als er geen text is, niet groter dan het icoontje met mogelijk character-bolletje

        //6 = stroke padding (2*3)
        let totalImgWidth: number = Math.max(textWidth + 6, (iconImg.width + 7)); // Was vroeger MapIconGenerator.CANVAS_WIDTH
        let totalImgWidthCenter: number = totalImgWidth / 2;

        //Return image with other properties needed for drawing
        return {
            url: this.canvas.toDataURL('image/png'),
            anchor: new google.maps.Point(totalImgWidthCenter, iconImg.height + MapIconGenerator.CANVAS_MARKER_HEIGHT_OFFSET),
            origin: new google.maps.Point((MapIconGenerator.CANVAS_WIDTH / 2 - (totalImgWidthCenter)), 0),
            size: new google.maps.Size(totalImgWidth, MapIconGenerator.CANVAS_HEIGHT),

            //Define the hitbox for the icon. So the user can't select the label
            // rect:x1, y1 =upper left corner, x2, y2 = lower right corner
            shape: {
                coords: [totalImgWidthCenter - (iconImg.width / 2), MapIconGenerator.CANVAS_MARKER_HEIGHT_OFFSET, totalImgWidthCenter + (iconImg.width / 2), iconImg.height + MapIconGenerator.CANVAS_MARKER_HEIGHT_OFFSET],
                type: 'rect',
            },
        };
    }

    // There is only a file name to conclude which folder to use
    // TODO: als er meer verschillende folders komen moet dit op een andere handige manier. Path meesturen met icon?
    private static getIconFolder(completeImageName: string): string {
        if (completeImageName.substr(0, 3) == 'rws') {
            return MapIconGenerator.ICON_PATH_RWS;
        }

        if (completeImageName.substr(0, 6) == 'stedin') {
            return MapIconGenerator.ICON_PATH_STEDIN;
        }

        return MapIconGenerator.ICON_PATH_SIZE_16;
    }

    public getIcon(markerData: {icon?: string, color?: string, selected?: boolean}): MapIconInterface {
        //Create the marker image on request and save the image for future reference
        //Is super dynamic, but might cost a little bit more processing power
        if (!markerData.icon && !markerData.color) {
            this.logger.log('[MapIconGenerator] ' + 'WARNING: Map item has no marker or color, default icon was applied. Marker data is ', markerData);
            return this.staticMarkerImages.markerImageDefault;
        }

        if (markerData.icon == MapIconGenerator.LOCATION_ICON_ACTIVE) {
            return this.staticMarkerImages.markerImageLocationActive;
        }
        else if (markerData.icon == MapIconGenerator.LOCATION_ICON_INACTIVE) {
            return this.staticMarkerImages.markerImageLocationInactive;
        }

        //For debug
        //markerData.icon = null;
        //markerData.color = "#FF00FF";

        if (markerData.icon) {

            let completeImageName: string = markerData.icon + (markerData.selected ? MapIconGenerator.ICON_SELECTED_SUFFIX : '');
            let matchingImage: any;

            //Search for a preexisting marker image
            this.dynamicMarkerImages.forEach((image: any) => {
                if (image.url == (MapIconGenerator.ICON_BASE_PATH + MapIconGenerator.getIconFolder(completeImageName) + completeImageName + MapIconGenerator.ICON_EXTENSION_SUFFIX)) {
                    matchingImage = image;
                }
            });

            if (matchingImage) {
                return matchingImage;
            }
            else {

                let matchingSelectedImage;
                //This is a new marker. Check if the selected variant is available. Pre-loading is necessary for drawing on the canvas, else the icon will be invisible
                if (!markerData.selected) {
                    this.dynamicMarkerImages.forEach((image: any) => {
                        if (image.url == (MapIconGenerator.ICON_BASE_PATH + MapIconGenerator.getIconFolder(completeImageName) + completeImageName + MapIconGenerator.ICON_SELECTED_SUFFIX + MapIconGenerator.ICON_EXTENSION_SUFFIX)) {
                            matchingSelectedImage = image;
                        }
                    });

                    //If no selected variant is found, load it
                    if (!matchingSelectedImage) {
                        this.loadNewImage(completeImageName + MapIconGenerator.ICON_SELECTED_SUFFIX);
                    }
                }
                return this.loadNewImage(completeImageName);
            }
        }
        else if (markerData.color) {

            let matchingColorMarker: any;
            let strokeColor: any = markerData.selected ? MapIconGenerator.COLOR_BLACK : MapIconGenerator.COLOR_WHITE;

            this.dynamicColorMarkers.forEach((symbol: any) => {
                if (symbol.fillColor == markerData.color && strokeColor == symbol.strokeColor) {
                    matchingColorMarker = symbol;
                }
            });

            if (matchingColorMarker) {
                return matchingColorMarker;
            }
            else {
                // Preload de selected state ook alvast als je de normale state aanmaakt. Anders is hij onzichtbaar
                if (!markerData.selected) {
                    this.loadNewColorImage(markerData.color, MapIconGenerator.COLOR_BLACK);
                }
                return this.loadNewColorImage(markerData.color, strokeColor);
            }
        }
        else {
            //Can't generate an icon
            return null;
        }


        //Voor bepalen van icoontjes adhv type. Nog even bewaren
        /*let strippedType:string = markerData.type.toLowerCase();
        strippedType = strippedType.replace("_", "");

        if (markerData.selected){
            switch (strippedType) {
                case MapItem.MAPITEM_TYPE_WERKORDER:
                case MapItem.MAPITEM_TYPE_OLC:
                case MapItem.MAPITEM_TYPE_MAST: {
                    return this.markerImages.markerImageMastSelected;
                }
                case MapItem.MAPITEM_TYPE_SEGMENT: {
                    return this.markerImages.markerImageSegmentSelected;
                }
            }
        }else {
            switch (strippedType) {
                case MapItem.MAPITEM_TYPE_WERKORDER:
                case MapItem.MAPITEM_TYPE_OLC:
                case MapItem.MAPITEM_TYPE_MAST: {
                    return this.markerImages.markerImageMast;
                }
                case MapItem.MAPITEM_TYPE_SEGMENT: {
                    return this.markerImages.markerImageSegment;
                }

            }
        }

         return this.markerImages.markerImageDefault;
        */
    }

    public loadNewColorImage(color: string, strokeColor: string): ColorMarkerInterface {
        this.logger.log('[MapIconGenerator] ' + 'Create new color marker. Fill: ' + color + ' Stroke ' + strokeColor);
        //Clear the canvas
        this.colorMarkerContext.clearRect(0, 0, MapIconGenerator.COLOR_MARKER_CANVAS_WIDTH, MapIconGenerator.COLOR_MARKER_CANVAS_HEIGHT);

        //Draw the circle
        //(Radius + stroke) * 2 = marker formaat (16x16)
        MapIconGenerator.drawCircle(this.colorMarkerContext, (MapIconGenerator.COLOR_MARKER_CANVAS_WIDTH / 2), (MapIconGenerator.COLOR_MARKER_CANVAS_HEIGHT / 2), (MapIconGenerator.COLOR_MARKER_CANVAS_WIDTH / 2) - 1, 1, color, strokeColor);

        let newColorMarker: ColorMarkerInterface = {
            fillColor: color,
            strokeColor: strokeColor,
            url: this.colorMarkerCanvas.toDataURL('image/png'),
            anchor: new google.maps.Point(MapIconGenerator.COLOR_MARKER_CANVAS_WIDTH / 2, MapIconGenerator.COLOR_MARKER_CANVAS_HEIGHT / 2),
            img: new Image(),
            size: new google.maps.Size(MapIconGenerator.COLOR_MARKER_CANVAS_WIDTH, MapIconGenerator.COLOR_MARKER_CANVAS_HEIGHT),
        };

        newColorMarker.img.setAttribute('src', newColorMarker.url);

        this.dynamicColorMarkers.push(newColorMarker);

        return newColorMarker;
    }

    private static getAnchorPosition(completeImageName: string): google.maps.Point {
        if (completeImageName.substr(0, 3) == 'rws') {
            return new google.maps.Point(15, 35);
        }
        else {
            return new google.maps.Point(11, 11);
            // return new google.maps.Point(8, 8);
        }
    }

    private loadNewImage(completeImageName: string): MapIconInterface {
        //Create new image. For now the origin and filetype are hardcoded
        const newImage: MapIconInterface = {
            url: MapIconGenerator.ICON_BASE_PATH + MapIconGenerator.getIconFolder(completeImageName) + completeImageName + MapIconGenerator.ICON_EXTENSION_SUFFIX,
            anchor: MapIconGenerator.getAnchorPosition(completeImageName),
            img: new Image(),
            shape: null,
        };

        this.logger.log('[MapIconGenerator] ' + 'loadNewImage: ' + newImage.url);

        //Load imagedata
        newImage.img.setAttribute('src', newImage.url);

        //Add for future use
        this.dynamicMarkerImages.push(newImage);

        return newImage;
    }

    //Voor het tekenen van svg circle
    //Deze is een stuk trager dan een vast plaatje
    /*    private circleMarkerIcon:any = {
     path: google.maps.SymbolPath.CIRCLE,
     strokeColor: '#FFFFFF',
     strokeOpacity: 1,
     strokeWeight: 2,
     fillColor: "#" + AppSettings.COLOR_BLUE,
     fillOpacity: 1,
     scale: 7,
     labelOrigin: new google.maps.Point(0, 2.5)
     }*/

}

export interface MapIconInterface {
    url: string
    origin?: google.maps.Point
    anchor: google.maps.Point
    img?: HTMLImageElement
    shape?: google.maps.MarkerShape
    size?: google.maps.Size
}

export interface ColorMarkerInterface {
    fillColor: string
    strokeColor: string
    url: string
    anchor: google.maps.Point
    img: HTMLImageElement
    size: google.maps.Size
}

export interface StaticMarkerImagesInterface {
    markerImageDefault: MapIconInterface
    markerImageLocationActive: MapIconInterface
    markerImageLocationInactive: MapIconInterface
}
