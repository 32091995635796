import {FormButtonComponent} from '../form-button/form-button.component';
import {FormDropdownComponent} from '../form-dropdown/form-dropdown.component';
import {FormInputComponent} from '../form-input/form-input.component';
import {FormSelectComponent} from '../form-select/form-select.component';
import {FormCheckBoxComponent} from '../form-checkbox/form-checkbox.component';
import {FormFileComponent} from '../form-file/form-file.component';
import {FormGroupComponent} from '../form-group/form-group.component';
import {FormPasswordInputComponent} from '../form-input/form-password-input.component';
import {FormDateTimeInputComponent} from '../form-input/form-date-time-input.component';
import {FormTimeInputComponent} from '../form-input/form-time-input.component';
import {FormDateInputComponent} from '../form-input/form-date-input.component';
import {FormGraphComponent} from '../form-widgets/form-graph.component';
import {FormTableComponent} from '../form-widgets/form-table.component';
import {FormIntegerInputComponent} from '../form-input/form-integer-input-component';
import {FormRangeInputComponent} from '../form-input/form-range-input-component';
import {
    Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
    ViewContainerRef
} from '@angular/core';

import {GlobalModel} from "../../../../services/state/global.model";

import {FormAnchorComponent} from '../form-anchor/form-anchor.component';
import {FormBushItemTreeComponent} from '../form-bush-item-tree/form-bush-item-tree.component';
import {FormLocationComponent} from '../form-location/form-location';
import {FormItemCountComponent} from '../form-item-count/form-item-count';
import {UntypedFormGroup} from '@angular/forms';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {AuthFormTableComponent} from '../form-table/auth-form-table.component';
import {AuthActionTableComponent} from '../form-table/auth-action-table.component';
import {AuthFieldTableComponent} from '../form-table/auth-field-table.component';
import {FormMultiSelectComponent} from '../form-select/form-multi-select/form-multi-select.component';
import {FormColorPickerComponent} from '../form-select/form-color-picker/form-color-picker.component';
import {FormAdvancedColorPickerComponent} from '../form-select/form-advanced-color-picker/form-advanced-color-picker.component';
import {DimmingExceptionsComponent} from '../dimming/dimming-exceptions.component';
import {DimmingSchemeSelectComponent} from '../dimming/dimming-scheme-select.component';
import {FormButtonInlineComponent} from '../form-button/form-button-inline.component';
import {FormLogComponent} from '../form-log/form-log.component';
import {FormCommentComponent} from '../form-comment/form-comment.component';
import {FormTextAreaComponent} from '../form-input/form-text-area.component';
import {FormMapComponent} from '../form-widgets/form-map.component';
import {FormAttachmentComponent} from '../form-attachment/form-attachment.component';
import {FormWorkPreparationComponent} from '../work-preparation/form-work-preparation.component';
import {FormWorkActivityComponent} from '../work-activity/form-work-activity.component';
import {FormWorkSpecificationComponent} from '../work-specification/form-work-specification.component';
import {FormWeekprijsComponent} from '../weekprijs/form-weekprijs.component';

import {FormLinkComponent} from '../form-link/form-link.component';
import {FormTimeTrackingComponent} from '../form-time-tracking/form-time-tracking.component';
import {CheckActivitiesComponent} from '../../../check-activities/check-activities.component';
import {FormDummyComponent} from '../form-dummy/form-dummy.component';
import {ConfigName, ConfigType} from './fieldDirective.interface';
import {FormFileManagerComponent} from '../form-file-manager/form-file-manager.component';
import {FormButtonGroupComponent} from '../form-button-group/form-button-group.component';
import {FormMapIconPickerComponent} from '../form-select/form-map-icon-picker/form-map-icon-picker.component';
import {FormGroupWithStatusComponent} from '../form-group/form-group-with-status/form-group-with-status.component';
import {FormMapServerComponent} from '../form-map-server/form-map-server.component';
import {FormTimelineComponent} from '../form-timeline/form-timeline.component';
import {GuiFormComponent} from '../../../gui-form/gui-form.component';
import {FormImageComponent} from "../form-image/form-image.component";
import {LoggerService} from "../../../../services/logger/logger.service";
import {FormAttachmentSimpleComponent} from "../form-attachment-simple/form-attachment-simple.component";

// https://stackoverflow.com/questions/35056799/how-to-override-directive-definitions-in-angular-2

@Directive({
    selector: '[fieldDirective]',
})
export class FieldDirective implements OnInit, OnChanges {
    // this is the  mapping between the json and actual components
    protected componentMapping = {};

    @Input() config: any;
    @Input() rootConfig: any;
    @Input() group: UntypedFormGroup;
    @Input() validationConstraints: any;
    @Input() readOnly: boolean;
    @Input() invalidControlsErrors: any;
    @Input() formIsSubmitted: any;

    private isNewAssetBush = true;

    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();

    private component: any;

    constructor(
        public container: ViewContainerRef,
        public validationConstraintService: ValidationConstraintService,
        private model: GlobalModel,
        protected logger:LoggerService
    ) {
        this.componentMapping[ConfigType.object] = FormGroupComponent;
        this.componentMapping[ConfigType.group_with_status] = FormGroupWithStatusComponent;
        this.componentMapping[ConfigType.string] = FormInputComponent;
        this.componentMapping[ConfigType.text_area] = FormTextAreaComponent;
        this.componentMapping[ConfigType.integer] = FormIntegerInputComponent;
        this.componentMapping[ConfigType.range] = FormRangeInputComponent;
        this.componentMapping[ConfigType.email] = FormInputComponent; // regular old form input
        this.componentMapping[ConfigType.date_time_text] = FormDateTimeInputComponent;
        this.componentMapping[ConfigType.date_text] = FormDateInputComponent;
        this.componentMapping[ConfigType.time] = FormTimeInputComponent;
        this.componentMapping[ConfigType.select] = FormSelectComponent;
        this.componentMapping[ConfigType.multi_select] = FormMultiSelectComponent;
        this.componentMapping[ConfigType.checkbox] = FormCheckBoxComponent;
        this.componentMapping[ConfigType.color_picker] = FormColorPickerComponent;
        this.componentMapping[ConfigType.color_picker_advanced] = FormAdvancedColorPickerComponent;
        this.componentMapping[ConfigType.button] = FormButtonComponent;
        this.componentMapping[ConfigType.file] = FormFileComponent;
        this.componentMapping[ConfigType.password] = FormPasswordInputComponent;
        this.componentMapping[ConfigType.graph] = FormGraphComponent;
        this.componentMapping[ConfigType.table] = FormTableComponent;
        this.componentMapping[ConfigType.auth_table] = AuthFormTableComponent;
        this.componentMapping[ConfigType.auth_action_table] = AuthActionTableComponent;
        this.componentMapping[ConfigType.formfield_settings_table] = AuthFieldTableComponent;
        this.componentMapping[ConfigType.anchor] = FormAnchorComponent;
        this.componentMapping[ConfigType.image] = FormImageComponent;
        this.componentMapping[ConfigType.location] = FormLocationComponent;
        this.componentMapping[ConfigType.item_count] = FormItemCountComponent;
        this.componentMapping[ConfigType.dimming_exceptions] = DimmingExceptionsComponent;
        this.componentMapping[ConfigType.dimming_scheme] = DimmingSchemeSelectComponent;
        this.componentMapping[ConfigType.inline_button] = FormButtonInlineComponent;
        this.componentMapping[ConfigType.log_component] = FormLogComponent;
        this.componentMapping[ConfigType.comment_component] = FormCommentComponent;
        this.componentMapping[ConfigType.map_component] = FormMapComponent;
        this.componentMapping[ConfigType.attachment_component] = FormAttachmentComponent;
        this.componentMapping[ConfigType.attachment_single_component] = FormAttachmentComponent;
        this.componentMapping[ConfigType.attachment_simple_component] = FormAttachmentSimpleComponent;
        this.componentMapping[ConfigType.work_preparation_component] = FormWorkPreparationComponent;
        this.componentMapping[ConfigType.work_activity_component] = FormWorkActivityComponent;
        this.componentMapping[ConfigType.check_activity_component] = CheckActivitiesComponent;
        this.componentMapping[ConfigType.work_specification_component] = FormWorkSpecificationComponent;
        this.componentMapping[ConfigType.weekprijs_component] = FormWeekprijsComponent;
        this.componentMapping[ConfigType.form_link] = FormLinkComponent;
        this.componentMapping[ConfigType.time_registration] = FormTimeTrackingComponent;
        this.componentMapping[ConfigType.status] = FormDummyComponent;
        this.componentMapping[ConfigType.file_manager] = FormFileManagerComponent;
        this.componentMapping[ConfigType.map_icon_picker] = FormMapIconPickerComponent;
        this.componentMapping[ConfigType.form_map_server] = FormMapServerComponent;
        this.componentMapping[ConfigType.timeline] = FormTimelineComponent;
        this.componentMapping[ConfigType.gui] = GuiFormComponent;
        this.componentMapping[ConfigType.map_item_tree] = FormBushItemTreeComponent;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.handleConfigurationChanges(this.config);
    }

    private handleConfigurationChanges(config: any) {
        // this.logger.log('[FieldDirective] Handling changes configuration for config with name', this.config.name);
        if (this.component && this.component.instance) {
            let fieldComponent: any = this.component.instance;
            fieldComponent.validationConstraints = this.getValidationConstraintsForField(config.name);
            fieldComponent.readOnly = this.readOnly;
            fieldComponent.invalidControlsErrors = this.invalidControlsErrors;
            fieldComponent.formIsSubmitted = this.formIsSubmitted;
        }
    }

    // TODO: moet dit niet afterviewinit zijn? nu is group er niet per se altijd
    ngOnInit() {
        if (this.group) {
            this.initializeConfiguration(this.config, this.group);
        }
    }

    private initializeConfiguration(config: any, group: UntypedFormGroup) {
        this.container.clear();

        if (config && config.type) {
            let component;
            
            if (config.type === ConfigType.object) {
                if (config.name) {
                    switch (config.name) {
                        case ConfigName.BUTTON_GROUP: {
                            component = FormButtonGroupComponent;
                            break;
                        }
                        case ConfigName.DROPDOWN: {
                            component = FormDropdownComponent;
                            break;
                        }
                        default: {
                            component = this.componentMapping[config.type];
                        }
                    }
                } else {
                    component = this.componentMapping[config.type];
                }
            } else {
                component = this.componentMapping[config.type];
            }

            if (component && component.name) {
                if (group.contains(config.name)) {
                    // const localComponent = this.resolver.resolveComponentFactory<any>(component);
                    this.component = this.container.createComponent(component);
                    let fieldComponent: any = this.component.instance;

                    fieldComponent.onComponentEvent = this.onComponentEvent;
                    fieldComponent.config = config;

                    // Store the root of the config, not just the (grand)child
                    // TODO: dit is nog niet in alle componenten geimplementeerd maar kan zo overal gebruikt worden
                    fieldComponent.rootConfig = this.rootConfig;

                    // object == formgroup
                    if (config.type === 'object' || config.type === 'group_with_status') {
                        fieldComponent.form = group.get(config.name);
                        fieldComponent.label = config.label;
                        fieldComponent.name = config.name;
                    } else {
                        fieldComponent.group = group;
                        if (config.attr && config.attr['batch-update'] == true) {
                            fieldComponent.batchUpdateMode = true;
                        }
                    }

                    if (config.attr && config.attr.infoText) {
                        fieldComponent.infoText = config.attr.infoText;
                    }

                    fieldComponent.formIsSubmitted = this.formIsSubmitted;
                    fieldComponent.readOnly = this.readOnly;
                    fieldComponent.invalidControlsErrors = this.invalidControlsErrors;
                    fieldComponent.validationConstraints = this.getValidationConstraintsForField(config.name);
                }
            } else {
                this.logger.error('[FieldDirective] Implementation Error: No component defined for config with type "'+config.type+'". Skipping component rendering! Please implement a component via the "components" constant in FieldDirective',config);
            }
        }
    }

    private getValidationConstraintsForField(field: string): any {
        if (field && this.validationConstraints) {
            if (this.validationConstraints[field]) {
                this.logger.log('[ValidationConstraintService] Trying to find field [' + field + '] in', this.validationConstraints);

                if (this.validationConstraints[field].errors) {
                    this.logger.log('[ValidationConstraintService] found field [' + field + ']');
                    return this.validationConstraints[field].errors;
                }

                // A group gives validation constraints to its children this way.
                if (this.validationConstraints[field].children) {
                    this.logger.log('[ValidationConstraintService] field [' + field + '] has children');
                    return this.validationConstraints[field].children;
                }
            }
        }

        // TODO: nu heeft hij altijd een return waarde, en dit breekt de code niet, maar dit is niet netjes
        return undefined;
    }

    // TODO: variabelen leegmaken is niet nodig...
    ngOnDestroy() {
        this.config = null;
        this.invalidControlsErrors = null;
        this.validationConstraints = null;
        this.formIsSubmitted = null;
        this.readOnly = null;
        if (this.component) {
            this.component.destroy();
        }
        this.container.clear();
        this.container.remove();
    }
}

