import {ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AuthorizationService} from '../../../../services/authorization/authorization.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {Subscription} from 'rxjs';
import {FormEvent} from '../../containers/form/form.interface';
import {ButtonRank} from '../../../commonUI/button/lumi-button/lumi-button.interface';
import {FormGroupConfig} from '../field/fieldDirective.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-dropdown',
    styleUrls: ['./form-dropdown.component.scss'],
    template: `
        <div *ngIf="isVisible()" class="dynamic-field">
            <div class="dropdown">
                <lumi-button [disabled]="isDisabled(this.config)"
                             [size]="'medium'"
                             [label]="config.label? config.label : ''"
                             [fullWidth]="!!config.label"
                             [centerLabel]="false"
                             [rank]="'primary'"
                             [icon]="getIcon(this.config)"
                             [title]="getToolTip(this.config)"
                             id="formDropdown"
                             data-toggle="dropdown"
                             aria-haspopup="true"
                             aria-expanded="false">
                </lumi-button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="formDropdown">
                    <div *ngFor="let menuItem of getDropDownItems()"
                         (click)="handleClick(menuItem)"
                         [attr.title]="getToolTip(menuItem)"
                         [ngClass]="{'disabled-item': isDisabled(menuItem)}"
                         class="simple-dropdown-item d-flex align-items-center">
                        
                        <span *ngIf="getIcon(menuItem)">
                            <i class="material-icons">{{getIcon(menuItem)}}</i>&nbsp;
                        </span>
                        <span>{{getToolTip(menuItem)}}</span>
                    </div>
                </div>
            </div>
        </div>
    `,
})

export class FormDropdownComponent implements OnDestroy, OnInit {
    @Output() onComponentEvent: EventEmitter<FormButtonComponentEvent> = new EventEmitter();

    readOnly: boolean;

    public _config: any;
    private _subMobileMode: Subscription;
    private _mobileMode: Boolean = false;

    public constructor(private auth: AuthorizationService, private model: GlobalModel, private cd: ChangeDetectorRef, protected logger:LoggerService) {
    }

    ngOnInit(): void {
        this._subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            //Don't forget to unsubscribe
            this._mobileMode = value;
            this.cd.detectChanges(); //Mark for change was not enough here
        });
    }

    ngOnDestroy(): void {
        if (this._subMobileMode) {
            this._subMobileMode.unsubscribe();
        }
    }

    public getDropDownItems():any{
        return this.config.children;
    }

   @Input()
   set config(value: any) {
        this._config = value;
    }

    get config(): any {
        return this._config;
    }


    isVisible(): boolean {
        let result: boolean = true;

        if (this.isCancelButton(this.config) && this._mobileMode) {
            result = false;
        }

        return result;
    }

    isDisabled(button:any): boolean {
        let disabled: boolean = false;

        if (this.readOnly === true) {
            disabled = true;
        }
        else if (this.readOnly === false) {
            disabled = false;
        }

        if (button.disabled === true) {
            disabled = true;
        }

        if (button.disabledByFormError) {
            disabled = true;
        }

        if (button && button.attr) {
            // TODO: Kan ook verplaatst worden naar de backend. De knop als disabled doorsturen als je geen delete rechten hebt
            // client-side authorisation for special cases
            let action: string = button.attr.action;
            if (
                (action == FormEvent.DELETE_BASEOBJECT && !this.auth.allowRemoveBaseObject()) ||
                (action == FormEvent.SEGMENT_DELETE && !this.auth.allowRemoveBaseObject()) ||
                (action == FormEvent.COPY_BASEOBJECT && !this.auth.allowCopyBaseObject()) ||
                (action == FormEvent.EXCEPTION_CREATE && !this.auth.allowCreateException()) ||
                (action == FormEvent.SCHEME_EDIT && !this.auth.allowEditDimmingScheme()) ||
                (action == FormEvent.SET_DIMGROUP_OVERRIDE && !this.auth.allowSetOverride()) ||
                (action == FormEvent.DIMGROUP_DELETE && !this.auth.allowDeleteDimGroup())
            ) {
                disabled = true;
            }
        }

        //Movebutton altijd toeganklijk
        if (button && button.attr && button.attr.action == FormEvent.MOVE_MAPITEM) {
            disabled = false;
        }

        return disabled;
    }

    handleClick(button:any) {
        this.logger.log('[FormButtonComponent] ' + 'handle click form button');

        if (this.isBackButton(button)) {
            window.history.back();
        }

        //Default to save-button, since that is most common
        let action: string = FormEvent.SAVE;
        let url: string = '';
        let mapItem: number = -1;
        let attr: object = {};

        if (button.attr) {
            attr = button.attr;

            if (button.attr.action) {
                action = button.attr.action;
            }
            if (button.attr.url) {
                url = button.attr.url;
            }
            if (button.attr.map_item_id) {
                mapItem = button.attr.map_item_id;
            }
        }

        this.onComponentEvent.emit({event: action, data: {url: url, mapItem: mapItem, attr: attr}});
    }

    hasIcon(button:any): boolean {
        return (button.attr && button.attr.icon && button.attr.icon != '');
    }

    private isCancelButton(button:any): boolean {
        return (button.attr && button.attr.action && button.attr.action == FormEvent.CANCEL);
    }

    private isBackButton(button:any): boolean {
        return (button.attr && button.attr.action && button.attr.action == FormEvent.BACK);
    }

    getIcon(button:any): string {
        let result: string = '';

        if (this.hasIcon(button)) {
            result = button.attr.icon;
        }

        return result;
    }

    getToolTip(button:any): string {
        let result = '';

        if (button.attr && button.attr.toolTip) {
            result = button.attr.toolTip;
        }

        return result;
    }
}

export interface FormButtonComponentEvent {
    event: string
    data: {
        url: string
        mapItem: number
        attr: object
    }
    config?: FormGroupConfig
}
