import {NgModule} from "@angular/core";
import {TranslatePipe} from "./translate.pipe";
import {TruncatePipe} from './truncate.pipe';
import { DurationPipe } from './duration.pipe';
import {HumanDatePipe} from './human-date.pipe';

@NgModule({
    imports: [],
    declarations: [
        TranslatePipe,
        TruncatePipe,
        DurationPipe,
        HumanDatePipe
    ],
    exports: [
        TranslatePipe,
        TruncatePipe,
        DurationPipe,
        HumanDatePipe
    ]
})

export class CommonPipesModule {}
