<ng-container [formGroup]="group">
    <div class="d-block w-100">
        <div class="d-flex">
            <form-component-label *ngIf="showLabel" [batchUpdateMode]="batchUpdateMode" [config]="config"
                                  [included]="included" (onClickInclude)="handleClickInclude($event)"></form-component-label>
            <div class="md-form input-group p-0 m-0">
                <div class="d-flex align-items-center" *ngIf="uploadedImageUrl != '' || attachments.length > 0">
                    <div class="mr-2 selected-image">
                        <img [src]="uploadedImageUrl != '' ? uploadedImageUrl : attachments[0].thumbNail" alt="" />
                    </div>
                    <lumi-button (onClick)="handleClickDeleteAttachment($event, attachments[0])"
                                 [icon]="'delete'"
                                 [title]="'remove-image' | translate"
                                 [label]="'remove-image' | translate">
                    </lumi-button>
                </div>

                <file-selector #fileSelector
                               (onChanges)="onFilesSelected($event)"
                               [multiInput]="allowMultiSelect"
                               [isDisabled]="isLoading"
                               *ngIf="attachments.length == 0">
                </file-selector>
            </div>
        </div>
    </div>
</ng-container>
