<div class="monitor-container {{profilePosition}}" *ngIf="hasUserEngineerRole() && this.profilerModeEnabled && model.showProfilerWindow">
    <div class="monitor-container-header d-flex justify-content-between align-items-center">
        <div class="title">Profiler</div>
        <div class="search d-flex align-items-center">
            <div class="custom_input">
                <svg xmlns="http://www.w3.org/2000/svg" class="svg_icon bi-x" viewBox="0 0 16 16" (click)="clearSearch()" *ngIf="searchQuery != ''">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
                <input class="input-c" type="text" [(ngModel)]="searchQuery" placeholder="Zoeken">
            </div>
        </div>
        <div class="d-flex justify-content-end profiler-icons">
            <div (click)="clearProfiler()" class="pointer-cursor"><i class="material-icons">delete</i></div>
            <div (click)="changePosition()" class="pointer-cursor">
                <i class="material-icons" *ngIf="profilePosition == 'left-side'">switch_left</i>
                <i class="material-icons" *ngIf="profilePosition == 'right-side'">switch_right</i>
            </div>
            <div (click)="closeProfiler()" class="pointer-cursor ml-md-3"><i class="material-icons">close</i></div>
        </div>
    </div>
    <div class="monitor-container-content" id="monitor-container-content">
        <div class="monitor-item" *ngFor="let monitorItem of getNetworkList()">
            <div class="row m-0" *ngIf="monitorItem.callUrl">
                <div class="d-flex align-items-center">
                    <a [href]="monitorItem.profilerUrl" target="_blank" class="px-large mr-1"><fa-icon [icon]="faSymfony"></fa-icon></a>
                    <div>
                        <span class="datetime">{{formatDateTime(monitorItem.datetime)}} | </span>
                        <span class="method">{{monitorItem.method}}</span>
                        <span class="datetime"> - </span>
                    </div>
                    <div>
                        <span class="call" data-toggle="tooltip" data-placement="top" matTooltip="{{monitorItem.callUrl}}">{{monitorItem.callUrl}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="monitor-button {{profilePosition}}" *ngIf="hasUserEngineerRole() && this.profilerModeEnabled && !model.showProfilerWindow" (click)="openProfiler()">
    <fa-icon [icon]="faSymfony"></fa-icon>
</div>
