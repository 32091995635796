import {AfterViewInit, Component, HostBinding, Renderer2, ViewChild} from '@angular/core';
import 'moment/locale/nl';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-text-area',
    template: `
        <ng-container [formGroup]="group">

            <form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included"
                                  (onClickInclude)="handleClickInclude($event)"></form-component-label>

            <div class="md-form input-group p-0 m-0 d-flex flex-column">
                <textarea #currentFormField
                          class="{{isControlReadOnly()?'d-none':''}}"
                          (keyup)="handleKeyUp($event)"
                          [disableControl]="isControlDisabled()"
                          name="{{config.name}}"
                          id="{{config.name}}"
                          [attr.placeholder]="config.placeholder"
                          [attr.maxLength]="max_chars"
                          [formControlName]="config.name"
                          [(ngModel)]="initialValue"
                ></textarea>
                <div class="{{!isControlReadOnly()?'d-none':''}} form-read-only-label"
                     style="white-space: pre-line;max-height: 250px;overflow-y: auto;">{{initialValue}}</div>
                <div class="d-flex pb-1">
                    <div *ngIf="!isControlDisabled()"
                         class="ml-auto form-text-area-counter">{{getCharacterCount()}}</div>
                </div>
            </div>
        </ng-container>
    `,
})
export class FormTextAreaComponent extends AbstractFormFieldComponent implements AfterViewInit {
    @ViewChild('currentFormField', {static: false}) currentFormField: any;
    @HostBinding('class') hostClasses = 'd-flex mt-1';

    max_chars: number = 1500;

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if (this.config && this.config.attr && this.config.attr.maxLength){
            this.max_chars = (typeof this.config.attr.maxLength === 'number') ? this.config.attr.maxLength : parseInt('' + this.config.attr.maxLength);
        }
    }

    getCharacterCount(): string {
        return this.currentFormField ? this.currentFormField.nativeElement.value.length + '/' + this.max_chars : 'undefined';
    }

    handleKeyUp(event: any): void {
        //Prevent this event from going global. When inside a popup you don't want to trigger an OK press, only because you wanted a new line.
        //It will keep pressing the enter key inside this component
        if (event.keyCode == 13) {
            event.stopImmediatePropagation();
        }
    }
}
