/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {GlobalPopup} from "../global-popup";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {AppSettings} from "../../../app.settings";
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {StorageService} from "../../../shared/services/storage/storage.service";
import {UserRoles} from "../../../shared/interfaces/user.interface";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {LoggerService} from "../../../shared/services/logger/logger.service";
import {map} from "rxjs/operators";
import {HttpService2} from "../../../shared/services/http-service-2.0/http2.0.service";
import {Observable, take} from "rxjs";
import {clearCaches} from '@typescript-eslint/parser';
import {environment} from "../../../../environments/environment";

@Component ({
    selector: 'global-popup-about-us-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [globalPopup]="globalPopup" (onClose)="closePopup($event)">
            <div style="padding-left: 2rem;" class="pb-3">
                <img class="pb-2 pt-2 img-fluid" src="assets/img/luminizerlogo.png"/>
                <h2 style="font-size: 16px;"><a routerLink="/information/release-notes" class="text-color-darkgrey" style="text-decoration: underline; font-size: 16px" routerLinkActive="active" (click)="handleClickReleaseNotes(globalPopup)"> {{'Bekijk wat er nieuw is' | translate}}</a></h2>
            </div>
            <div class="d-flex pt-4">
                <div class="mr-auto">
                    <h2 class="d-flex"><i class="material-icons py-0 pl-0">home</i>{{'Bezoek ons hier' | translate}}</h2>
                    <ul class="pb-0" style="padding-left: 2rem;">
                        <li>Luminext B.V.</li>
                        <li>Schoudermantel 37</li>
                        <li>3981 AE  Bunnik</li>
                        <li>Netherlands</li>
                    </ul>
                </div>
                <div class="mr-auto">
                    <h2 class="d-flex"><i class="material-icons py-0 pl-0">phone</i>{{'Of neem contact op' | translate}}</h2>
                    <ul class="" style="padding-left: 2rem;">
                        <li>Tel.: +31 (0)30 207 2017</li>
                        <li><a href="mailto:info@luminext.eu">info&#64;luminext.eu</a></li>
                        <li><a href="https://www.luminext.eu/">www.luminext.eu</a></li>
                    </ul>
                </div>
            </div>
            <div class="p-4 developer-tools" *ngIf="hasUserEngineerRole()">
                <h5>Developer tools</h5>
                <div class="d-flex justify-content-between align-items-start mt-4 mb-4">
                    <div>
                        <div class="form-inline d-flex" title="Translation mode">
                            <div class="map-option-label">
                                <div>Translation mode</div>
                            </div>
                            <lumi-switch [initialValue]="translationModeEnabled"
                                         (onSwitch)="handleToggleTranslationMode()">
                            </lumi-switch>
                        </div>
                        <div class="form-inline d-flex pt-2" title="Debug mode">
                            <div class="map-option-label">
                                <div>Debug mode</div>
                            </div>
                            <lumi-switch [initialValue]="debugModeEnabled"
                                         (onSwitch)="handleToggleDebugMode()">
                            </lumi-switch>
                        </div>
                        <div class="form-inline d-flex pt-2" title="Profiler mode" *ngIf="canShowProfilerMode">
                            <div class="map-option-label">
                                <div>Profiler mode</div>
                            </div>
                            <lumi-switch [initialValue]="profilerModeEnabled"
                                         (onSwitch)="handleToggleProfilerMode()">
                            </lumi-switch>
                        </div>
                    </div>
                    <div>
                        <div class="form-inline d-flex" title="Clear cache">
                            <lumi-button (onClick)="handleClearCache()" label="Clear cache" />
                        </div>
                    </div>
                </div>
            </div>
        </default-popup-wrapper-component>
    `,
    styles:['.developer-tools{background:#f3f3f3}']
})

export class GlobalPopupAboutUsComponent extends AbstractBasePopupComponent {
    public canShowProfilerMode:boolean = false;
    public debugModeEnabled:boolean = false;
    public translationModeEnabled:boolean = false;
    public profilerModeEnabled:boolean = false;

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected elementRef:ElementRef, protected ts:TranslateService, protected tooltipService:TooltipService,private storage: StorageService,private model:GlobalModel, protected logger:LoggerService, protected httpService2: HttpService2) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);

        this.storage.getBooleanValue(StorageService.KEY_DEBUG_MODE, (value: boolean) => {
            this.debugModeEnabled = value;
        });
        this.storage.getBooleanValue(StorageService.KEY_TRANSLATION_MODE, (value: boolean) => {
            this.translationModeEnabled = value;
        });
        this.storage.getBooleanValue(StorageService.KEY_PROFILER_MODE, (value: boolean) => {
            this.profilerModeEnabled = value;
        });

        if (!environment.production) {
            this.canShowProfilerMode = true;
        }
    }

    public handlePopupAction(event:MouseEvent, alert:GlobalPopup, button:any)
    {
        // Hide alert and perform callback of button
        this.onPopupAction.emit({event:event, alert:alert, button:button});
    }

    public handleToggleDebugMode(){
        if(this.hasUserEngineerRole()){
            this.debugModeEnabled = !this.debugModeEnabled;
            this.storage.setObjectValue(StorageService.KEY_DEBUG_MODE,this.debugModeEnabled)
            this.reloadApp();
        }
    }

    public handleToggleProfilerMode(){
        if(this.hasUserEngineerRole()){
            this.profilerModeEnabled = !this.profilerModeEnabled;
            this.storage.setObjectValue(StorageService.KEY_PROFILER_MODE,this.profilerModeEnabled)
            this.reloadApp();
        }
    }

    public handleToggleTranslationMode(){
        if(this.hasUserEngineerRole()){
            this.translationModeEnabled = !this.translationModeEnabled;
            this.changeTranslationMode(this.translationModeEnabled).pipe(
                take(1)
            ).subscribe(() => {
                this.storage.setObjectValue(StorageService.KEY_TRANSLATION_MODE,this.translationModeEnabled)
                this.reloadApp();
            });
        }
    }

    private reloadApp():void{
        setTimeout(() => {
            window.location.reload();
        }, 500)
    }

    public handleClearCache(): void {
        if (this.hasUserEngineerRole()){
            this.clearCache().pipe(
                take(1)
            ).subscribe(() => {

            });
        }
    }
    private clearCache(): Observable<void> {
        return this.httpService2.doFormPostRequest(`translations/cache/clear`, JSON.parse(JSON.stringify({clearCache: true})), false).pipe(
            map((result) => {
                this.logger.log('[GlobalPopupAboutUsComponent] - Cache cleared', result);
            })
        );
    }

    public changeTranslationMode(translationMode:boolean):Observable<void>{
        this.logger.log('[GlobalPopupAboutUsComponent] - Post new translation mode: '+translationMode);
        return this.httpService2.doFormPostRequest(`translations/translate/mode`, JSON.parse(JSON.stringify({mode: translationMode})), false).pipe(
            map((result) => {
                this.logger.log('[GlobalPopupAboutUsComponent] - Set translation mode to '+translationMode+'. results', result);
            })
        );
    }

    public hasUserEngineerRole(): boolean {
        return this.model.getUserRoleCodes().some(role => role === UserRoles.ENGINEER);
    }

    handleClickReleaseNotes(alert: GlobalPopup):void
    {
        // Release note link is clicked, remove the info alert
        this.closePopup(null);
    }

    closePopup(event: any):void
    {
        this.doDefaultCloseHandling(event, false);
    }
}
