/**
 * Created by Jasper Kuiper on 18/06/2020.
 */
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HTTPService} from "../../shared/services/http/http.service";
import {UserService} from "../login/user.service";
import {GlobalModel} from "../../shared/services/state/global.model";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {AppSettings} from '../../app.settings';
import {Router} from '@angular/router';
import {LuminizerRoutes} from '../../shared/interfaces/routes';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component ({
    selector: 'user-invite-component',
    template: `
        <div #div class="login-background">
            <div class="login-container">
                <div class="login-container-inner d-flex align-items-center justify-content-between">
                    <div class="login-notify-outer">&nbsp;</div>
                    <div class="login-container-right d-flex align-items-center">
                        <div class="login-form">
                            <div class="col-12">
                                <h3 class="mb-4">{{pageTitle}}</h3>
                                <p>{{pageBody}}</p>
                                <div class="login-btn-container ml-0">
                                    <lumi-button (onClick)="handleClickLogin()"
                                                 [disabled]="httpService.hasPendingCall()"
                                                 [rank]="'secondary'"
                                                 [size]="'large'"
                                                 [label]="'Log in' | translate">
                                    </lumi-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class UserInviteComponent implements OnInit {
    @ViewChild('div', {static: false}) div:ElementRef<HTMLElement>;

    private userInviteUrl:string = 'user-management/request';
    private token:string;
    private status:string;
    public pageTitle:string = 'Er ging iets mis.';
    public pageBody:string = 'De invite url is niet correct. Neem contact op met Luminext.';

    constructor(public httpService:HTTPService, private model:GlobalModel, private userService:UserService, private cd:ChangeDetectorRef, private router:Router, protected logger:LoggerService)
    {
        this.token = this.userService.getTokenParam();
        this.status = this.userService.getStatusParam();

        this.logger.log("[UserInviteComponent] " + "Token: " + this.token);
        this.logger.log("[UserInviteComponent] " + "Status: " + this.status);
    }

    ngOnInit(){
        if(this.token && this.status){
            let requestUrl = this.userInviteUrl + '/' + this.token + '/' + this.status;

            this.httpService.doGetRequest(requestUrl, (callback) => {
                this.pageTitle = callback.message.title;
                this.pageBody = callback.message.body;
                // Force a click on the element otherwise it won't notice the change
                this.div.nativeElement.click();
            }, () => {}, () => {}, false);

            this.cd.markForCheck();
        }
    }
    
    public handleClickLogin(){
        this.router.navigate([LuminizerRoutes.LOGIN_PAGE]);
    }
    
    public handleKeyDown($event: any) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
            this.handleClickLogin();
        }
    }
}
