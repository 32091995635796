import {ChangeDetectorRef, Component, Output, EventEmitter, OnInit, Input, Renderer2} from '@angular/core';
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {StorageService} from "../../../shared/services/storage/storage.service";

@Component ({
    selector: 'login-header',
    templateUrl: 'login-header.component.html'
})

export class LoginHeaderComponent implements OnInit {
    @Output() flippingLanguage = new EventEmitter<boolean>();
    @Input() beforeLogin: boolean = true;
    public languages:any = TranslateService.LANGUAGES;

    public activeLanguageCode: string = "nl";
    public activeLanguageName: string = "Nederlands";
    public activeFlagSrc: string = "/assets/img/flags/flag-"+this.activeLanguageCode+".svg";

    constructor(private model:GlobalModel,private ts:TranslateService,private storage:StorageService,private cd:ChangeDetectorRef, private renderer:Renderer2){

    }

    ngOnInit() {
        this.handleSelectedLanguage();
    }

    getLogoPath(): string {
        if(this.model.isXmasMode()){
            return 'assets/img/luminizerlogo-login-xmas.png';
        } else {
            return '/assets/img/luminizerlogo-login.png';
        }
    }

    public async handleChangeLanguage(language:string)
    {
        //Show the fields again
        this.flippingLanguage.emit(true);
        this.cd.detectChanges();

        await this.ts.setLanguage(language);
        this.storage.setValue(StorageService.KEY_SELECTED_LANGUAGE, language);

        this.activeLanguageCode = language;
        this.activeLanguageName = this.getLanguageLabel(language);
        this.activeFlagSrc = "/assets/img/flags/flag-"+language+".svg";

        this.changeLanguageTag(language);

        // Show language switch animation
        this.flippingLanguage.emit(false);
        this.cd.detectChanges();
    }

    private handleSelectedLanguage():void {
        //Safety for element not loaded
        let language:string = "nl";

        //Get language from storage
        this.storage.getStringValue(StorageService.KEY_SELECTED_LANGUAGE, (value: any) => {
            language = value;
        });
        
        this.activeLanguageCode = language;
        this.activeLanguageName = this.getLanguageLabel(language);
        this.activeFlagSrc = "/assets/img/flags/flag-"+language+".svg";

        this.changeLanguageTag(language);
    }

    private getLanguageLabel(languageCode:string):string{
        return this.languages.find(_x => _x.value === languageCode).label;
    }

    private changeLanguageTag(languageCode:string):void{
        const htmlElement = document.documentElement; // <html>
        this.renderer.setAttribute(htmlElement, 'lang', languageCode);
    }


}
