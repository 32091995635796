<div class="form-log-container">
    <div class="form-attachment-loader {{isLoading?'':'d-none'}}">
        <div class="progress">
            <div class="indeterminate"></div>
        </div>
    </div>
    <div class="form-log flex-column p-2 mb-2 {{disabled() != null?'d-none':'d-flex'}}">
        <div class="form-new-attachment-title mb-2">{{'attachment.newtitle' | translate}}</div>
        <file-selector #fileSelector
                       (onChanges)="onFilesSelected($event)"
                       [multiInput]="allowMultiSelect"
                       [isDisabled]="isLoading">
        </file-selector>
    </div>

    <ng-container *ngFor="let attachment of attachments">
        <complex-list-item class="{{attachment.isNew?'animated fadeIn':''}}"
                           [style.color]="attachment.isDeleted? AppSettings.getColor('$selection-gray'):''"
                           [item]="attachment.listItem"
                           [readonly]="disabled()"
                           (onMenuItemClick)="onAttachmentAction($event, attachment)">
            <div class="d-flex flex-column pb-1">
                <div class="d-flex mt-2">
                    <div class="form-attachment-preview cursor-pointer mr-2"
                         [style.opacity]="attachment.isDeleted?'0.3':'1'"
                         title="{{!hasThumbNail(attachment)?('attachment.download' | translate:[attachment.fileName]):('attachment.view' | translate:[attachment.fileName])}}"
                         (click)="handleOpenAttachment($event, attachment)">
                        <div *ngIf="!hasThumbNail(attachment)">
                            <img [src]="'/assets/img/'+getFileTypeThumbnail(attachment)" style="width:70%;height:auto;">
                        </div>
                        <div *ngIf="hasThumbNail(attachment)">
                            <img [src]="attachment.thumbNail" style="width:100%;height:auto;">
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-column form-attachment-data">
                        <key-value [key]="'attachment.uploader' | translate"
                                   [value]="getName(attachment)">
                        </key-value>
                        <key-value [key]="'attachment.created' | translate"
                                   [value]="attachment.created">
                        </key-value>
                        <key-value [key]="'attachment.size' | translate"
                                   [value]="getFileSize(attachment)">
                        </key-value>
                    </div>
                </div>
            </div>

            <div class="form-attachment-loader {{attachment.isDownloading?'':'d-none'}}">
                <div class="progress">
                    <div class="indeterminate"></div>
                </div>
            </div>
        </complex-list-item>
    </ng-container>
    <div class="" *ngIf="disabled() != null && attachments.length <= 0">
        {{'attachment.noattachments' | translate}}
    </div>
</div>
